import React from 'react';
import styles from './login.dialog.module.css';
import Typography from '@mui/material/Typography';
import CloseIcon from '../closeIcon';
import Config from '../../../config/Config';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../redux/hooks';
import Common from '../../../utils/Common';
import { useTranslation } from 'react-i18next';

type PropsInterface = {
  handleClose: any;
}
export default function LoginDialog(props: PropsInterface) {
  const { t } = useTranslation();
  const { setPageQueryParams } = Common();
  const dispatch = useAppDispatch();
  const randomState = '12345678901234567890123456789011'; //cryptoRandomString({ length: 32 });
  const clientId = Config().configModule.saLogin.clientId;
  const redirectBaseUrl = Config().configModule.saLogin.redirectBaseUrl;
  const aliasId = Config().configModule.saLogin.aliasId;
  const logOutReturnURL = Config().configModule.saLogin.logOutReturnURL;
  const location = useLocation();
  const fullPathLink = redirectBaseUrl + location.pathname;
  const scope = 'offline.access';

  const onClickHandler = () => {
    setPageQueryParams();
  }

  return (
    <div className={styles.dialogWrapper}>
      <div className={styles.closeBtn}>
        <CloseIcon handleClose={props.handleClose} />
      </div>
      <Typography variant={'body1'} className={styles.title}>
        {t('miscSignInTitle')}
      </Typography>
      <Typography variant={'body1'} className={styles.subTitle}>
        {t('miscSignInDescription')}
      </Typography>
      <div className={styles.btnWrapper}>
        <a
          onClick={onClickHandler}
          className={styles.btn}
          href={`https://account.samsung.com/accounts/v1/${aliasId}/signInGate?client_id=${clientId}&response_type=code&redirect_uri=${fullPathLink}&state=${randomState}&scope=${scope}`}
          rel="noopener noreferrer"
        >
          <Typography variant={'body1'} className={styles.btnText}>{t('miscSignInBtnText')}</Typography>
        </a>
      </div>
    </div>
  )
}