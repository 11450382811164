import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React from 'react';
import styles from './LandingPageFrameSection.module.css';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { disableRightClick } from '../../utils/Common';

export default function LandingPageFrameSection() {
  const { t } = useTranslation();

  return (
    <Box className={styles.frameContainer}>
      <Stack
        className={styles.frameBody}
        direction={{ sm: 'row' }}
        alignItems={{ sm: 'stretch' }}
        justifyContent={{ sm: 'stretch' }}
      >
        <Box className={styles.frameImageContainer} flexBasis={{ sm: '50.6%' }}>
          <section>
            <img
              src="/images/frame_banner.jpg"
              alt="Frame Banner"
              className={styles.frameImage}
              onContextMenu={disableRightClick}
            />
          </section>
        </Box>
        <Stack
          className={styles.frameContent}
          flexBasis={{ sm: '50%' }}
          justifyContent="center"
        >
          <Typography variant="h5" className={styles.frameContentHeading}>
            {t('miscLandingPageFrameTV')}
          </Typography>
          <Typography variant="body1" className={styles.frameContentCaption}>
            {t('miscLandingPageQLED')}
          </Typography>
          <div className={styles.btnWrapper}>
            <Link
              to="https://www.samsung.com/us/tvs/the-frame/highlights/?cid=us_ow_app_artstore_frametv_ongoing_frame4art_article_home_anon"
              className={styles.btnText}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('miscLandingPageBuynow')}
            </Link>
          </div>
        </Stack>
      </Stack>
    </Box>
  );
}
