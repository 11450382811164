import React from 'react';

type PropsInterface = {
  handleClose: any;
}
const CloseIcon = (props: PropsInterface) => {
  return (
    <div style={{ cursor: 'pointer', display: 'inline-block' }} onClick={props.handleClose}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g clipPath="url(#clip0_4104_1080)">
          <path d="M0.601562 15.3953L15.3953 0.601562" stroke="#010101" strokeWidth="1.25" strokeMiterlimit="10"/>
          <path d="M0.601562 0.601562L15.3953 15.3953" stroke="#010101" strokeWidth="1.25" strokeMiterlimit="10"/>
        </g>
        <defs>
          <clipPath id="clip0_4104_1080">
            <rect width="16" height="16" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default CloseIcon;