import React from 'react';

const FavoriteUncheckedIcon = (props: any) => {
  return (
    <svg style={{ cursor: 'pointer' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11.375" fill={props.disabled ? '#C9C5CA' : 'white'}  stroke="#C9C5CA" strokeWidth="1.25" />
      <g clipPath="url(#clip0_4104_713)">
        <path d="M12 17.3332V6.6665" stroke={props.disabled ? 'white' : '#585DE9'} strokeMiterlimit="10"/>
        <path d="M6.66699 12H17.3337" stroke={props.disabled ? 'white' : '#585DE9'} strokeMiterlimit="10"/>
      </g>
      <defs>
        <clipPath id="clip0_4104_713">
          <rect width="10.6667" height="10.6667" fill="white" transform="translate(6.66699 6.6665)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default FavoriteUncheckedIcon;