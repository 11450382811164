import axios, { AxiosPromise } from 'axios';
import Config from '../../config/Config';
import {
  FeaturedCollectionParamsInterface,
  CollectionContentParamsInterface,
  CollectionContentDetailsParamsInterface,
  SearchCollectionContentParamsInterface,
  ArtistCollectionContentParamsInterface,
} from '../../interfaces/collections.interface';

const axiosObject = axios.create();
const basePath = `${Config().baseUrl}/${Config().appPrefix}/${
  Config().apiVersion
}`;

export const featuredCollection = (
  apiKey: string,
  params: FeaturedCollectionParamsInterface
): AxiosPromise => {
  let {
    country,
    languageCode,
    year,
    model,
    collectionType
  }: FeaturedCollectionParamsInterface = params;

  // Default values
  country = country || 'US';
  languageCode = languageCode || 'US';
  year = year || '2022';
  model = model || '22_PONTUSM_FTV';
  collectionType = collectionType || 'featured_collection';

  const parameters = `country=${country}&langcode=${languageCode}&year=${year}&model=${model}&collectiontype=${collectionType}`;

  const config = {
    method: 'get',
    url: `${basePath}/content/featured-collections?${parameters}`,
    headers: { 'Content-Type': 'application/json', apiKey },
  };

  return axiosObject(config);
};

export const collectionContent = (
  apiKey: string,
  params: CollectionContentParamsInterface
): AxiosPromise => {
  let {
    country,
    languageCode,
    year,
    model,
    categoryId,
    subCatId,
  }: CollectionContentParamsInterface = params;

  // Default values
  country = country || 'US';
  languageCode = languageCode || 'US';
  year = year || '2022';
  model = model || '22_PONTUSM_FTV';

  const parameters = `country=${country}&langcode=${languageCode}&year=${year}&model=${model}&categoryId=${categoryId}&subCatId=${subCatId}`;

  const config = {
    method: 'get',
    url: `${basePath}/content/featured-collection?${parameters}`,
    headers: { 'Content-Type': 'application/json', apiKey },
  };

  return axiosObject(config);
};

export const artistCollectionContent = (
  apiKey: string,
  params: ArtistCollectionContentParamsInterface
): AxiosPromise => {
  let {
    country,
    languageCode,
    year,
    model,
    artistId
  }: ArtistCollectionContentParamsInterface = params;

  // Default values
  country = country || 'US';
  languageCode = languageCode || 'US';
  year = year || '2022';
  model = model || '22_PONTUSM_FTV';

  const parameters = `country=${country}&langcode=${languageCode}&year=${year}&model=${model}&artistId=${artistId}`;

  const config = {
    method: 'get',
    url: `${basePath}/content/artist-collection?${parameters}`,
    headers: { 'Content-Type': 'application/json', apiKey },
  };

  return axiosObject(config);
};

export const collectionContentDetails = (
  apiKey: string,
  params: CollectionContentDetailsParamsInterface
): AxiosPromise => {
  let {
    country,
    languageCode,
    year,
    model,
    contentId
  }: CollectionContentDetailsParamsInterface = params;

  // Default values
  country = country || 'US';
  languageCode = languageCode || 'US';
  year = year || '2022';
  model = model || '22_PONTUSM_FTV';

  const parameters = `country=${country}&langcode=${languageCode}&year=${year}&model=${model}&contentId=${contentId}`;

  const config = {
    method: 'get',
    url: `${basePath}/content/featured-collection/details?${parameters}`,
    headers: { 'Content-Type': 'application/json', apiKey },
  };

  return axiosObject(config);
};

export const searchCollectionContent = (
  apiKey: string,
  params: SearchCollectionContentParamsInterface
): AxiosPromise => {
  let {
    country,
    languageCode,
    year,
    model,
    keyword
  }: SearchCollectionContentParamsInterface = params;

  // Default values
  country = country || 'US';
  languageCode = languageCode || 'US';
  year = year || '2022';
  model = model || '22_PONTUSM_FTV';

  const parameters = `country=${country}&langcode=${languageCode}&year=${year}&model=${model}&keyword=${keyword}`;

  const config = {
    method: 'get',
    url: `${basePath}/content/featured-collection/search?${parameters}`,
    headers: { 'Content-Type': 'application/json', apiKey },
  };

  return axiosObject(config);
};
