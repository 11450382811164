const constants = {
  colors: {
    primary: '#585DE9',
    primaryDark: '#1D1D2F',
  },
  bgColors: {
    primary: '#585DE9',
    primaryDark: '#1D1D2F',
    secondary: 'linear-gradient(150deg, #575de8 40%, #8050dd)',
  },
  carouselSettings: {
    defaultTimer: 4000,
    autoAdvanceTimer: 6000,
    autoAdvanceButtonTimer: 1000
  },
  screenType: {
    mobile: 'mobile',
    desktop: 'desktop',
    tablet: 'tablet'
  },
  localStorageKey: {
    refreshToken: 'refresh-token',
    accessToken: 'access-token',
    accessTokenExpire: 'access-token-exp-time',
    auth: 'auth-data',
    code: 'auth-code',
  },
};

export default constants;
