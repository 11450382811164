export default function Config() {
  const env = process.env.REACT_APP_ENV || 'localhost';
  let envConfigModule;
  switch (env) {
    case 'localhost':
      envConfigModule = {
        cloudFront: `https://d1cqzliw3j9qs7.cloudfront.net`,
        saLogin: {
          clientId: '451augcy17',
          redirectBaseUrl: 'https://dev.samsungartstore.com',
          aliasId: 'artstoremobile-dev',
          signOut: 'https://account.samsung.com/accounts/v1/artstoremobile-dev/signOutGate',
          logOutReturnUrl: 'http://localhost:3001',
          saBaseUrl: 'https://account.samsung.com',
        },
      };
      break;
    case 'dev':
      envConfigModule = {
        cloudFront: `https://d1cqzliw3j9qs7.cloudfront.net`,
        saLogin: {
          clientId: '451augcy17',
          redirectBaseUrl: 'https://dev.samsungartstore.com',
          aliasId: 'artstoremobile-dev',
          signOut: 'https://account.samsung.com/accounts/v1/artstoremobile-dev/signOutGate',
          logOutReturnURL: 'https://dev.samsungartstore.com',
          saBaseURL: 'https://account.samsung.com',
        },
      };
      break;
    case 'stage':
      envConfigModule = {
        cloudFront: `https://d1xwq3thutcpdh.cloudfront.net`,
        saLogin: {
          clientId: 'cp6z2mp0x1',
          redirectBaseUrl: 'https://stage.samsungartstore.com',
          aliasId: 'artstoremobile-stage',
          signOut: 'https://account.samsung.com/accounts/v1/artstoremobile-stage/signOutGate',
          logOutReturnURL: 'https://stage.samsungartstore.com',
          saBaseURL: 'https://account.samsung.com',
        },
      };
      break;
    case 'prod':
      envConfigModule = {
        cloudFront: `https://da9yavyx0puiz.cloudfront.net`,
        saLogin: {
          clientId: 'y35e9r6pol',
          redirectBaseUrl: 'https://samsungartstore.com',
          aliasId: 'artstoremobile-prod',
          signOut: 'https://account.samsung.com/accounts/v1/artstoremobile-prod/signOutGate',
          logOutReturnURL: 'https://samsungartstore.com',
          saBaseURL: 'https://account.samsung.com',
        },
      };
      break;
    default:
      envConfigModule = {
        cloudFront: `https://d1cqzliw3j9qs7.cloudfront.net`,
        saLogin: {
          clientId: '451augcy17',
          redirectBaseUrl: 'https://dev.samsungartstore.com',
          aliasId: 'artstoremobile-dev',
          signOut: 'https://account.samsung.com/accounts/v1/artstoremobile-dev/signOutGate',
          logOutReturnURL: 'https://dev.samsungartstore.com',
          saBaseURL: 'https://account.samsung.com',
        },
      };
  }

  return {
    baseUrl:
      process.env.REACT_APP_BASE_URL ||
      (env === 'localhost'
        ? 'http://localhost:3000'
        : `https://api-${env}.samsungartplus.com`),
    appPrefix: process.env.REACT_APP_APP_PREFIX || 'api',
    apiVersion: process.env.REACT_APP_API_VERSION || 'v1',
    clientKey:
      process.env.REACT_APP_CLIENTKEY || 'unknown-client',
    configModule: envConfigModule,
    stateExpiryMinutes: process.env.REACT_APP_STATE_EXPIRY_MINUTES || 5,
    favoriteFeatureFlag: process.env.REACT_APP_FAVORITE_FEATURE_FLAG === 'true',
    samsungAccountFeatureFlag: process.env.REACT_APP_SAMSUNG_ACCOUNT_FEATURE_FLAG === 'true',
  };
}
