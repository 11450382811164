import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link, useLocation } from 'react-router-dom';
import styles from './Footer.module.css';
import colorStyles from '../../styles/Color.module.css';
import { setPreviousPath } from '../../redux/reducers/userSlice';
import { useAppDispatch } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <footer className={`${styles.footer} ${colorStyles.bgPrimaryDarkColor}`}>
      <Stack
        direction={'column'}
        alignItems={{ sm: 'flex-start' }}
        justifyContent={{ sm: 'space-between', md: 'space-around' }}
        spacing={4}
      >
        <Box>
          <img
            src="/images/logo_stack.svg"
            alt="Footer logo"
            width={'124.26px'}
            height={'32px'}
          />
        </Box>

        <Box sx={{ margin: '24px' }}>
          <Typography variant="body1" className={styles.linksHeading}>
            {t('miscFooterUseful')}
          </Typography>
          <ul className={styles.footerLink}>
            <li>
              <Link
                to="https://www.samsung.com/us/support/answer/ANS00076727/"
                target={'_blank'}
                rel="noopener noreferrer"
                className={styles.footerLinkItem}
              >
                {t('miscFooterUseArtMode')}
              </Link>
            </li>

            <li>
              <Link
                to="https://www.samsung.com/us/tvs/the-frame/highlights/?cid=us_ow_app_artstore_frametv_ongoing_frame4art_article_home_anon"
                target={'_blank'}
                rel="noopener noreferrer"
                className={styles.footerLinkItem}
              >
                {t('miscFooterFrameTV')}
              </Link>
            </li>
            <li>
              <Link
                to="https://news.samsung.com/global/tag/samsung-art-store"
                target={'_blank'}
                rel="noopener noreferrer"
                className={styles.footerLinkItem}
              >
                {t('miscFooterReadMore')}
              </Link>
            </li>
            <li>
              <Link
                to="/collections/search"
                onClick={() => dispatch(setPreviousPath({path: location.pathname + location.search, type: 'search'}))}
                rel="noopener noreferrer"
                className={styles.footerLinkItem}
              >
                {t('miscFooterSearch')}
              </Link>
            </li>
          </ul>
        </Box>
      </Stack>

      <Typography variant="body1" className={styles.footerCopyright}>
        &copy; {t('miscFooterNote')}{' '}
        <Link
          to="/terms-and-conditions"
          onClick={() => dispatch(setPreviousPath({path: location.pathname + location.search, type: 'terms-conditions'}))}
        >
          {t('miscFooterTerms')}
        </Link>
      </Typography>

      <Stack className={styles.secondaryNav} direction={'row'}>
        <Link
          to="https://www.samsung.com/us/account/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.navLink}
        >
          {t('miscFooterPrivacy')}
        </Link>
        <Link
          to="https://www.samsung.com/us/common/legal/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.navLink}
        >
          {t('miscFooterLegal')}
        </Link>
      </Stack>
    </footer>
  );
}
