import React, { useEffect } from 'react';
import styles from './styles.module.css';
import { CollectionPageStatusEnum } from './CollectionPageStatus.enum';
import LoadingState from './loadingState';
import TitleSection from './titleSection';
import ImageSection from './imageSection';
import NoCollectionErrorState from './noCollectionErrorState';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  artistCollectionContentData,
  fetchArtistCollectionContent,
  status as artistCollectionStatus,
} from '../../redux/reducers/artistCollectionContentSlice';
import { ArtistCollectionContentParamsInterface } from '../../interfaces/collections.interface';
import { logClientError } from '../../redux/reducers/clientSlice';
import { resetFavoriteListSavedStatus } from '../../redux/reducers/contentStoreSlice';
import { isUserLoggedIn, setRedirectUrl } from '../../redux/reducers/userSlice';
import Common from '../../utils/Common';

type PropsInterface = {
  pageType: string;
  collectionShareablePath: string;
  favoriteHandler: any;
  findFavorite: any;
}

const ArtistCollectionBody = (props: PropsInterface) => {
  const { pageType, collectionShareablePath, favoriteHandler, findFavorite } = props;
  const { updateAndGetQueryParams } = Common();
  const artistCollectionsContent = useAppSelector(artistCollectionContentData);
  const useArtistCollectionStatus = useAppSelector(artistCollectionStatus);
  const isLoggedIn = useAppSelector(isUserLoggedIn);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const { artistId } = updateAndGetQueryParams.artistCollectionsPage();

    const params: ArtistCollectionContentParamsInterface = {
      country: 'US',
      languageCode: 'US',
      year: '2022',
      model: '22_PONTUSM_FTV',
      artistId
    };

    if (isLoggedIn) {
      try {
        isInvalidData(artistId) && dispatch(fetchArtistCollectionContent(params));
      } catch (e) {
        dispatch(fetchArtistCollectionContent(params));
        dispatch(logClientError(`ARTIST_COLLECTION: ${e.message}`));
      }

      dispatch(resetFavoriteListSavedStatus());
    }
    dispatch(setRedirectUrl('collections/artist'));

    window.scrollTo(0, 0); // Put the top of the page into view at load time
  }, [isLoggedIn]);

  const isInvalidData = (artistId: string) => {
    return !artistCollectionsContent || !artistCollectionsContent.length ||
      (
        artistCollectionsContent.length && artistCollectionsContent[0].artistId !== artistId
      );
  }

  return (
    <Box className={styles.container}>
      {useArtistCollectionStatus === CollectionPageStatusEnum.LOADING && (
        <LoadingState />
      )}

      {useArtistCollectionStatus === CollectionPageStatusEnum.FULFILLED &&
        (artistCollectionsContent && artistCollectionsContent.length > 0 && (artistCollectionsContent[0].contents.sixteenByNine.length || artistCollectionsContent[0].contents.nineBySixteen.length) ? (
          <>
            <TitleSection
              name={artistCollectionsContent[0].name}
              collectionShareablePath={collectionShareablePath}
              pageType={pageType}
            />
            <ImageSection
              contents={artistCollectionsContent[0].contents}
              favoriteHandler={favoriteHandler}
              findFavorite={findFavorite}
              pageType={pageType}
            />
          </>
        ) : (
          <NoCollectionErrorState />
        ))}
    </Box>
  );
};

export default ArtistCollectionBody;