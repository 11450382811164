import React, { useState } from 'react';
import styles from './profile.dialog.module.css';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import CloseIcon from '../closeIcon';
import Config from '../../../config/Config';
import Avatar from '@mui/material/Avatar';
import { isSASessionExpired, isSubscribedUser, isUserSALoggedIn, logoutSA } from '../../../redux/reducers/userSlice';
import {
  resetFavoritesData,
  fetchUserSubscriptionDetails,
  userSubscriptionDetailsData,
  userSubscriptionDetailsStatus,
} from '../../../redux/reducers/contentStoreSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import Common from '../../../utils/Common';
import Loading from '../../Loading';
import moment from 'moment';
import ProfileIcon from '../../icons/profileIcon';

type PropsInterface = {
  handleClose: any;
  data: any;
}
export default function ProfileDialog(props: PropsInterface) {
  const { setPageQueryParams } = Common();
  const randomState = '12345678901234567890123456789011'; //cryptoRandomString({ length: 32 });
  const clientId = Config().configModule.saLogin.clientId;
  const redirectBaseUrl = Config().configModule.saLogin.redirectBaseUrl;
  const aliasId = Config().configModule.saLogin.aliasId;
  const logOutReturnURL = Config().configModule.saLogin.logOutReturnURL;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const fullPathLink = redirectBaseUrl + location.pathname;
  const isSALoggedIn = useAppSelector(isUserSALoggedIn);
  const userSubscriptionDetailsAPIStatus = useAppSelector(userSubscriptionDetailsStatus);
  const isSAUserSessionExpired = useAppSelector(isSASessionExpired);
  const isSubscribed = useAppSelector(isSubscribedUser);
  const userSubscriptionDetails = useAppSelector(userSubscriptionDetailsData);
  const [memberSince, setMemberSince]: any = useState();

  React.useEffect(() => {
    const params: any = {
      country: 'US',
      language: 'en_US',
      model: '22_PONTUSM_FTV',
      deviceId: 'MOBILEWEBAPIS'
    };
    dispatch(fetchUserSubscriptionDetails(params));
  }, []);

  React.useEffect(() => {
    if (userSubscriptionDetails && userSubscriptionDetails.isSubscribed) {
      if (userSubscriptionDetails.subscription.length > 0) {
        const dates: any = [];

        userSubscriptionDetails.subscription.forEach((item: any, index: number) => {
          if (item.isSubscribed) {
            dates.push(moment(item.first_sub_start_date));
          }
        })

        setMemberSince(moment.min(dates).format('MMMM YYYY'));
      }
    }
  }, [userSubscriptionDetails])

  const getAvatarInitials = () => {
    let initials =  props.data.givenName && props.data.givenName.length > 0 ? props.data.givenName.substring(0,1) : '';
    return initials.toUpperCase();
  }

  const renderAvatar = () => {
    if (props.data.picture) {
      return <Avatar src={props.data.picture} sx={{ bgcolor: '#00D8FF', width: 72, height: 72}} className={styles.ProfileAvatar} />
    } else {
      return <span><ProfileIcon/></span>
    }
  }

  const handleLogout = () => {
    setPageQueryParams();
    dispatch(logoutSA());
    dispatch(resetFavoritesData());
    props.handleClose();
  };

  React.useEffect(() => {
    if (isSAUserSessionExpired) {
      handleLogout();
    }
  }, [isSAUserSessionExpired])

  return (
    <div className={styles.dialogWrapper}>
      {/*{userSubscriptionDetailsAPIStatus === 'loading' && <Loading />}*/}
      <div className={styles.closeBtn}>
        <CloseIcon handleClose={props.handleClose} />
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.avatarWrapper}>
          {renderAvatar()}
        </div>
        <div>
          <Typography variant={'body1'} className={styles.name}>
            {props.data.givenName} {props.data.familyName}
          </Typography>
          <Typography variant={'body1'} className={styles.email}>
            {props.data.email}
          </Typography>
        </div>
        <div className={styles.memberWrapper}>
          {isSubscribed ? (
            <>
              <Typography variant={'body1'} className={styles.memberTitle}>
                {t('miscMemberSince')}
              </Typography>
              <Typography variant={'body1'} className={styles.memberDescription}>
                {t('miscMemberDescription')}
              </Typography>
            </>
          ) : (
            <div className={styles.noneMemberWrapper}>
              <a
                href={`/landingpage#join`}
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
                onClick={props.handleClose}
              >
                <Typography variant={'body1'} className={styles.joinBtnText}>
                  {t('miscIsSubscribe')}
                </Typography>
              </a>
            </div>
          )}
        </div>
        <a
          href={`https://account.samsung.com/accounts/v1/${aliasId}/signOutGate?client_id=${clientId}&signOutURL=${fullPathLink}&state=${randomState}`}
          rel="noopener noreferrer"
          onClick={handleLogout}
          style={{ textDecoration: 'none' }}
        >
          <Typography variant={'body1'} className={styles.logoutBtnText}>
            {t('miscSignOut')}
          </Typography>
        </a>
      </div>
    </div>
  )
}