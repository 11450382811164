import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState, store } from '../store';
import { artistCollectionContent } from '../api/collectionsAPI';
import {
  ArtistCollectionContentParamsInterface,
  ArtistCollectionContentResponseInterface
} from '../../interfaces/collections.interface';
import { revertAll, clearAllState } from '../actions';

export interface StateInterface {
  status: 'idle' | 'loading' | 'failed' | 'fulfilled';
  artistCollectionContentData: ArtistCollectionContentResponseInterface[];
}

const initialState: StateInterface = {
  status: 'idle',
  artistCollectionContentData: [],
};

export const fetchArtistCollectionContent = createAsyncThunk(
  'content/artist-collection-content',
  async (params: ArtistCollectionContentParamsInterface) => {
    try {
      const apiKey: string = store.getState().user.apiToken;
      const response = await artistCollectionContent(apiKey, params);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  }
);

export const artistCollectionContentSlice = createSlice({
  name: 'artistCollectionContent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchArtistCollectionContent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchArtistCollectionContent.fulfilled, (state, action) => {
        state.status = 'fulfilled';

        if (action.payload && Array.isArray(action.payload)) {
          state.artistCollectionContentData = action.payload;
        }
      })
      .addCase(fetchArtistCollectionContent.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(revertAll, () => initialState)
      .addCase(clearAllState, () => initialState);
  },
});

export const status = (state: RootState) => state.artistCollectionContent.status;
export const artistCollectionContentData = (state: RootState) =>
  state.artistCollectionContent.artistCollectionContentData;
export default artistCollectionContentSlice.reducer;
