import { Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Footer from '../../components/footer/Footer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useLocation } from 'react-router-dom';
import {
  isUserLoggedIn,
  isUserSALoggedIn,
  setRedirectUrl,
  setSelectedContent,
  selectedContent,
  clearSelectedContent,
} from '../../redux/reducers/userSlice';
import Loading from '../../components/Loading';
import Logo from '../../components/logo/Logo';
import {
  fetchFavorites,
  saveFavorites,
  favoritesData,
  resetFavoriteSavedStatus,
  favoriteSavedStatus,
  resetFavoritesData,
  status as favoriteApiStatus
} from '../../redux/reducers/contentStoreSlice';
import CustomDialog from '../../components/dialogs/CustomDialog';
import { getParameterByName } from '../../redux/api/apiUtil';
import { logClientError } from '../../redux/reducers/clientSlice';
import Config from '../../config/Config';
import CollectionsBody from '../../components/collectionPage/collectionsBody';
import ArtistCollectionBody from '../../components/collectionPage/artistCollectionBody';
import SearchCollectionBody from '../../components/collectionPage/searchCollectionBody';
import { CollectionPageStatusEnum } from '../../components/collectionPage/CollectionPageType.enum';

type PropsInterface = {
  pageType: string;
}

export default function CollectionPage(props: PropsInterface) {
  const dispatch = useAppDispatch();
  const favoritesList = useAppSelector(favoritesData);
  const favoriteStatus = useAppSelector(favoriteSavedStatus);
  const favoriteAPIStatus = useAppSelector(favoriteApiStatus);
  const selectedFavoriteContent = useAppSelector(selectedContent);
  const location = useLocation();
  const collectionShareablePath = window.location.origin + location.pathname + location.search;
  const isLoggedIn = useAppSelector(isUserLoggedIn);
  const isSALoggedIn = useAppSelector(isUserSALoggedIn);
  const [openLoginDialog, setOpenLoginDialog] = React.useState(false);
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [afterSignIn, setAfterSignIn] = useState(false);
  const [selectedContentData, setSelectedContentData] = useState({
    id: '',
    name: ''
  });

  useEffect(() => {
    if (getParameterByName('code')) {
      setAfterSignIn(true);
    }
  }, [])

  useEffect(() => {
    if (isSALoggedIn) {
      if (selectedFavoriteContent && selectedFavoriteContent.id) {
        setFavoriteContent(selectedFavoriteContent.id);
        setSelectedContentData(selectedFavoriteContent);
        dispatch(clearSelectedContent());
      }

      try {
        if (
          !favoritesList ||
          !favoritesList.content ||
          !favoritesList.content.length
        ) {
          fetchFavoritesData();
        }
      } catch (e) {
        fetchFavoritesData();
        dispatch(logClientError(`COLLECTION | FAVORITES: ${e.message}`));
      }
    } else {
      dispatch(resetFavoritesData());
    }
  }, [isSALoggedIn]);

  useEffect(() => {
    if (favoriteStatus === 'saved' || favoriteStatus.includes('exist')) {
      if (favoriteStatus.includes('exist')) {
        fetchFavoritesData();
        if (favoriteStatus === 'already-exist') {
          setSnackBarMessage('Added to ‘My Mobile Artworks’ row inside Samsung Art Store on The Frame TV');
        } else if (favoriteStatus === 'not-exist') {
          setSnackBarMessage('Removed from ‘My Mobile Artworks’ row inside Samsung Art Store on The Frame TV');
        }
      }
      if (afterSignIn &&
        (
          (selectedFavoriteContent && selectedFavoriteContent.name) ||
          (selectedContentData && selectedContentData.name)
        )
      ) {
        setOpenConfirmDialog(true);
        setAfterSignIn(false);
      } else {
        setShowSnackbar(true);
        dispatch(resetFavoriteSavedStatus());
        setAfterSignIn(false);
      }
    } else if (favoriteStatus === 'invalid-token') {
      setOpenLoginDialog(true); // open login dialog
      dispatch(setRedirectUrl('collections'));
      dispatch(resetFavoriteSavedStatus());
    } else if (favoriteStatus === 'failed') {
      setSnackBarMessage('Something went wrong! Please try again later')
      setShowSnackbar(true);
      dispatch(resetFavoriteSavedStatus());
    }
  }, [favoriteStatus]);

  useEffect(() => {
    if (favoriteAPIStatus !== 'loading') {
      setIsLoading(false);
    }
  }, [favoriteAPIStatus]);

  const favoriteHandler = (contentId: string, artName: any) => {
    const selectedContent = {
      id: contentId,
      name: artName
    }
    setSelectedContentData(selectedContent);
    if (isSALoggedIn) {
      setFavoriteContent(contentId);
    } else {
      dispatch(setSelectedContent(selectedContent));
      setOpenLoginDialog(true);
    }
  }

  const setFavoriteContent = (contentId: string) => {
    setIsLoading(true);
    let exist = findFavorite(contentId);

    if (!exist) {
      setSnackBarMessage('Added to ‘My Mobile Artworks’ row inside Samsung Art Store on The Frame TV');
    } else {
      setSnackBarMessage('Removed from ‘My Mobile Artworks’ row inside Samsung Art Store on The Frame TV');
    }

    let data = {
      country: 'US',
      favorites: {
        content_id: contentId,
        favourite: exist ? 'NO' : 'YES',
        collection_name: 'My Own List',
      }
    }
    dispatch(resetFavoriteSavedStatus());
    Config().favoriteFeatureFlag && dispatch(saveFavorites(data));
  }

  const findFavorite = (contentId: string) => {
    let index = favoritesList && favoritesList.content && favoritesList.content.findIndex((item: any) => item.content_id === contentId)
    if (index >= 0) {
      return true;
    } else {
      return false;
    }
  }

  const fetchFavoritesData = () => {
    const params: any = {
      country: 'US',
    };
    Config().favoriteFeatureFlag && dispatch(fetchFavorites(params.country));
  }

  const resetConfirmDialog = () => {
    dispatch(resetFavoriteSavedStatus());
    dispatch(clearSelectedContent());
    setShowSnackbar(false);
    setOpenConfirmDialog(false);
  }

  const resetLoginDialog = () => {
    setOpenLoginDialog(false);
  }

  return !isLoggedIn ? (
    <Loading />
  ) : (
    <>
      {/*{isLoading && <Loading />}*/}
      <Logo borderBottom={false} backArrow={true} removeHomeNavigateLink={false} />
      <main style={{ overflow: 'hidden' }}>
        {props.pageType === CollectionPageStatusEnum.COLLECTIONS && (
          <CollectionsBody
            pageType={props.pageType}
            collectionShareablePath={collectionShareablePath}
            favoriteHandler={favoriteHandler}
            findFavorite={findFavorite}
          />
        )}

        {props.pageType === CollectionPageStatusEnum.ARTIST && (
          <ArtistCollectionBody
            pageType={props.pageType}
            collectionShareablePath={collectionShareablePath}
            favoriteHandler={favoriteHandler}
            findFavorite={findFavorite}
          />
        )}

        {props.pageType === CollectionPageStatusEnum.SEARCH && (
          <SearchCollectionBody
            pageType={props.pageType}
            favoriteHandler={favoriteHandler}
            findFavorite={findFavorite}
          />
        )}
      </main>
      <Footer />
      {openLoginDialog && <CustomDialog displayDialog={openLoginDialog} data={{}} resetDisplayDialogStatus={resetLoginDialog} dialogType='login' />}
      {openConfirmDialog && <CustomDialog displayDialog={openConfirmDialog} data={{title: selectedContentData && selectedContentData.name, exist: findFavorite(selectedContentData.id)}} resetDisplayDialogStatus={resetConfirmDialog} dialogType='confirm' />}
      {showSnackbar && snackBarMessage && (
        <Snackbar
          sx={{
            '.MuiPaper-root': {
              background: '#1D1D2F',
              textAlign: 'center',
            }
          }}
          open={showSnackbar}
          onClose={resetConfirmDialog}
          autoHideDuration={4000}
          message={snackBarMessage}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        />
      )}
    </>
  );
}
