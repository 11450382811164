import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState, store } from '../store';
import * as ArtAPI from '../api/artAPI';
import { revertAll, clearAllState } from '../actions';
interface MeetTheArtistData {
  artistName: string;
  description: string;
  youtubeEmbedDetails: {
    title: string;
    embedId: string;
  };
  showImage: Boolean;
  imageURL: string;
  cta?: {
    title?: string;
    url?: string;
  };
}
export interface StateInterface {
  status: 'idle' | 'loading' | 'failed';
  meetTheArtistData: MeetTheArtistData[];
}

const initialState: StateInterface = {
  status: 'idle',
  meetTheArtistData: [],
};

export const fetchMeetTheArtist = createAsyncThunk(
  'meetTheArtist/fetchMeetTheArtist',
  async () => {
    try {
      const apiKey: string = store.getState().user.apiToken;
      const response = await ArtAPI.getMeetTheArtist(apiKey);
      return response.data;
    } catch (error) {
      console.log('ERROR: Getting Meet The Artist Data: ', error);
    }
  }
);

export const meetTheArtistSlice = createSlice({
  name: 'meetTheArtist',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMeetTheArtist.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMeetTheArtist.fulfilled, (state, action) => {
        state.status = 'idle';

        if (action.payload && action.payload.length) {
          state.meetTheArtistData = action.payload;
        }
      })
      .addCase(fetchMeetTheArtist.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(revertAll, () => initialState)
      .addCase(clearAllState, () => initialState);
  },
});

export const status = (state: RootState) => state.meetTheArtist.status;
export const meetTheArtistData = (state: RootState): MeetTheArtistData[] =>
  state.meetTheArtist.meetTheArtistData;
export default meetTheArtistSlice.reducer;
