import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { AxiosResponse } from 'axios/index';
import { store } from '../store';
import { logoutSA, setSASessionExpired } from '../reducers/userSlice';
import Constants from '../../config/Constants';
import { getRefreshToken } from './loginApi';
//import * as packageJson from "../../../package.json";

// This service is only used by APIs that need token key
export const samsungAccountAPIService = axios.create();

export const axiosInterceptorOnFulfilled = (response: AxiosResponse): AxiosResponse => {
    return response;
}

export const axiosInterceptorOnRejected = async function (error: any) {
    let { isSALoggedIn }  = store.getState().user;
    console.log('Intercepter error:', error);
    console.log('Intercepter error isUserSALoggedIn:', isSALoggedIn);
    // For Network Errors got to login page
    if (error.message === 'Network Error') {
        console.log('Intercepter Network error logging out:', error.message);
        // localStorage.removeItem(Constants.localStorageKey.accessToken);
        // localStorage.removeItem(Constants.localStorageKey.refreshToken);
        // localStorage.removeItem(Constants.localStorageKey.auth);
        // window.location.href = '/login';
        store.dispatch(logoutSA());
        return;
    }
    const originalRequest = error.config;
    if (error.response && [401, 404, 500].includes(error.response.status) && !originalRequest._retry) {
        console.log('Intercepter refresh logic started:', error.response.status);

        originalRequest._retry = true;
        const accessToken = await refreshAuth();

        if (accessToken) {
            originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
            return samsungAccountAPIService(originalRequest);
        }
    }
    return Promise.reject(error);
}

export const refreshAuth = async () => {
    const paramData : string = localStorage.getItem(Constants.localStorageKey.accessToken) || '';
    if (!paramData) {
        localStorage.removeItem(Constants.localStorageKey.accessToken);

        store.dispatch(logoutSA());
        // window.location.href = '/landingpage';

        return;
    }

    const access_token: string = localStorage.getItem(Constants.localStorageKey.accessToken) || '';

    try {
        const result = await getRefreshToken(access_token);
        if (result.status > 400) {
            localStorage.removeItem(Constants.localStorageKey.accessToken);

            store.dispatch(logoutSA());
            // window.location.href = '/landingpage';

            return;
        }

        const accessToken = result && result.data && result.data.accessToken;

        if (accessToken) {
            localStorage.setItem(Constants.localStorageKey.accessToken, accessToken);
            return Buffer.from(accessToken, 'base64').toString();
        } else {
            localStorage.removeItem(Constants.localStorageKey.accessToken);

            store.dispatch(logoutSA());
            // window.location.href = '/landingpage';

            return;
        }
    } catch (e) {
        console.log('REFRESH_LOGIC ==>> ERROR: ', e.message);
        store.dispatch(logoutSA());
        store.dispatch(setSASessionExpired());
        return;
    }
};

samsungAccountAPIService.interceptors.response.use(axiosInterceptorOnFulfilled, axiosInterceptorOnRejected);

export function getApiHeaders() {
    const now = new Date();
    return {
        'X-Correlation-Id': uuidv4(),
        'Accept-Language': 'en-US',
        'X-Started': now.toISOString(),
        'X-Created': now.toISOString(),
        'X-Context-Category': 'default',
        'X-Context-Id': 'none',
        'X-Client-Name': 'I Am Zen UI',
        //'X-Client-Version': packageJson.version,
        'X-Client-Variant': '',
    };
}

export function getAuthorizedApiHeaders(token: any) {
    return {
        ...getApiHeaders(),
        Authorization: `Bearer ${token}`
    }
}

export function getParameterByName(name: any) {
    const match = RegExp('[?&]' + name + '=([^&]*)')
            .exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}