import React from 'react';
import styles from './login.error.dialog.module.css';
import Typography from '@mui/material/Typography';
import CloseIcon from '../closeIcon';
import ErrorIcon from './errorIcon';

type PropsInterface = {
  handleClose: any;
}
export default function LoginErrorDialog(props: PropsInterface) {

  const handleLearnMore = () => {
    console.log('Learn more clicked!');
  }

  return (
    <div className={styles.dialogWrapper}>
      <div className={styles.closeBtn}>
        <CloseIcon handleClose={props.handleClose} />
      </div>
      <Typography variant={'body1'} className={styles.title}>
        Have you signed into The Frame TV?
      </Typography>
      <div className={styles.iconWrapper}>
        <ErrorIcon/>
      </div>
      <Typography variant={'body1'} className={styles.subTitle}>
        Sign into your Samsung account on The Frame TV to see your added artworks
      </Typography>
      <Typography variant={'body1'} className={styles.subText}>
        What is The Frame TV?
      </Typography>
      <Typography variant={'body1'} className={styles.learnMoreText} onClick={handleLearnMore}>
        Learn more
      </Typography>
    </div>
  )
}