import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState, store } from '../store';
import { collectionContent } from '../api/collectionsAPI';
import {
  CollectionContentParamsInterface,
  CollectionContentResponseInterface,
} from '../../interfaces/collections.interface';
import { revertAll, clearAllState } from '../actions';

export interface StateInterface {
  status: 'idle' | 'loading' | 'failed' | 'fulfilled';
  collectionContentData: CollectionContentResponseInterface[];
}

const initialState: StateInterface = {
  status: 'idle',
  collectionContentData: [],
};

export const fetchCollectionContent = createAsyncThunk(
  'content/collection-content',
  async (params: CollectionContentParamsInterface) => {
    try {
      const apiKey: string = store.getState().user.apiToken;
      const response = await collectionContent(apiKey, params);

      return response.data;
    } catch (e) {
      console.error(e);
    }
  }
);

export const collectionContentSlice = createSlice({
  name: 'collectionContent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCollectionContent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCollectionContent.fulfilled, (state, action) => {
        state.status = 'fulfilled';

        if (action.payload && Array.isArray(action.payload)) {
          state.collectionContentData = action.payload;
        }
      })
      .addCase(fetchCollectionContent.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(revertAll, () => initialState)
      .addCase(clearAllState, () => initialState);
  },
});

export const status = (state: RootState) => state.collectionContent.status;
export const collectionContentData = (state: RootState) =>
  state.collectionContent.collectionContentData;
export default collectionContentSlice.reducer;
