import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import { disableRightClick } from '../../utils/Common';
import Config from '../../config/Config';
import FavoriteCheckedIcon from '../icons/favoriteCheckedIcon';
import FavoriteUncheckedIcon from '../icons/favoriteUncheckedIcon';
import { CollectionPageStatusEnum } from './CollectionPageType.enum';
import ChevronGreyIcon from '../icons/chevronGreyIcon';
import SignInTooltipCta from '../signInTooltipCta/signInTooltipCta';

type PropsInterface = {
  index: number;
  id: string;
  onClickHandler: any;
  marketingExcluded: boolean;
  imageUrl: string;
  title: string;
  artWorkName: string;
  artistName: string;
  buttonPendingStatus: any;
  findFavorite: any;
  favoriteHandler: any;
  artistId: string;
  pageType: string;
  isSubscribed: boolean;
}

const NineBySixteenImageSection = (props: PropsInterface) => {
  const { index, onClickHandler, buttonPendingStatus, findFavorite, favoriteHandler, pageType, isSubscribed } = props;
  const isBlockedImage: boolean = props.marketingExcluded && !isSubscribed;

  return (
    <Box key={index} className={styles.artworkItem}>
      <Box className={styles.imgContainer}>
        <SignInTooltipCta disableHover={!isBlockedImage}>
          <Link
            to={`/collection/details?id=${props.id}`}
            onClick={() => onClickHandler(props.id)}
          >
            <img
              style={{ aspectRatio: '9/16' }}
              src={
                isBlockedImage
                  ? '/images/image_excluded_placeholder_vertical.png'
                  : props.imageUrl ||
                  '/images/image_error_placeholder.svg'
              }
              alt={props.title}
              onContextMenu={disableRightClick}
            />
          </Link>
        </SignInTooltipCta>
      </Box>
      <Typography
        variant="caption"
        className={styles.artworkText}
      >
        <span className={styles.artworkName}>
          {props.artWorkName}
        </span>
        {pageType !== CollectionPageStatusEnum.ARTIST ? (
          <>
            <span className={styles.bullet}>&#183;</span>
            {props.artistId ? (
              <Link
                to={`/collections/artist?id=${props.artistId}`}
                onClick={() => onClickHandler(props.artistId)}
                className={styles.link}
              >
                <span className={styles.artistName}>
                  {props.artistName}
                  <span className={styles.chevronIcon}>
                    <ChevronGreyIcon />
                  </span>
                </span>
              </Link>
            ) : (
              <span className={styles.artistName}>
                {props.artistName}
              </span>
            )}
          </>
        ) : ''}
        {Config().favoriteFeatureFlag
          ? buttonPendingStatus(props.id)
            ? (
              <span className={styles.favoriteIconPortrait}>
                {findFavorite(props.id) ? <FavoriteCheckedIcon disabled={true} /> : <FavoriteUncheckedIcon disabled={true} />}
              </span>
            )
            : (
              <span className={styles.favoriteIconPortrait} onClick={() => favoriteHandler(props.id, props.artWorkName)}>
                {findFavorite(props.id) ? <FavoriteCheckedIcon disabled={false}  /> : <FavoriteUncheckedIcon disabled={false}  />}
              </span>
            )
          : ''
        }
      </Typography>
    </Box>
  );
};

export default NineBySixteenImageSection;