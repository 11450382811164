import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { SelectChangeEvent } from '@mui/material/Select';
import LoginDialog from './login/LoginDialog';
import LoginConfirmationDialog from './loginConfirmation/LoginConfirmationDialog';
import LoginErrorDialog from './loginError/LoginErrorDialog';
import ProfileDialog from './profile/ProfileDialog';
import { useAppSelector } from '../../redux/hooks';
import { isUserSALoggedIn } from '../../redux/reducers/userSlice';

type propsInterface = {
    displayDialog: boolean;
    dialogType: string;
    resetDisplayDialogStatus: Function;
    data: object;
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


export default function CustomDialog(props: propsInterface) {
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');
  const resetDisplayDialogStatus = props.resetDisplayDialogStatus;
  const isSALoggedIn = useAppSelector(isUserSALoggedIn)

  useEffect(() => {
    setOpen(props.displayDialog);
  },[props.displayDialog])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetDisplayDialogStatus();
  };

  const handleMaxWidthChange = (event: SelectChangeEvent<typeof maxWidth>) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );
  };

  const handleFullWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFullWidth(event.target.checked);
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        sx={{
          '@media (max-width: 400px)': {
            '& .MuiPaper-root': {
              borderRadius: '8px',
              width: isSALoggedIn ? '320px' : '328px',
              background: '#FFF'
            }
          },
          '@media (min-width: 401px)': {
            '& .MuiPaper-root': {
              width: isSALoggedIn ? '320px' : '504px',
            }}
        }}
      >
        {props.dialogType === 'login' && <LoginDialog handleClose={handleClose} />}
        {props.dialogType === 'profile' && <ProfileDialog data={props.data} handleClose={handleClose} />}
        {props.dialogType === 'confirm' && <LoginConfirmationDialog data={props.data} handleClose={handleClose} />}
        {props.dialogType === 'error' && <LoginErrorDialog handleClose={handleClose} />}
      </BootstrapDialog>
    </React.Fragment>
  );
}