import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState, store } from '../store';
import * as ArtAPI from '../api/artAPI';
import {  GetCategoriesParamsInterface } from '../../interfaces/content.interfaces';
import { revertAll, clearAllState } from '../actions';

export interface StateInterface {
  status: "idle" | "loading" | "failed";
  categoryData: object
}

const initialState: StateInterface = {
  status: "idle",
  categoryData: {}
};

export const fetchCategories = createAsyncThunk(
  "content/fetchCategories",
  async (data: GetCategoriesParamsInterface) => {
      try {
        const apiKey: string = store.getState().user.apiToken;
        const response = await ArtAPI.getCategories(apiKey, data.country, data.languageCode, data.year, data.model);
        return response.data;
      } catch (error) {
        console.log("ERROR: Getting content: ", error);
      }
  }
);

export const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchCategories.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchCategories.fulfilled, (state, action) => {
          state.status = "idle";

          if (action.payload) {
            state.categoryData = action.payload;
          }
        })
        .addCase(fetchCategories.rejected, (state) => {
          state.status = "failed";
        })
        .addCase(revertAll, () => initialState)
        .addCase(clearAllState, () => initialState);
  },
});

export const status = (state: RootState) => state.categories.status;
export const categoryData = (state: RootState) => state.categories.categoryData;
export default categorySlice.reducer;
