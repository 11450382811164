import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState, store } from '../store';
import * as ArtAPI from '../api/artAPI';
import { GetArtistParamsInterface, GetCategoriesParamsInterface } from '../../interfaces/content.interfaces';
import { revertAll, clearAllState } from '../actions';
export interface StateInterface {
  status: "idle" | "loading" | "failed";
  artistData: object,
}

const initialState: StateInterface = {
  status: "idle",
  artistData: {},
};

export const fetchArtist = createAsyncThunk(
    "content/fetchArtist",
    async (data: GetArtistParamsInterface) => {
        try {
          const apiKey: string = store.getState().user.apiToken;
          const response = await ArtAPI.getArtists(apiKey, data.country, data.languageCode, data.year, data.model);
          return response.data;
        } catch (error) {
          console.log("ERROR: Getting content: ", error);
        }
    }
);

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchArtist.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchArtist.fulfilled, (state, action) => {
          state.status = "idle";

          if (action.payload) {
            state.artistData = action.payload;
          }
        })
        .addCase(fetchArtist.rejected, (state) => {
          state.status = "failed";
        })
        .addCase(revertAll, () => initialState)
        .addCase(clearAllState, () => initialState);
  },
});

export const status = (state: RootState) => state.content.status;
export const artistData = (state: RootState) => state.content.artistData;
export default contentSlice.reducer;
