import React from 'react';
import { Typography } from '@mui/material';
import styles from './signInTooltipCta.module.css';
import Tooltip from '@mui/material/Tooltip';
import CustomDialog from '../dialogs/CustomDialog';
import { useAppSelector } from '../../redux/hooks';
import { isSubscribedUser, isUserSALoggedIn } from '../../redux/reducers/userSlice';
import Config from '../../config/Config';
import { useTranslation } from 'react-i18next';

type PropsInterface = {
  children: any;
  disableHover: boolean;
}

const SignInTooltipCta = (props: PropsInterface) => {
  const { t } = useTranslation();
  const { children, disableHover } = props;
  const [openLoginDialog, setOpenLoginDialog] = React.useState(false);
  const isSubscribed = useAppSelector(isSubscribedUser);
  const isSALoggedIn = useAppSelector(isUserSALoggedIn);

  const toolTipCTA = () => {
    return (
      <>
        {isSALoggedIn && !isSubscribed ? (
          <Typography variant={'caption'}>
            {t('miscToolTipBecomeMember')}
          </Typography>
        ) : (
          <Typography variant={'caption'}>
            {t('miscToolTipMembers')}<span onClick={() => setOpenLoginDialog(true)} className={styles.signInCta}>{t('miscToolTipSignIn')}</span> {t('miscToolTipImage')}
          </Typography>
        )}
      </>
    )
  }
  return (
    <>
      {openLoginDialog || disableHover || !Config().samsungAccountFeatureFlag ? children : (
        <Tooltip title={toolTipCTA()} arrow placement="top-end" disableHoverListener={disableHover}>
          {children}
        </Tooltip>
      )}
      {openLoginDialog && <CustomDialog displayDialog={openLoginDialog} data={{}} resetDisplayDialogStatus={() => setOpenLoginDialog(false)} dialogType='login' />}
    </>
  )
};

export default SignInTooltipCta;