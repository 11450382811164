import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState, store } from '../store';
import * as ClientAPI from '../api/clientAPI';
import { revertAll, clearAllState } from '../actions';

export interface StateInterface {
  status: 'idle' | 'loading' | 'failed';
  errorMessage: string;
}

const initialState: StateInterface = {
  status: 'idle',
  errorMessage: ''
};

export const logClientError = createAsyncThunk(
  'client/log-error',
  async (errorMessage: any) => {
    try {
      const apiKey: string = store.getState().user.apiToken;
      await ClientAPI.logClientError(apiKey, errorMessage);
      return errorMessage;
    } catch (error) {
      console.log('ERROR: Logging Client Error: ', error);
    }
  }
);

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logClientError.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(logClientError.fulfilled, (state, action) => {
        state.status = 'idle';
        if (action.payload) {
          state.errorMessage = action.payload;
        }
      })
      .addCase(logClientError.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(revertAll, () => initialState)
      .addCase(clearAllState, () => initialState);
  },
});

export const status = (state: RootState) => state.client.status;
export const clientErrorMessage = (state: RootState) => state.client.errorMessage;
export default clientSlice.reducer;
