import React, { useEffect, useState, useRef } from 'react';
import Lottie from 'lottie-react';
import * as AnimationDataAPI from '../../../redux/api/animationDataAPI';
import Constants from '../../../config/Constants';

import { CircularProgress,  Box } from '@mui/material';
// Swiper Imports
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import './animation-swiper.styles.css';
import { useSwiper } from 'swiper/react';

type RenderSlideProps = {
  data: any;
  slideIndex: number;
  slideRef: any;
  timerRef: any;
}

const styles = {
  loaderBoxContainer: { 
    display: 'flex', 
    justifyContent: 'center',  
    marginTop: '15rem', 
    height: '15rem',
    color: '#1D1D2F' 
  },
  loader: {
    color: '#585DE9'
  }
};

const RenderSlide = (props: RenderSlideProps) => {
  const { data, slideRef, slideIndex, timerRef } = props;
  const swiper = useSwiper();

  const onCompleteHandler = () => {
    timerRef.current = setTimeout(() => {
      slideIndex === 3 ? swiper.slideTo(0) : swiper.slideNext()
    }, 3000)
  }

  return (
    <Lottie
      lottieRef={slideRef}
      animationData={data}
      autoplay={slideIndex === 0}
      loop={0}
      onComplete={onCompleteHandler}
    />
  )
}

const CustomCarouselSlider = (props: any) => {
  const { screenType } = props;
  const [p1Data, setP1Data] = useState(null);
  const [p2Data, setP2Data] = useState(null);
  const [p3Data, setP3Data] = useState(null);
  const [p4Data, setP4Data] = useState(null);
  const animationData = [p1Data, p2Data, p3Data, p4Data];

  const playerRef0 = useRef<any>();
  const playerRef1 = useRef<any>();
  const playerRef2 = useRef<any>();
  const playerRef3 = useRef<any>();
  const playerRefs = [playerRef0, playerRef1, playerRef2, playerRef3];
  const [sliderIndex, setSliderIndex] = useState(0);
  let timer = useRef<any>();

  let initialized = false;
  useEffect(() => {
    // If we don't check for initialize it would call data twice due to react.strictmode
    if (!initialized) {
      initialized = true;
      const getAnimation = async () => {
        if (screenType === Constants.screenType.mobile) {
          const response1 = await AnimationDataAPI.getAnimation(
            `Mobile_p1.json`
          );
          setP1Data(response1.data);
          const response2 = await AnimationDataAPI.getAnimation(
            `Mobile_p2.json`
          );
          setP2Data(response2.data);
          const response3 = await AnimationDataAPI.getAnimation(
            `Mobile_p3.json`
          );
          setP3Data(response3.data);
          const response4 = await AnimationDataAPI.getAnimation(
            `Mobile_p4.json`
          );
          setP4Data(response4.data);
        } else {
          const response1 = await AnimationDataAPI.getAnimation(`p1.json`);
          setP1Data(response1.data);
          const response2 = await AnimationDataAPI.getAnimation(`p2.json`);
          setP2Data(response2.data);
          const response3 = await AnimationDataAPI.getAnimation(`p3.json`);
          setP3Data(response3.data);
          const response4 = await AnimationDataAPI.getAnimation(`p4.json`);
          setP4Data(response4.data);
        }
      };

      getAnimation();
    }

    return () => clearTimeout(timer.current);
  }, []);

  return (
    <>
      { animationData && animationData[0] ?
        <Swiper
          onSlideChange={(swiper) => {
            clearTimeout(timer.current);
            setSliderIndex((prev) => {
              playerRefs[prev].current && playerRefs[prev].current.stop();
              return swiper.activeIndex;
            });
          }}
          onTransitionEnd={(swiper) => {
            playerRefs[swiper.activeIndex].current && playerRefs[swiper.activeIndex].current.play();
          }}
          onSliderFirstMove={() => {
            clearTimeout(timer.current);
          }}
          navigation={true}
          pagination={{ clickable: true }}
          modules={[Pagination, Navigation]}
          className="animationSwiper"
        >
          {animationData.map((animation: any, index: number) => (
            (animation && (Object.keys(animation).length || animation.assets.length)) && (
              <SwiperSlide key={index}>
                <RenderSlide data={animation} slideIndex={index} slideRef={playerRefs[index]} timerRef={timer} />
              </SwiperSlide>
            )
          ))}
        </Swiper>
      :
          <Box sx={styles.loaderBoxContainer} >
            <CircularProgress className="loadingSwiper" />
          </Box>
      }
    </>
  );
};

export default CustomCarouselSlider;
