import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import styles from './NewsletterSubscriptionSection.module.css';
import { emailSubscriptionAPI } from '../../redux/api/brazeEmailSubscriptionAPI';
import { apiToken } from '../../redux/reducers/userSlice';
import { AxiosError } from 'axios';
import { useAppSelector } from '../../redux/hooks';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { validateEmoji } from '../../utils/Common';

type NewsletterPropInterface = {
  setShowEmailConfirmationHandRaiser: Function;
  setSubscriptionStatus: Function;
  setSubscriptionCaption: Function;
};

export default function NewsletterSubscriptionSection(
  props: NewsletterPropInterface
) {
  const [disableFormElement, setDisableFormElement] = useState(false);
  const clientApiToken = useAppSelector(apiToken);
  const { t } = useTranslation();

  const {
    setShowEmailConfirmationHandRaiser,
    setSubscriptionStatus,
    setSubscriptionCaption,
  } = props;

  const subscribeClient = async (clientEmail: string) => {
    try {
      setDisableFormElement(true);

      const { data: emailSubscriptionResponse } = await emailSubscriptionAPI(
        clientApiToken,
        clientEmail
      );

      setDisableFormElement(false);

      emailSubscriptionResponse
        ? setShowEmailConfirmationHandRaiser(true)
        : null;

      emailSubscriptionResponse && emailSubscriptionResponse.message
        ? setSubscriptionStatus(emailSubscriptionResponse.message.toLowerCase())
        : null;

      if (
        emailSubscriptionResponse &&
        emailSubscriptionResponse.message.toLowerCase() === 'success'
      ) {
        setSubscriptionCaption(
          'You have been successfully subscribed to our newsletter'
        );
      } else {
        setSubscriptionCaption(
          'Error submitting your email for subscription. Try again!'
        );
      }
    } catch (e) {
      setDisableFormElement(false);

      const errorMessage: string = Object.entries(e as AxiosError).reduce(
        (acc, curr) => {
          if (curr[0] === 'response') {
            return (acc += curr[1].data.message);
          }

          return acc;
        },
        ''
      );

      setShowEmailConfirmationHandRaiser(true);
      setSubscriptionStatus('');
      setSubscriptionCaption(errorMessage);
    }
  };

  const validate = (values: any) => {
    const errors: any = {}

    if (!values.email) {
      errors.email = 'Required'
    } else {
      const { isEmoji, validatedValue } = validateEmoji(values.email);

      if (isEmoji) {
        formik.setFieldValue('email', validatedValue, true);
      }

      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
      }
    }

    return errors
  }

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validate,
    onSubmit: async (values) => {
      await subscribeClient(values.email)
    },
  })

  return (
    <Box className={styles.subscriptionContainer}>
      <Typography variant="h5" className={styles.heading}>
        {t('miscNewsletterComingToArt')}
      </Typography>
      <Typography variant="body1" className={styles.caption}>
        {t('miscNewsletterSubscribe')}
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <Stack
          direction={{ sm: 'row' }}
          spacing={2}
          alignItems={'center'}
          justifyContent="center"
        >
          <div style={{ height: '40px' }}>
            <input
              type="text"
              name="email"
              autoComplete="on"
              disabled={disableFormElement}
              onChange={formik.handleChange}
              value={formik.values.email}
              className={styles.emailField}
              title="example@mail.com"
              maxLength={128}
            />
            {formik.touched.email && formik.errors.email && (
              <Typography variant={'caption'} className={styles.errorField}>{formik.errors.email}</Typography>
            )}
          </div>

          <button
            type="submit"
            disabled={disableFormElement}
            className={styles.submitBtn}
          >
            {t('miscSubscribe')}
          </button>
        </Stack>

        <Link
          to="https://www.samsung.com/us/account/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.privacyLink}
        >
          {t('miscNewsletterPrivacy')}
        </Link>
      </form>
    </Box>
  );
}
