import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState, store } from '../store';
import * as ArtStoreAPI from '../api/artStoreAPI';
import { GetArtistParamsInterface } from '../../interfaces/content.interfaces';
import { clearAllState, revertAll } from '../actions';
import Constants from '../../config/Constants';

export interface StateInterface {
  status: "idle" | "loading" | "failed" | "invalid-token";
  favoriteSavedStatus: "" | "saved" | "failed" | "invalid-token" | "already-exist" | "not-exist";
  awsS3PresignedUrlData: object,
  favorites: object,
  favoriteListSavedStatus: SaveFavoritesStatus[],
  homescreenConfig: object,
  homescreenConfigPersonal: object,
  userSubscriptionDetails: object,
  userSubscriptionDetailsStatus: "idle" | "loading" | "failed";
}

export interface SaveFavoritesStatus {
  collection_name: string;
  content_id: string;
  favourite: string; // "YES" | "NO"
  requestStatus: "fulfilled" | "pending" | "rejected" | "invalid-token";
}

const initialState: StateInterface = {
  status: "idle",
  favoriteSavedStatus: "",
  awsS3PresignedUrlData: {},
  favorites: {},
  favoriteListSavedStatus: [],
  homescreenConfig: {},
  homescreenConfigPersonal: {},
  userSubscriptionDetails: {},
  userSubscriptionDetailsStatus: 'idle'
};

export const fetchAWSPresignedUrl = createAsyncThunk(
    "contentstore/fetchAWSPresignedUrl",
    async () => {
        try {
          const apiKey: string = store.getState().user.apiToken;
          const response = await ArtStoreAPI.getContentStoreAWSPresignedUrl(apiKey);

          return response.data;
        } catch (error) {
          console.log("ERROR: Getting Presigned Url: ", error);
        }
    }
);


export const fetchFavorites = createAsyncThunk(
  "contentstore/fetchFavorites",
  async (country: string) => {
     try {
        const apiKey: string = store.getState().user.apiToken;
        const saAccessToken: string = localStorage.getItem(Constants.localStorageKey.accessToken) || '';

        const response = await ArtStoreAPI.getContentStoreFavorites(apiKey, saAccessToken, country);

        return response.data;
      } catch (error) {
        console.log("ERROR: Getting Favorites: ", error);
      }
  }
);

export const saveFavorites = createAsyncThunk(
  "contentstore/saveFavorites",
  async (data: any) => {
      try {
        
        const apiKey: string = store.getState().user.apiToken;
        const saAccessToken : string = localStorage.getItem(Constants.localStorageKey.accessToken) || '';

        const response = await ArtStoreAPI.saveContentStoreFavorites(apiKey, saAccessToken, data.country, data.favorites);
        return response.data;

      } catch (error) {
        console.log("ERROR: Getting Favorites: ", error);
        throw error;
      }
  }
);

export const fetchHomescreenConfig = createAsyncThunk(
  "contentstore/fetchHomescreenConfig",
  async (country: string) => {
     try {
        const apiKey: string = store.getState().user.apiToken;
        const saAccessToken: string = localStorage.getItem(Constants.localStorageKey.accessToken) || '';

        const response = await ArtStoreAPI.getHomescreenConfig(apiKey, saAccessToken, country);

        return response.data;
      } catch (error) {
        console.log("ERROR: Getting Favorites: ", error);
      }
  }
);

export const fetchHomescreenConfigPersonal = createAsyncThunk(
  "contentstore/fetchHomescreenConfigPersonal",
  async (country: string) => {
     try {
        const apiKey: string = store.getState().user.apiToken;
        const saAccessToken: string = localStorage.getItem(Constants.localStorageKey.accessToken) || '';

        const response = await ArtStoreAPI.getHomescreenConfigPersonal(apiKey, saAccessToken, country);

        return response.data;
      } catch (error) {
        console.log("ERROR: Getting Favorites: ", error);
      }
  }
);


export const fetchUserSubscriptionDetails = createAsyncThunk(
  "contentstore/fetchUserSubscriptionDetails",
  async (data: any) => {
     try {
        const apiKey: string = store.getState().user.apiToken;
        const saAccessToken: string = localStorage.getItem(Constants.localStorageKey.accessToken) || '';

        const response = await ArtStoreAPI.getUserSubscriptionDetails(apiKey, saAccessToken, data.country, data.language, data.model, data.deviceId);

        return response.data;
      } catch (error) {
        console.log("ERROR: Getting Favorites: ", error);
      }
  }
);

export const contentStoreSlice = createSlice({
  name: 'contentStore',
  initialState,
  reducers: {
    resetFavoriteSavedStatus: (state) => {
      state.favoriteSavedStatus = "";
    },
    resetFavoriteListSavedStatus: (state) => {
      state.favoriteListSavedStatus = [];
    },
    resetFavoritesData: (state) => {
      state.favorites = {}
    },
    resetHomescreenConfigData: (state) => {
      state.homescreenConfig = {}
    },
    resetHomescreenConfigPersonalData: (state) => {
      state.homescreenConfigPersonal = {}
    },
    resetUserSubscriptionDetailsData: (state) => {
      state.userSubscriptionDetails = {}
    }
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchAWSPresignedUrl.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchAWSPresignedUrl.fulfilled, (state, action) => {
          state.status = "idle";

          if (action.payload) {
            state.awsS3PresignedUrlData = action.payload;
          }
        })
        .addCase(fetchAWSPresignedUrl.rejected, (state) => {
          state.status = "failed";
        })
        .addCase(fetchFavorites.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchFavorites.fulfilled, (state, action) => {
          state.status = "idle";

          if (action.payload && action.payload.status && action.payload.status === 'ok') {
            state.favorites = action.payload;
          }
        })
        .addCase(fetchFavorites.rejected, (state) => {
          state.status = "failed";
        })
        .addCase(saveFavorites.pending, (state, action) => {
          
          let favIndex = state.favoriteListSavedStatus.findIndex((item) => item.content_id === action.meta.arg.favorites.content_id);
          if (favIndex >= 0) {
            state.favoriteListSavedStatus[favIndex].requestStatus = action.meta.requestStatus // pending, fulfilled and rejected
          } else {
            let saveFavorite : SaveFavoritesStatus = {
              collection_name: action.meta.arg.favorites.collection_name,
              content_id: action.meta.arg.favorites.content_id,
              favourite: action.meta.arg.favorites.favourite, // YES, NO
              requestStatus: action.meta.requestStatus // pending, fulfilled and rejected
            }
            state.favoriteListSavedStatus.push(saveFavorite)
          }
          state.status = "loading";
        })
        .addCase(saveFavorites.fulfilled, (state, action) => {
          state.status = "idle";

          if (action.meta && action.meta.arg) {
            let favIndex = state.favoriteListSavedStatus.findIndex((item) => item.content_id === action.meta.arg.favorites.content_id);
            if (favIndex >= 0) {
              state.favoriteListSavedStatus[favIndex].requestStatus = action.meta.requestStatus // pending, fulfilled and rejected
            }
          }

          if (action.payload && action.payload.status) {
            if (action.payload.status === 'ok') {
              state.favoriteSavedStatus = 'saved';
              state.favorites = action.payload;
            } else if (action.payload.status === 'fail') {
              if (action.payload.message.toLowerCase().includes('already exist')) {
                state.favoriteSavedStatus = 'already-exist';
              } else if (action.payload.message.toLowerCase().includes(`doesn't exist`)) {
                state.favoriteSavedStatus = 'not-exist';
              } else {
                state.favoriteSavedStatus = 'failed';
              }
            }
          } else {
            state.favoriteSavedStatus = 'failed';
          }
        })
        .addCase(saveFavorites.rejected, (state, action) => {
          if (action && action.error && action.error.message && action.error.message.includes('401')) {
            state.favoriteSavedStatus = "invalid-token";
          } else {
            state.favoriteSavedStatus = "failed";
          }
          if (action.meta && action.meta.arg) {
            let favIndex = state.favoriteListSavedStatus.findIndex((item) => item.content_id === action.meta.arg.favorites.content_id);
            if (favIndex >= 0) {
              state.favoriteListSavedStatus[favIndex].requestStatus = action.meta.requestStatus // pending, fulfilled and rejected
            }
          }
        })
        .addCase(fetchHomescreenConfig.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchHomescreenConfig.fulfilled, (state, action) => {
          state.status = "idle";

          if (action.payload && action.payload.status && action.payload.status === 'ok') {
            state.homescreenConfig = action.payload;
          }
        })
        .addCase(fetchHomescreenConfig.rejected, (state) => {
          state.status = "failed";
        })
        .addCase(fetchHomescreenConfigPersonal.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchHomescreenConfigPersonal.fulfilled, (state, action) => {
          state.status = "idle";

          if (action.payload && action.payload.status && action.payload.status === 'ok') {
            state.homescreenConfigPersonal = action.payload;
          }
        })
        .addCase(fetchHomescreenConfigPersonal.rejected, (state) => {
          state.status = "failed";
        })
        .addCase(fetchUserSubscriptionDetails.pending, (state) => {
          state.userSubscriptionDetailsStatus = "loading";
        })
        .addCase(fetchUserSubscriptionDetails.fulfilled, (state, action) => {
          state.userSubscriptionDetailsStatus = "idle";

          if (action.payload) {
            state.userSubscriptionDetails = action.payload;
          }
        })
        .addCase(fetchUserSubscriptionDetails.rejected, (state) => {
          state.userSubscriptionDetailsStatus = "failed";
        })
        .addCase(revertAll, () => initialState)
        .addCase(clearAllState, () => initialState);
  },
});

export const { resetFavoriteSavedStatus, resetFavoritesData, resetFavoriteListSavedStatus, resetHomescreenConfigData, resetUserSubscriptionDetailsData } = contentStoreSlice.actions;
export const favoriteSavedStatus = (state: RootState) => state.contentStore.favoriteSavedStatus;
export const favoriteListSavedStatus = (state: RootState) => state.contentStore.favoriteListSavedStatus;
export const status = (state: RootState) => state.contentStore.status;
export const awsS3PresignedUrlData = (state: RootState) => state.contentStore.awsS3PresignedUrlData;
export const favoritesData = (state: RootState) => state.contentStore.favorites; 
export const homescreenConfigData = (state: RootState) => state.contentStore.homescreenConfig;
export const homescreenConfigPersonalData = (state: RootState) => state.contentStore.homescreenConfigPersonal;
export const userSubscriptionDetailsData = (state: RootState) => state.contentStore.userSubscriptionDetails;
export const userSubscriptionDetailsStatus = (state: RootState) => state.contentStore.userSubscriptionDetailsStatus
export default contentStoreSlice.reducer;
