import React, { useEffect, useState } from 'react';
import styles from './UploadImagePage.module.css';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import Logo from '../../components/logo/Logo';
import { Box, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useLocation } from 'react-router-dom';
import { status, awsS3PresignedUrlData, fetchAWSPresignedUrl } from '../../redux/reducers/contentStoreSlice';
import { isUserLoggedIn } from '../../redux/reducers/userSlice';
import Loading from '../../components/Loading';
import Footer from '../../components/footer/Footer';
import Skeleton from '@mui/material/Skeleton';
import ShareButton from '../../components/shareButton/ShareButton';
import * as UploadImageAPI from '../../redux/api/uploadImageAPI';
import { apiToken, accessTokenArtStore } from '../../redux/reducers/userSlice';
import { PresignedUrlDataInterface } from '../../interfaces/content.interfaces';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';

const UploadImagePage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const currentStatus = useAppSelector(status);
  const presignedUrlData = useAppSelector(awsS3PresignedUrlData);
  const isLoggedIn = useAppSelector(isUserLoggedIn);
  const clientApiToken = useAppSelector(apiToken);
  const clientAccessTokenArtStore = useAppSelector(accessTokenArtStore);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [displayErrorAlert, setDisplayErrorAlert] = React.useState(false);
  const [loadingImage, setLoadingImage] = React.useState(false);


  useEffect(() => {
    // window.scrollTo(0, 0); // Put the top of the page into view at load time
    //dispatch(fetchAWSPresignedUrl());
  }, []);


  const handleClickAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const alertAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseAlert}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleChangeStatus = ({ meta, remove } : any, status: any) => {
    console.log(status, meta);
  };


  const handleSubmit = async (files: any) => {
    setLoadingImage(true);
    const file = files[0];
    console.log('File Details:', file);
    let fileType = file.meta.type;
    let lengthChar = fileType.indexOf("/");
    let extension = fileType.substring(lengthChar+1, fileType.length)
    console.log('File Extention:', extension);

    try {
        let presignedUrl = await UploadImageAPI.getContentStoreAWSPresignedUrl(clientApiToken,clientAccessTokenArtStore, extension);
        console.log('presignedUrl Data:', presignedUrl);
        if (presignedUrl) {
          
              let { data } = presignedUrl;
              const presignedUrlLink: string = data.url; 
              const keyValue: string = data.fields.key; 
              const AWSAccessKeyId: string = data.fields.AWSAccessKeyId;
              const AMZSecurityToken: string = data.fields["x-amz-security-token"]; 
              const policy: string = data.fields.policy; 
              const signature: string = data.fields.signature; 
              const fileLocal: any = file;
              const contentType: string = 'image%2Fjpg';
          
              const result = await UploadImageAPI.uploadImageToPresignedS3(presignedUrlLink, keyValue, AWSAccessKeyId, AMZSecurityToken, policy, signature, fileLocal, contentType);
              console.log('Upload Result::', result)
              setOpenAlert(true);
              setLoadingImage(false);
              setDisplayErrorAlert(false);
              // Clear files after the file is uploaded.
              files.forEach(( f: any ) => f.remove());

        } else {
          setOpenAlert(true);
          setDisplayErrorAlert(true);
          setLoadingImage(false);
        }

      }
      catch (e: any){
        console.log('Upload Exception:', e.message);
        setOpenAlert(true);
        setDisplayErrorAlert(true);
        setLoadingImage(false);
      }
  };

  return !isLoggedIn ? (
    <Loading />
  ) : (
    <>
      <div>
        <Logo borderBottom={false} backArrow={true} removeHomeNavigateLink={false}/>
        <main style={{ overflow: 'hidden' }} className={styles.nineBySixteen}>
          <div className={styles.contentBody}>
            <Box className={styles.container}>
              <>
                <Dropzone
                    onChangeStatus={handleChangeStatus}
                    onSubmit={handleSubmit}
                    maxFiles={1}
                    multiple={false}
                    canCancel={false}
                    accept="image/jpeg"
                    inputContent="Drag Files or Click to Browse"
                    styles={{
                        dropzone: { width: 400, height: 200 },
                        dropzoneActive: { borderColor: "green" },
                    }}
                    />
                  <div className={styles.titleInfoSection}>* Only jpg and jpeg image types are allowed</div>
                  {loadingImage && <div className={styles.titleInfoSection}><LinearProgress /></div> }
              </>
            </Box>
          </div>
          <div>
            <Snackbar
              open={openAlert}
              anchorOrigin={{ vertical:'top', horizontal: 'center' }}
              autoHideDuration={6000}
              onClose={handleCloseAlert}
              message="Uploaded Image Succesfully"
              action={alertAction}
            >
              {displayErrorAlert ?
              (<Alert  action={alertAction} onClose={handleCloseAlert} variant="filled" severity="error">
                  Error: Uploading Image
                </Alert>)
              :
              (<Alert  action={alertAction} onClose={handleCloseAlert} variant="filled" severity="success">
                    Uploaded Image Succesfully
                </Alert>)
              }
            </Snackbar>
          </div>
        </main>
        <Footer/>
      </div>
    </>
  );
};

export default UploadImagePage;