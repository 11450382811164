import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState, store } from '../store';
import { collectionContentDetails } from '../api/collectionsAPI';
import { CollectionContentDetailsParamsInterface } from '../../interfaces/collections.interface';
import { revertAll, clearAllState } from '../actions';

export interface StateInterface {
  status: 'idle' | 'loading' | 'failed' | 'fulfilled';
  collectionContentDetailsData: any;
}

const initialState: StateInterface = {
  status: 'idle',
  collectionContentDetailsData: {}
};

export const fetchCollectionContentDetails = createAsyncThunk(
  'content/collection-content-details',
  async (params: CollectionContentDetailsParamsInterface) => {
    try {
      const apiKey: string = store.getState().user.apiToken;
      const response = await collectionContentDetails(apiKey, params);

      return response.data;
    } catch (e) {
      console.error(e);
    }
  }
);

export const collectionContentDetailsSlice = createSlice({
  name: 'collectionContentDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCollectionContentDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCollectionContentDetails.fulfilled, (state, action) => {
        state.status = 'fulfilled';

        if (action.payload) {
          state.collectionContentDetailsData = action.payload;
        }
      })
      .addCase(fetchCollectionContentDetails.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(revertAll, () => initialState)
      .addCase(clearAllState, () => initialState);
  },
});

export const status = (state: RootState) => state.collectionContentDetails.status;
export const collectionContentDetailsData = (state: RootState) =>
  state.collectionContentDetails.collectionContentDetailsData;
export default collectionContentDetailsSlice.reducer;
