import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import styles from './FallbackErrorPage.module.css';
import buttonStyles from '../../styles/Button.module.css';

type PropsInterface = {
  error: any,
  resetErrorBoundary: any
}

export default function FallbackErrorPage(props: PropsInterface) {
  const { error, resetErrorBoundary } = props;

  return (
    <Stack
      direction={'column'}
      spacing={2}
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      className={styles.container}
    >
      <Box className={styles.logo}>
        <img src="/images/logo_white.svg" alt="artstore logo" />
      </Box>
      <Typography variant="h5" className={styles.header}>
        Something went wrong!
      </Typography>
      <button onClick={resetErrorBoundary} className={buttonStyles.btnPrimary}>
        Back to home
      </button>
      <br />
    </Stack>
  );
}
