import axios from 'axios';
import { AxiosPromise, AxiosResponse } from 'axios';
import Config from '../../config/Config';
import Constants from '../../config/Constants';
import { GetBannerParamsInterface } from '../../interfaces/content.interfaces';
import jwt_decode from 'jwt-decode';
import { getAuthorizedApiHeaders } from './apiUtil';
import {
  FeaturedCollectionParamsInterface,
  CollectionContentParamsInterface,
} from '../../interfaces/collections.interface';

const artAPIService = axios.create();
const basePath = `${Config().baseUrl}/${Config().appPrefix}/${
  Config().apiVersion
}`;
const authAPIService = axios.create();




export const signIn = (password: string): AxiosPromise => {
  const data = JSON.stringify({ password });

  const config = {
    method: 'post',
    url: `${basePath}/auth/login`,
    headers: {
      'Content-Type': 'application/json',
      'clientkey': Config().clientKey,
    },
    data: data,
  };

  return artAPIService(config);
};


export const getCategories = (
  apiKey: string,
  country: string,
  languageCode: string,
  year: string,
  model: string
): AxiosPromise => {
  let params = `country=${country}&langcode=${languageCode}&year=${year}&model=${model}`;
  const config = {
    method: 'get',
    url: `${basePath}/content/categories?${params}`,
    headers: {
      'Content-Type': 'application/json',
      apiKey
    }
  };

  return artAPIService(config);
};

export const getContent = (contentId: string, apiKey: string): AxiosPromise => {
  const config = {
    method: 'get',
    url: `${basePath}/contents/${contentId}`,
    headers: {
      'Content-Type': 'application/json',
      apiKey,
    },
  };

  return artAPIService(config);
};

export const getArtists = (
  apiKey: string,
  country: string,
  languageCode: string,
  year: string,
  model: string
): AxiosPromise => {
  let params = `country=${country}&langcode=${languageCode}&year=${year}&model=${model}`;
  const config = {
    method: 'get',
    url: `${basePath}/content/artists?${params}`,
    headers: {
      'Content-Type': 'application/json',
      apiKey,
    },
  };

  return artAPIService(config);
};

export const getBanners = (
  apiKey: string,
  params: GetBannerParamsInterface
): AxiosPromise => {
  let queryParams = `country=${params.country}&langcode=${params.languageCode}&year=${params.year}&model=${params.model}&promotionType=${params.promotionType}`;
  const config = {
    method: 'get',
    url: `${basePath}/content/banners?${queryParams}`,
    headers: {
      'Content-Type': 'application/json',
      apiKey,
    },
  };

  return artAPIService(config);
};

export const getMeetTheArtist = (apiKey: string): AxiosPromise => {
  const config = {
    method: 'get',
    url: `${basePath}/content/meet-the-artist`,
    headers: {
      'Content-Type': 'application/json',
      apiKey,
    },
  };

  return artAPIService(config);
};

export const emailSubscriptionAPI = (
  apiKey: string,
  clientEmail: string
): AxiosPromise => {
  const data = JSON.stringify({ clientEmail });

  const config = {
    method: 'post',
    url: `${basePath}/braze/subscribe`,
    headers: { 'Content-Type': 'application/json', apiKey },
    data,
  };

  return artAPIService(config);
};


export const featuredCollection = (
  apiKey: string,
  params: FeaturedCollectionParamsInterface
): AxiosPromise => {
  let {
    country,
    languageCode,
    year,
    model,
  }: FeaturedCollectionParamsInterface = params;

  // Default values
  country = country || 'US';
  languageCode = languageCode || 'en-US';
  year = year || '2022';
  model = model || '22_PONTUSM_FTV';

  const parameters = `country=${country}&langcode=${languageCode}&year=${year}&model=${model}`;

  const config = {
    method: 'get',
    url: `${basePath}/content/featured-collections?${parameters}`,
    headers: { 'Content-Type': 'application/json', apiKey },
  };

  return artAPIService(config);
};

export const collectionContent = (
  apiKey: string,
  params: CollectionContentParamsInterface
): AxiosPromise => {
  let {
    country,
    languageCode,
    year,
    model,
    categoryId,
    subCatId,
  }: CollectionContentParamsInterface = params;

  // Default values
  country = country || 'US';
  languageCode = languageCode || 'en-US';
  year = year || '2022';
  model = model || '22_PONTUSM_FTV';

  const parameters = `country=${country}&langcode=${languageCode}&year=${year}&model=${model}&categoryId=${categoryId}&subCatId=${subCatId}`;

  const config = {
    method: 'get',
    url: `${basePath}/content/featured-collection?${parameters}`,
    headers: { 'Content-Type': 'application/json', apiKey },
  };

  return artAPIService(config);
};

