import axios, { AxiosPromise } from 'axios';
import Config from '../../config/Config';

const brazeSubscriptionService = axios.create();
const basePath = `${Config().baseUrl}/${Config().appPrefix}/${
  Config().apiVersion
}`;

export const emailSubscriptionAPI = (
  apiKey: string,
  clientEmail: string
): AxiosPromise => {
  const data = JSON.stringify({ clientEmail });

  const config = {
    method: 'post',
    url: `${basePath}/braze/subscribe`,
    headers: { 'Content-Type': 'application/json', apiKey },
    data,
  };

  return brazeSubscriptionService(config);
};
