import React from 'react';
import { Box } from '@mui/material';
import styles from './styles.module.css';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import SixteenByNineImageSection from './sixteenByNineImageSection';
import NineBySixteenImageSection from './nineBySixteenImageSection';
import { favoriteListSavedStatus } from '../../redux/reducers/contentStoreSlice';
import { isSubscribedUser, setCurrentParams, setPreviousPath } from '../../redux/reducers/userSlice';

type PropsInterface = {
  contents: any;
  favoriteHandler: any;
  findFavorite: any;
  pageType: string;
}

const ImageSection = (props: PropsInterface) => {
  const { contents, favoriteHandler, findFavorite, pageType } = props;
  const dispatch = useAppDispatch();
  const location = useLocation();
  const favoriteListStatus = useAppSelector(favoriteListSavedStatus);
  const isSubscribed = useAppSelector(isSubscribedUser);

  const onClickHandler = (contentId: string) => {
    const searchParams = `?id=${contentId}`;
    dispatch(setCurrentParams(searchParams));
    dispatch(setPreviousPath({path: location.pathname + location.search, type: props.pageType }));
  }

  const buttonPendingStatus = (contentId: string) => {
    let index = favoriteListStatus && favoriteListStatus.findIndex((item: any) => item.content_id === contentId && item.requestStatus === 'pending')
    if (index >= 0) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <Box
        className={`${styles.sixteenByNineArtworkContainer} ${styles.artworkContainer}`}
      >
        {contents.sixteenByNine.map(
          (props: any, index: number) => {
            if (props) {
              return (
                <SixteenByNineImageSection
                  key={index}
                  index={index}
                  id={props.id}
                  onClickHandler={onClickHandler}
                  marketingExcluded={props.marketingExcluded}
                  imageUrl={props.imageUrl}
                  title={props.title}
                  artWorkName={props.artWorkName}
                  artistName={props.artistName}
                  buttonPendingStatus={buttonPendingStatus}
                  findFavorite={findFavorite}
                  favoriteHandler={favoriteHandler}
                  artistId={props.artistId}
                  pageType={pageType}
                  isSubscribed={isSubscribed}
                />
              );
            }
          }
        )}
      </Box>

      <Box
        className={`${styles.nineBySixteenArtworkContainer} ${styles.artworkContainer}`}
      >
        {contents.nineBySixteen.map(
          (props: any, index: number) => {
            if (props) {
              return (
                <NineBySixteenImageSection
                  key={index}
                  index={index}
                  id={props.id}
                  onClickHandler={onClickHandler}
                  marketingExcluded={props.marketingExcluded}
                  imageUrl={props.imageUrl}
                  title={props.title}
                  artWorkName={props.artWorkName}
                  artistName={props.artistName}
                  buttonPendingStatus={buttonPendingStatus}
                  findFavorite={findFavorite}
                  favoriteHandler={favoriteHandler}
                  artistId={props.artistId}
                  pageType={pageType}
                  isSubscribed={isSubscribed}
                />
              );
            }
          }
        )}
      </Box>
    </>
  );
};

export default ImageSection;