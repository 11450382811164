import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './styles.module.css';
import { disableRightClick } from '../../utils/Common';
import { useTranslation } from 'react-i18next';

const NoCollectionErrorState = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h4" className={styles.header}>
        {t('miscNoCollection')}
      </Typography>
      <Box className={styles.imgContainer}>
        <img
          src={'/images/image_error_placeholder.svg'}
          alt={'image placeholder'}
          onContextMenu={disableRightClick}
        />
      </Box>
      <Typography
        variant="body1"
        sx={{ marginTop: '1rem', marginBottom: '5rem' }}
      >
        {t('miscCollectionNotAvail')}
      </Typography>
    </>
  );
};

export default NoCollectionErrorState;