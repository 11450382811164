import React from 'react';

const ErrorIcon = () => {
  return (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4104_971)">
        <path d="M36.002 69.0431C54.2501 69.0431 69.0431 54.2501 69.0431 36.002C69.0431 17.7539 54.2501 2.96094 36.002 2.96094C17.7539 2.96094 2.96094 17.7539 2.96094 36.002C2.96094 54.2501 17.7539 69.0431 36.002 69.0431Z" stroke="#A9A9A9" strokeWidth="5.91781" strokeMiterlimit="10"/>
        <path d="M36 14.7969V40.4407" stroke="#A9A9A9" strokeWidth="5.91781" strokeMiterlimit="10"/>
        <path d="M35.9989 54.5018C37.9054 54.5018 39.451 52.9562 39.451 51.0497C39.451 49.1432 37.9054 47.5977 35.9989 47.5977C34.0924 47.5977 32.5469 49.1432 32.5469 51.0497C32.5469 52.9562 34.0924 54.5018 35.9989 54.5018Z" fill="#A9A9A9"/>
      </g>
      <defs>
        <clipPath id="clip0_4104_971">
          <rect width="72" height="72" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default ErrorIcon;