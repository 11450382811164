import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Box, Stack } from '@mui/material';
import styles from './styles.module.css';

const LoadingState = () => {
  return (
    <>
      <Skeleton variant="text" sx={{ fontSize: '2.125rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      <Stack
        direction={{ sm: 'column', md: 'row' }}
        alignItems="stretch"
        justifyContent={'space-between'}
        flexWrap={'wrap'}
        className={styles.artworkContainerSkeleton}
      >
        {Array(6)
          .fill(0)
          .map((n, index) => (
            <Box key={index} className={styles.artworkItemSkeleton}>
              <Skeleton
                variant="rectangular"
                sx={{ minHeight: '15rem' }}
              />
              <Skeleton variant="text" />
            </Box>
          ))}
      </Stack>
    </>
  );
};

export default LoadingState;