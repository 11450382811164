import React, { useEffect, useRef, useState } from 'react';
import styles from './LandingPageFeaturedCollection.module.css';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  fetchFeaturedCollections,
  featuredCollectionData,
  featuredMastersCollectionData,
  featuredMuseumsCollectionData,
  featuredContemporaryArtistsCollData,
  featuredStatus,
  featuredMastersStatus,
  featuredMuseumsStatus,
  featuredContemporaryArtistsStatus
} from '../../redux/reducers/featuredCollectionSlice';
import { FeaturedCollectionParamsInterface } from '../../interfaces/collections.interface';
import Skeleton from '@mui/material/Skeleton';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import { setCurrentParams, setPreviousPath } from '../../redux/reducers/userSlice';
import { setFeaturedCollectionURL } from '../../redux/reducers/featuredCollectionSlice';
// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import './featured-collection-swiper.styles.css';
import './museums-collection-swiper.styles.css';
import './contemporary-artists-swiper.styles.css';
import { disableRightClick } from '../../utils/Common';
import { logClientError } from '../../redux/reducers/clientSlice';

type propsInterface = {
  featureType: 'featured_collection' | 'masters_collection' | 'museums_collection' | 'contemporary_artists';
  title: string;
};

export default function LandingPageFeaturedCollection(props: propsInterface) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryMetaId = queryParams.get("mta_id");
  const navigate = useNavigate();
  const buttonRef = useRef(null);

  const GetStatus = () => {
      if (props.featureType === 'featured_collection') {
          return useAppSelector(featuredStatus);
      } else if (props.featureType === 'masters_collection') {
          return useAppSelector(featuredMastersStatus)
      } else if (props.featureType === 'museums_collection') {
          return useAppSelector(featuredMuseumsStatus)
      } else if (props.featureType === 'contemporary_artists') {
          return useAppSelector(featuredContemporaryArtistsStatus)
      } 
  }
  const apiStatus = GetStatus(); // useAppSelector(status);

  const [sliderIndex, setSliderIndex] = useState(0);

  const GetCollections = () => {
    if (props.featureType === 'featured_collection') {
      return useAppSelector(featuredCollectionData);
   } else if (props.featureType === 'masters_collection') {
      return useAppSelector(featuredMastersCollectionData)
   } else if (props.featureType === 'museums_collection') {
      return useAppSelector(featuredMuseumsCollectionData)
   } else if (props.featureType === 'contemporary_artists') {
      return useAppSelector(featuredContemporaryArtistsCollData)
   } 
  }

  const featuredCollections = GetCollections();

  useEffect(() => {
    const params: FeaturedCollectionParamsInterface = {
      country: 'US',
      languageCode: 'US',
      year: '2022',
      model: '22_PONTUSM_FTV',
      collectionType: props.featureType,
    };

    try {
      if (!featuredCollections || !featuredCollections.length) {
        dispatch(fetchFeaturedCollections(params));
      }
    } catch (e) {
      dispatch(fetchFeaturedCollections(params));
      dispatch(logClientError(`FEATURED COLLECTION: ${e.message}`));
    }
  }, []);

  const onClickHandler = () => {
    const searchParams = `?categoryId=${featuredCollections[sliderIndex].categoryId}&subCatId=${featuredCollections[sliderIndex].subCatId}`;
    const url = window.location.origin + `/collections` + searchParams;
    dispatch(setCurrentParams(searchParams));
    dispatch(setPreviousPath({path: location.pathname, type: 'collection'}));
    dispatch(setFeaturedCollectionURL(url));
  };

  useEffect(() => {
    if (apiStatus === 'fulfilled') {
      if (queryMetaId) {
        if (location.hash == "#meet-the-artist") {
          navigate(`/${location.search}`);
        }
        // @ts-ignore
        buttonRef.current.click();
      }
    }
  }, [apiStatus])

  return (
    <div className={styles.containerWrapper}>
      <section className={styles.container}>
        <Typography variant="body1" className={(props.featureType === 'museums_collection') ? styles.headingWhite : styles.heading}>
          {props.title}
        </Typography>

        {apiStatus === 'loading' ? (
          queryMetaId ? (
          <div className={styles.featureListcontainer}>
            <Skeleton
              variant="text"
              sx={{ fontSize: '2.125rem', height: '2rem' }}
            />
            <Skeleton variant="rounded" sx={{ minHeight: '15rem' }} />
            <Skeleton variant="text" />
          </div>
          ) : (
            <>
            <Skeleton
              variant="text"
              sx={{ fontSize: '2.125rem', height: '2rem' }}
            />
            <Skeleton variant="rounded" sx={{ minHeight: '15rem' }} />
            <Skeleton variant="text" />
            </>
          )
        ) : featuredCollections && featuredCollections.length > 0 ? (
          <Swiper
            onSlideChange={(swiper) => {
              setSliderIndex((prev) => swiper.activeIndex);
            }}
            navigation={true}
            pagination={{ clickable: true }}
            modules={[Pagination, Navigation]}
            className={(props.featureType === 'contemporary_artists') ? "contemporaryArtistsSwiper" : (props.featureType === 'museums_collection') ? "museumsCollectionSwiper" :  "featuredCollectionSwiper"}
          >
            {featuredCollections.map((item: any, index: number) => {
              return (
                (item.name) ? (
                  <SwiperSlide key={index} className={styles.carouselContainer}>
                    <Typography variant="h4" className={(props.featureType === 'museums_collection') ? styles.titleWhite : styles.title}>
                      {item.name}
                    </Typography>
                    <Box className={styles.imgContainer}>
                      <Link
                        to={`/collections?categoryId=${featuredCollections[sliderIndex].categoryId}&subCatId=${featuredCollections[sliderIndex].subCatId}`}
                        onClick={onClickHandler}
                      >
                        <img
                          src={
                            item.thumbnail ||
                            '/images/image_error_placeholder.svg'
                          }
                          onContextMenu={disableRightClick}
                        />
                      </Link>
                    </Box>
                  </SwiperSlide>
                ) : (
                  <SwiperSlide key={index} className={styles.carouselContainer}>
                    <Typography variant="h4"  className={(props.featureType === 'museums_collection') ? styles.titleWhite : styles.title}>
                      {t('miscNoCollection')}
                    </Typography>
                    <Box className={styles.imgContainer}>
                      <Link
                        to={(item.name) ?`/collections?categoryId=${featuredCollections[sliderIndex].categoryId}&subCatId=${featuredCollections[sliderIndex].subCatId}`: ''}
                        onClick={onClickHandler}
                      >
                        <img
                          src={'/images/image_error_placeholder.svg'}
                          alt={'image placeholder'}
                          onContextMenu={disableRightClick}
                        />
                      </Link>
                    </Box>
                  </SwiperSlide>
                )
              );
            })}
          </Swiper>
        ) : (
          <>
            <Typography variant="h4" className={styles.header}>
              {t('miscNoCollection')}
            </Typography>
            <Box className={styles.imgContainer}>
              <img
                src={'/images/image_error_placeholder.svg'}
                alt={'image placeholder'}
                onContextMenu={disableRightClick}
              />
            </Box>
            <Typography
              variant="body1"
              sx={{ marginTop: '0rem', marginBottom: '1rem' }}
            >
              {t('miscCollectionNotAvail')}
            </Typography>
          </>
        )}
      </section>

      {featuredCollections && featuredCollections.length > 0 ? (
        <Link
          to={(featuredCollections[sliderIndex].name) ? `/collections?categoryId=${featuredCollections[sliderIndex].categoryId}&subCatId=${featuredCollections[sliderIndex].subCatId}` : ''}
          className={(featuredCollections[sliderIndex].name) ? (props.featureType === 'museums_collection') ? styles.btnBlue : styles.btn : styles.btnDisabled}
          onClick={(event) => (featuredCollections[sliderIndex].name) ? onClickHandler() : event.preventDefault()}
        >
          <span className={((featuredCollections[sliderIndex].name) && props.featureType === 'museums_collection') ? styles.btnTextWhite : styles.btnText}>{t('miscViewCollection')}</span>
        </Link>
      ) : (
        <></>
      )}
      <a ref={buttonRef} href={'#meet-the-artist'} style={{ display: 'none' }}></a>
    </div>
  );
}
