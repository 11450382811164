import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { signIn, getTokens, configDetails, verifySamsungAccountToken } from '../api/loginApi';
import Constants from '../../config/Constants';
import { revertAll, clearAllState } from '../actions';

export interface StateInterface {
  count: number;
  password: string;
  isLoggedIn: boolean;
  isSALoggedIn: boolean;
  recievedConfigSettings: boolean;
  apiToken: string;
  notificationKey: string;
  notificationEndpoint: string;
  status: 'idle' | 'loading' | 'failed';
  previousPath: PathInterface[];
  configStatus: 'idle' | 'loading' | 'failed';
  scrollPosition: 'top' | 'bottom' | 'custom';
  apiVersion: string;
  accessToken: string;
  tokenType: string;
  accessTokenExpiresIn: number;
  expiresIn: number;
  refreshToken: string;
  refreshTokenExpiresIn: number;
  userId: string;
  accessTokenArtStore: string;
  profileCountryCode: string;
  profileEmail: string;
  profileEmailVerified: string;
  profileFamilyName: string;
  profileGivenName: string;
  profileLocale: string;
  profilePreferredUsername: string;
  profileSub: string;
  profilePicture: string;
  currentParams: string;
  redirectUrl: string;
  selectedContent: ContentInterface;
  stateTimeStamp: string;
  loginStatus: 'idle' | 'loading' | 'failed';
  verifySATokenStatus: 'idle' | 'loading' | 'failed';
  verifySATokenData: { success: boolean };
  isSASessionExpired: boolean;
  isSubscribedUser: boolean;
}

export interface ContentInterface {
  id: string;
  name: string;
}

export interface PathInterface {
  path: string;
  type: string;
}

const initialState: StateInterface = {
  count: 3,
  password: '',
  isLoggedIn: false,
  isSALoggedIn: false,
  recievedConfigSettings: false,
  apiToken: '',
  notificationKey: '',
  notificationEndpoint: '',
  status: 'idle',
  previousPath: [],
  accessToken: '',
  tokenType: '',
  accessTokenExpiresIn: -1,
  expiresIn: -1,
  refreshToken: '',
  refreshTokenExpiresIn: -1,
  userId: '',
  accessTokenArtStore: '',
  profileCountryCode: '',
  profileEmail: '',
  profileEmailVerified: '',
  profileFamilyName: '',
  profileGivenName: '',
  profileLocale: '',
  profilePreferredUsername: '',
  profileSub: '',
  profilePicture: '',
  configStatus: 'idle',
  apiVersion: '',
  currentParams: '',
  redirectUrl: '',
  selectedContent: { id: '', name: '' },
  stateTimeStamp: '',
  loginStatus: 'idle',
  scrollPosition: 'top',
  verifySATokenStatus: 'idle',
  verifySATokenData: { success: false },
  isSASessionExpired: false,
  isSubscribedUser: false
};

export const loginUser = createAsyncThunk(
  'user/login',
  async (password: string) => {
    try {
      const response = await signIn(password);
      return response.data;
    } catch (error) {
      console.log('ERROR: Config Settings: ', error);
    }
  }
);


export const configSettings = createAsyncThunk(
  'user/config',
  async () => {
    try {
      const response = await configDetails();
      return response.data;

    } catch (error: any) {
      console.log('ERROR: Settings: ', error.message);
      if (error.message.includes('401')) {
        throw Error();
      }
    }
  }
);

export const getUserTokens = createAsyncThunk(
  'user/tokens',
  async (data: any) => {
    try {
      const response = await getTokens(data);
      return response.data;
    } catch (error) {
      console.log('ERROR: getUserTokens: ', error);
    }
  }
);

export const verifySAToken = createAsyncThunk(
  "user/verify-sa-token",
  async () => {
    try {
      const saAccessToken : string = localStorage.getItem(Constants.localStorageKey.accessToken) || '';
      const response = await verifySamsungAccountToken(saAccessToken);
      return response.data;
    } catch (error) {
      console.log("ERROR: Verifying SA Token: ", error);
      throw error;
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.count = 3;
      state.isLoggedIn = false;
      state.recievedConfigSettings = false;
      state.apiToken = '';
      state.notificationKey = '';
      state.notificationEndpoint = '';
    },
    logoutSA: (state) => {
      state.isSALoggedIn = false;
      state.accessToken = '';
      state.tokenType = '';
      state.accessTokenExpiresIn = -1;
      state.expiresIn = -1;
      state.refreshToken = '';
      state.refreshTokenExpiresIn = -1;
      state.userId = '';
      state.accessTokenArtStore = '';
      state.profileCountryCode = '';
      state.profileEmail = '';
      state.profileEmailVerified = '';
      state.profileFamilyName = '';
      state.profileGivenName = '';
      state.profileLocale = '';
      state.profilePreferredUsername = '';
      state.profilePicture = '';
      state.profileSub = '';
      state.redirectUrl = '';
      state.isSubscribedUser = false;
    },
    setSASessionExpired: (state) => {
      state.isSASessionExpired = true;
    },
    clearSASessionExpired: (state) => {
      state.isSASessionExpired = false;
    },
    setRedirectUrl: (state, action) => {
      if (action.payload) {
        state.redirectUrl = action.payload;
      }
    },
    clearRedirectUrl: (state, action) => {
      state.redirectUrl = '';
    },
    setPreviousPath: (state, action) => {
      if (action.payload) {
        const path = action.payload.path;
        const type = action.payload.type;
        if (path.length > 0) {
          if (path.indexOf('landing') >= 0) {
            state.previousPath = [];
          }
          let pathExists = state.previousPath.findIndex((item) => item.type === type);
          if (pathExists === -1) {
            let newPath: PathInterface = {
              path: path,
              type: type
            }
            state.previousPath.push(newPath);
          }
        }
      }
    },
    clearPreviousPath: (state, action) => {
      if (action.payload === 'all') {
        state.previousPath = [];
      } else {
        if (state.previousPath.length > 0) {
          state.previousPath.pop();
        } else {
          state.previousPath = [];
        }
      }
    },
    setUserLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setUserLoggedInData: (state, action) => {
      if (action.payload) {
        state.accessToken = action.payload.saData.access_token;
        state.tokenType = action.payload.saData.token_type;
        state.accessTokenExpiresIn = action.payload.saData.access_token_expires_in;
        state.expiresIn = action.payload.saData.expires_in;
        state.refreshToken = action.payload.saData.refresh_token;
        state.refreshTokenExpiresIn = action.payload.saData.refresh_token_expires_in;
        state.userId = action.payload.saData.userId;
        state.isSALoggedIn = action.payload.isLoggedIn;
        state.accessTokenArtStore = action.payload.accessToken;
        state.isSASessionExpired = false;
      }
    },
    // setUserLoggedIn: (state, action) => {
    //   state.isLoggedIn = action.payload;
    // },
    // setUserLoggedInData: (state, action) => {
    //   state.accessToken = action.payload.saData.access_token;
    //   state.tokenType = action.payload.saData.token_type;
    //   state.accessTokenExpiresIn = action.payload.saData.access_token_expires_in;
    //   state.expiresIn = action.payload.saData.expires_in;
    //   state.refreshToken = action.payload.saData.refresh_token;
    //   state.refreshTokenExpiresIn = action.payload.saData.refresh_token_expires_in;
    //   state.userId = action.payload.saData.userId;
    //   state.isSALoggedIn = action.payload.isLoggedIn;

    //   state.accessTokenArtStore = action.payload.accessToken;
    // },
    setCurrentParams: (state, action) => {
      if (action.payload)
        state.currentParams = action.payload;
    },
    clearCurrentParams: (state, action) => {
      state.currentParams = ''
    },
    setSelectedContent: (state, action) => {
      if (action.payload) {
        state.selectedContent = {
          id: action.payload.id,
          name: action.payload.name
        };
      }
    },
    clearSelectedContent: (state) => {
      state.selectedContent = { id: '', name: '' };
    },
    setStateTimeStamp: (state, action) => {
      if (action.payload)
        state.stateTimeStamp = action.payload;
    },
    clearStateTimeStamp: (state) => {
      state.stateTimeStamp = ''
    },
    setScrollPosition: (state, action) => {
      state.scrollPosition = action.payload;
    },
    setIsSubscribedUser: (state, action) => {
      state.isSubscribedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'idle';
        if (action.payload) {
          if (action.payload.isLoggedIn) {
            state.isLoggedIn = action.payload.isLoggedIn;
            state.apiToken = action.payload.apikey;
            state.notificationKey = action.payload.notificationKey;
            state.notificationEndpoint = action.payload.notificationUrl;
            
          } else {
            state.count -= 1;
            state.isLoggedIn = false;
            state.apiToken = '';
            state.notificationKey = '';
            state.notificationEndpoint = '';
          }
        }
      })
      .addCase(loginUser.rejected, (state) => {
        state.status = 'failed';
      })
      // .addCase(getUserTokens.pending, (state) => {
      //   state.status = 'loading';
      // })
      // .addCase(getUserTokens.fulfilled, (state, action) => {
      //   state.status = 'idle';
      //   if (action.payload) {
      //     if (action.payload.isLoggedIn) {
            
      //       let authData = JSON.stringify(action.payload);
      //       localStorage.setItem(Constants.localStorageKey.auth, authData);
      //       localStorage.setItem(Constants.localStorageKey.accessToken, action.payload.accessToken);

      //       state.accessToken = action.payload.saData.access_token;
      //       state.tokenType = action.payload.saData.token_type;
      //       state.accessTokenExpiresIn = action.payload.saData.access_token_expires_in;
      //       state.expiresIn = action.payload.saData.expires_in;
      //       state.refreshToken = action.payload.saData.refresh_token;
      //       state.refreshTokenExpiresIn = action.payload.saData.refresh_token_expires_in;
      //       state.userId = action.payload.saData.userId;
      //       state.accessTokenArtStore = action.payload.accessToken;
      //       state.isSALoggedIn = action.payload.isLoggedIn;

      //       state.profileGivenName = action.payload.profileData.given_name;
      //       state.profileFamilyName = action.payload.profileData.family_name;
      //       //console.log("SA Profile Data:", action.payload.profileData);
      //       console.log("SA Profile profileGivenName:", state.profileGivenName);
      //       console.log("SA Profile profileFamilyName:", state.profileFamilyName);

      //     } else {
      //       state.isSALoggedIn = false;
      //       state.accessToken = '';
      //       state.tokenType = '';
      //       state.accessTokenExpiresIn = 0;
      //       state.expiresIn = 0;
      //       state.refreshToken = '';
      //       state.refreshTokenExpiresIn = 0;
      //       state.userId = '';
      //       state.accessTokenArtStore = '';
      //     }
      //   }
      // })
      // .addCase(getUserTokens.rejected, (state) => {
      //   state.status = 'failed';
      // })
      .addCase(getUserTokens.pending, (state) => {
        state.status = 'loading';
        state.loginStatus = 'loading';
      })
      .addCase(getUserTokens.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loginStatus = 'idle';

        if (action.payload) {
          if (action.payload.isLoggedIn) {
            
            let authData = JSON.stringify(action.payload);
            localStorage.setItem(Constants.localStorageKey.accessToken, action.payload.accessToken);

            state.accessToken = action.payload.saData.access_token;
            state.tokenType = action.payload.saData.token_type;
            state.accessTokenExpiresIn = action.payload.saData.access_token_expires_in;
            state.expiresIn = action.payload.saData.expires_in;
            state.refreshToken = action.payload.saData.refresh_token;
            state.refreshTokenExpiresIn = action.payload.saData.refresh_token_expires_in;
            state.userId = action.payload.saData.userId;
            state.accessTokenArtStore = action.payload.accessToken;
            state.isSALoggedIn = action.payload.isLoggedIn;
            state.profilePicture = action.payload.profileData.picture;
            state.profileGivenName = action.payload.profileData.given_name;
            state.profileFamilyName = action.payload.profileData.family_name;
            state.profileEmail = action.payload.profileData.email;
            state.profilePreferredUsername = action.payload.profileData.preferred_username;
            state.isSASessionExpired = false;
            //console.log("SA Profile Data:", action.payload.profileData);

            console.log("SA Profile isSALoggedIn:", state.isSALoggedIn);
            console.log("SA Profile profileGivenName:", state.profileGivenName);
            console.log("SA Profile profileFamilyName:", state.profileFamilyName);

          } else {
            state.isSALoggedIn = false;
            state.accessToken = '';
            state.tokenType = '';
            state.accessTokenExpiresIn = 0;
            state.expiresIn = 0;
            state.refreshToken = '';
            state.refreshTokenExpiresIn = 0;
            state.userId = '';
            state.accessTokenArtStore = '';
          }
        }
      })
      .addCase(getUserTokens.rejected, (state) => {
        state.status = 'failed';
        state.loginStatus = 'failed';
      })
      .addCase(configSettings.pending, (state) => {
        state.status = 'loading';
        state.configStatus = 'loading';
      })
      .addCase(configSettings.fulfilled, (state, action) => {
        state.status = 'idle';
        state.configStatus = 'idle';
        if (action.payload) {
            state.recievedConfigSettings = true;
            state.isLoggedIn = true;
            state.apiToken = action.payload.apikey;
            state.notificationKey = action.payload.notificationKey;
            state.notificationEndpoint = action.payload.notificationUrl;
            if (action.payload.apiVersion) {
              if (!localStorage.getItem('apiVersion')) {
                state.apiVersion = action.payload.apiVersion
                localStorage.setItem('apiVersion', action.payload.apiVersion);
              } else {
                state.apiVersion = action.payload.apiVersion
              }
            }
        } else {
            state.recievedConfigSettings = false;
            state.isLoggedIn = false;
            state.apiToken = '';
            state.notificationKey = '';
            state.notificationEndpoint = '';
            state.apiVersion = '';
        }
      })
      .addCase(configSettings.rejected, (state) => {
        state.status = 'failed';
        state.configStatus = 'failed';
        state.apiToken = '';
      })
      .addCase(verifySAToken.pending, (state) => {
        state.verifySATokenStatus = 'loading';
      })
      .addCase(verifySAToken.fulfilled, (state, action) => {
        state.verifySATokenStatus = 'idle';
        if (action.payload) {
          state.verifySATokenData = action.payload;
        } else {
          state.verifySATokenData = { success: false };
        }
      })
      .addCase(verifySAToken.rejected, (state) => {
        state.verifySATokenStatus = 'failed';
        state.verifySATokenData = { success: false };
      })
      .addCase(revertAll, (state) => {
        localStorage.removeItem('apiVersion')
        // Note: Since this is user login state returning the state as is and not clearing it.
        return {
          ...state
          // ...initialState,
          // currentParams: state.currentParams
        };
      })
      .addCase(clearAllState, () => initialState);
  },
});

export const {
  logout,
  logoutSA,
  setPreviousPath,
  clearPreviousPath,
  setUserLoggedIn,
  setUserLoggedInData,
  setRedirectUrl,
  clearRedirectUrl,
  setCurrentParams,
  clearCurrentParams,
  setSelectedContent,
  clearSelectedContent,
  setStateTimeStamp,
  clearStateTimeStamp,
  setScrollPosition,
  setSASessionExpired,
  clearSASessionExpired,
  setIsSubscribedUser
} = userSlice.actions;
export const selectCount = (state: RootState) => state.user.count;
export const isUserLoggedIn = (state: RootState) => state.user.isLoggedIn;
export const isUserSALoggedIn = (state: RootState) => state.user.isSALoggedIn;
export const redirectUrl = (state: RootState) => state.user.redirectUrl;
export const profileGivenName = (state: RootState) => state.user.profileGivenName;
export const profileFamilyName = (state: RootState) => state.user.profileFamilyName;
export const profilePicture = (state: RootState) => state.user.profilePicture;
export const profileEmail = (state: RootState) => state.user.profileEmail;
export const recievedConfigSettings = (state: RootState) => state.user.recievedConfigSettings;
export const notificationKey = (state: RootState) => state.user.notificationKey;
export const notificationEndpoint = (state: RootState) =>
  state.user.notificationEndpoint;
export const apiToken = (state: RootState) => state.user.apiToken;
export const accessTokenArtStore  = (state: RootState) => state.user. accessTokenArtStore;
export const configStatus = (state: RootState) => state.user.configStatus;

export const previousPath = (state: RootState) => state.user.previousPath;

export const apiVersion = (state: RootState) => state.user.apiVersion;

export const currentParams = (state: RootState) => state.user.currentParams;

export const selectedContent = (state: RootState) => state.user.selectedContent;

export const stateTimeStamp = (state: RootState) => state.user.stateTimeStamp;

export const loginStatus = (state: RootState) => state.user.loginStatus;

export const scrollPosition = (state: RootState) => state.user.scrollPosition;

export const verifySATokenStatus = (state: RootState) => state.user.verifySATokenStatus;

export const verifySATokenData = (state: RootState) => state.user.verifySATokenData;

export const isSASessionExpired = (state: RootState) => state.user.isSASessionExpired;

export const isSubscribedUser = (state: RootState) => state.user.isSubscribedUser;

export default userSlice.reducer;
