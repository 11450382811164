import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch} from '../../redux/hooks';
import {
  getUserTokens,
  isUserSALoggedIn,
  isUserLoggedIn,
  redirectUrl,
  setUserLoggedIn,
  setUserLoggedInData,
  logoutSA,
  verifySAToken,
  isSASessionExpired,
  clearSASessionExpired,
  setIsSubscribedUser,
} from '../../redux/reducers/userSlice';
import { Outlet } from 'react-router-dom';
import { getParameterByName } from '../../redux/api/apiUtil';
import Constants from '../../config/Constants';
import * as AuthAPI from '../../redux/api/authAPI';
import * as LoginAPI from '../../redux/api/loginApi';
import Common from '../../utils/Common';
import Config from '../../config/Config';
import CustomDialog from '../dialogs/CustomDialog';
import { Snackbar } from '@mui/material';
import {
  fetchUserSubscriptionDetails,
  userSubscriptionDetailsData,
  userSubscriptionDetailsStatus,
} from '../../redux/reducers/contentStoreSlice';

const Auth = () => {
  const { setPageQueryParams } = Common();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(isUserLoggedIn);
  const isSALoggedIn = useAppSelector(isUserSALoggedIn);
  const redirectUrlPath = useAppSelector(redirectUrl);
  const isSAUserSessionExpired = useAppSelector(isSASessionExpired);
  const [openLoginDialog, setOpenLoginDialog] = React.useState(false);
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  let fullPathLink = location.pathname;
  const userSubscriptionDetails = useAppSelector(userSubscriptionDetailsData);
  const userSubscriptionDetailsAPIStatus = useAppSelector(userSubscriptionDetailsStatus);

  if (location.search) {
    fullPathLink = fullPathLink + location.search;
  }

  useEffect(() => {
    const params: any = {
      country: 'US',
      language: 'en_US',
      model: '22_PONTUSM_FTV',
      deviceId: 'MOBILEWEBAPIS'
    };
    isSALoggedIn && dispatch(fetchUserSubscriptionDetails(params));
  }, [isSALoggedIn]);

  useEffect(() => {
    if (isSALoggedIn && userSubscriptionDetailsAPIStatus !== 'loading' && userSubscriptionDetails.isSubscribed) {
      const isSubscribed = userSubscriptionDetails.isSubscribed.toLowerCase() === 'yes';
      dispatch(setIsSubscribedUser(isSubscribed));
    }
  }, [userSubscriptionDetails, userSubscriptionDetailsAPIStatus])

  useEffect(() => {
    function refreshAuth() {
      if (location.pathname === '/') {
        navigate('/landingpage' + location.search ,{ replace: true });
      } else {
        navigate(fullPathLink, { replace: true });
      }
      
    }
    refreshAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function refreshSAAuth() {
      if (isSALoggedIn) { // User just logged in
        if (fullPathLink.indexOf('landing') >= 0) {
          navigate({ search: ''},{ replace: true });
        } else {
          setPageQueryParams();
        }
      } else {
        const paramData = getParameterByName('code');
        if (paramData) { // Authenticate Flow
          localStorage.setItem(Constants.localStorageKey.code, paramData);
          const redirectUrlPathName = location.pathname.slice(1, location.pathname.length);
          let data = {
            code: paramData,
            redirectUrl: redirectUrlPathName
          }
          dispatch(getUserTokens(data));
        } else { // Logout flow
          setPageQueryParams();
        }
      }
    }

    if (Config().samsungAccountFeatureFlag) {
      refreshSAAuth();
    } else {
      dispatch(logoutSA());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSALoggedIn]);

  useEffect(() => {
    if (isSAUserSessionExpired) {
      dispatch(clearSASessionExpired());
      setOpenLoginDialog(true);
      setShowSnackbar(true);
      setSnackBarMessage(`Your session has expired please sign in again.`);
    }
  }, [isSAUserSessionExpired])

  const closeSnackbar = () => {
    setShowSnackbar(false);
    setSnackBarMessage('');
  }

  return (
    <>
      <Outlet />
      {openLoginDialog && <CustomDialog displayDialog={openLoginDialog} data={{}} resetDisplayDialogStatus={() => setOpenLoginDialog(false)} dialogType='login' />}
      {showSnackbar && snackBarMessage && (
        <Snackbar
          sx={{
            '.MuiPaper-root': {
              background: '#1D1D2F',
              textAlign: 'center',
            }
          }}
          open={showSnackbar}
          onClose={closeSnackbar}
          autoHideDuration={4000}
          message={snackBarMessage}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        />
      )}
    </>
  );
};

export default Auth;
