import { Snackbar } from '@mui/material';
import React, { useState } from 'react'
import LinkIcon from '@mui/icons-material/Link';
import styles from './CopyToClipboardButton.module.css';
import { useTranslation } from 'react-i18next';

type CopyToClipboardButtonProps = {
  content?: string;
}

const CopyToClipboardButton = (props: CopyToClipboardButtonProps) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation();
  const handleClick = () => {
    setOpen(true)
    navigator.clipboard.writeText(props.content || '')
  }

  return (
    <>
      <span onClick={handleClick} className={styles.btn}><LinkIcon/></span>
      <Snackbar
        sx={{
          '.MuiPaper-root': {
            background: '#1D1D2F'
          }
        }}
        open={open}
        onClose={() => setOpen(false)}
        message={t('miscCopiedtoClipboard')}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </>
  )
}

export default CopyToClipboardButton