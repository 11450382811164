import axios from 'axios';
import { AxiosPromise, AxiosResponse } from 'axios';
import Config from '../../config/Config';
import Constants from '../../config/Constants';
import { GetBannerParamsInterface } from '../../interfaces/content.interfaces';
import jwt_decode from 'jwt-decode';
import { getAuthorizedApiHeaders, samsungAccountAPIService } from './apiUtil';
import {
  FeaturedCollectionParamsInterface,
  CollectionContentParamsInterface,
} from '../../interfaces/collections.interface';

import { store } from '../store';
import { logoutSA } from '../../redux/reducers/userSlice';
import { getRefreshToken } from './loginApi';

const basePath = `${Config().baseUrl}/${Config().appPrefix}/${
  Config().apiVersion
}`;
const authAPIService = axios.create();

interface AuthToken {
  name: string;
  exp: number;
  // Add any other
}

// artAPIService.interceptors.request.use(
//   async (config: any) => {
//     let accessToken = localStorage.getItem(Constants.localStorageKey.accessToken);

//     if (typeof accessToken === 'string') {
//         // Check if access token has expired and refresh
//         let decodedToken = jwt_decode<AuthToken>(accessToken);

//         let currentDate = new Date();
//         if (decodedToken.exp * 1000 < currentDate.getTime()) {
//           const access_token = await refreshAuth();

//           if (access_token) accessToken = access_token;
//         }

//         config.headers = {
//           ...config.headers,
//           Authorization: `Bearer ${accessToken}`,
//           'Accept-Language': 'en-US,en;q=0.5',
//         };
//         return config;
//     }
//   },
//   (error) => Promise.reject(error)
// );

export const signIn = (password: string): AxiosPromise => {
  const data = JSON.stringify({ password });

  const config = {
    method: 'post',
    url: `${basePath}/auth/login`,
    headers: {
      'Content-Type': 'application/json',
      'clientkey': Config().clientKey,
    },
    data: data,
  };

  return samsungAccountAPIService(config);
};


export const getCategories = (
  apiKey: string,
  country: string,
  languageCode: string,
  year: string,
  model: string
): AxiosPromise => {
  let params = `country=${country}&langcode=${languageCode}&year=${year}&model=${model}`;
  const config = {
    method: 'get',
    url: `${basePath}/content/categories?${params}`,
    headers: {
      'Content-Type': 'application/json',
      apiKey
    }
  };

  return samsungAccountAPIService(config);
};

export const getContent = (contentId: string, apiKey: string): AxiosPromise => {
  const config = {
    method: 'get',
    url: `${basePath}/contents/${contentId}`,
    headers: {
      'Content-Type': 'application/json',
      apiKey,
    },
  };

  return samsungAccountAPIService(config);
};

export const getArtists = (
  apiKey: string,
  country: string,
  languageCode: string,
  year: string,
  model: string
): AxiosPromise => {
  let params = `country=${country}&langcode=${languageCode}&year=${year}&model=${model}`;
  const config = {
    method: 'get',
    url: `${basePath}/content/artists?${params}`,
    headers: {
      'Content-Type': 'application/json',
      apiKey,
    },
  };

  return samsungAccountAPIService(config);
};

export const getBanners = (
  apiKey: string,
  params: GetBannerParamsInterface
): AxiosPromise => {
  let queryParams = `country=${params.country}&langcode=${params.languageCode}&year=${params.year}&model=${params.model}&promotionType=${params.promotionType}`;
  const config = {
    method: 'get',
    url: `${basePath}/content/banners?${queryParams}`,
    headers: {
      'Content-Type': 'application/json',
      apiKey,
    },
  };

  return samsungAccountAPIService(config);
};

export const getMeetTheArtist = (apiKey: string): AxiosPromise => {
  const config = {
    method: 'get',
    url: `${basePath}/content/meet-the-artist`,
    headers: {
      'Content-Type': 'application/json',
      apiKey,
    },
  };

  return samsungAccountAPIService(config);
};

// export const getMeetTheArtist = (apiKey: string): AxiosPromise => {
//   const config = {
//     method: 'get',
//     url: `${basePath}/content/meet-the-artist`,
//     headers: {
//       'Content-Type': 'application/json',
//       apiKey,
//     },
//   };

//   return artAPIService(config);
// };

export const emailSubscriptionAPI = (
  apiKey: string,
  clientEmail: string
): AxiosPromise => {
  const data = JSON.stringify({ clientEmail });

  const config = {
    method: 'post',
    url: `${basePath}/braze/subscribe`,
    headers: { 'Content-Type': 'application/json', apiKey },
    data,
  };

  return samsungAccountAPIService(config);
};


export const featuredCollection = (
  apiKey: string,
  params: FeaturedCollectionParamsInterface
): AxiosPromise => {
  let {
    country,
    languageCode,
    year,
    model,
  }: FeaturedCollectionParamsInterface = params;

  // Default values
  country = country || 'US';
  languageCode = languageCode || 'en-US';
  year = year || '2022';
  model = model || '22_PONTUSM_FTV';

  const parameters = `country=${country}&langcode=${languageCode}&year=${year}&model=${model}`;

  const config = {
    method: 'get',
    url: `${basePath}/content/featured-collections?${parameters}`,
    headers: { 'Content-Type': 'application/json', apiKey },
  };

  return samsungAccountAPIService(config);
};

export const collectionContent = (
  apiKey: string,
  params: CollectionContentParamsInterface
): AxiosPromise => {
  let {
    country,
    languageCode,
    year,
    model,
    categoryId,
    subCatId,
  }: CollectionContentParamsInterface = params;

  // Default values
  country = country || 'US';
  languageCode = languageCode || 'en-US';
  year = year || '2022';
  model = model || '22_PONTUSM_FTV';

  const parameters = `country=${country}&langcode=${languageCode}&year=${year}&model=${model}&categoryId=${categoryId}&subCatId=${subCatId}`;

  const config = {
    method: 'get',
    url: `${basePath}/content/featured-collection?${parameters}`,
    headers: { 'Content-Type': 'application/json', apiKey },
  };

  return samsungAccountAPIService(config);
};


export const getContentStoreAWSPresignedUrl = (apiKey: string): AxiosPromise => {
  const config = {
    method: 'get',
    url: `${basePath}/contentstore/presignedUrl`,
    headers: {
      'Content-Type': 'application/json',
      apiKey,
    },
  };

  return samsungAccountAPIService(config);
};


export const getContentStoreFavorites = (apiKey: string, authToken: string, country: string): AxiosPromise => {
  const jwtToken = Buffer.from(authToken, 'base64').toString();
  
  const parameters = `country=${country}`;
  const config = {
    method: 'get',
    url: `${basePath}/contentstore/collections?${parameters}`,
    headers: {
      'Content-Type': 'application/json',
      apiKey,
      Authorization: `Bearer ${jwtToken}`
    },
  };

  return samsungAccountAPIService(config);
};

export const saveContentStoreFavorites = (apiKey: string,authToken: string, country: string, body: any): AxiosPromise => {
  
  const parameters = `country=${country}`;
  const data = JSON.stringify(body);

  const jwtToken = Buffer.from(authToken, 'base64').toString();

  const config = {
    method: 'post',
    url: `${basePath}/contentstore/collection?${parameters}`,
    headers: {
      'Content-Type': 'application/json',
      apiKey,
      Authorization: `Bearer ${jwtToken}`
    },
    data: data,
  };

  return samsungAccountAPIService(config);
};

export const getHomescreenConfig = (apiKey: string, authToken: string, country: string): AxiosPromise => {
  const jwtToken = Buffer.from(authToken, 'base64').toString();
  
  const parameters = `supportedCountry=Y&memberType=member&resolutionType=UHD&model=22_PONTUSM_FTV&year=2022&langcode=en_US&country=US`;
  const config = {
    method: 'get',
    url: `${basePath}/contentstore/homescreen-config?${parameters}`,
    headers: {
      'Content-Type': 'application/json',
      apiKey,
      Authorization: `Bearer ${jwtToken}`
    },
  };

  return samsungAccountAPIService(config);
};



export const getHomescreenConfigPersonal = (apiKey: string, authToken: string, country: string): AxiosPromise => {
  const jwtToken = Buffer.from(authToken, 'base64').toString();
  
  const parameters = `supportedCountry=Y&memberType=member&resolutionType=UHD&model=22_PONTUSM_FTV&year=2022&langcode=en_US&country=US`;
  const config = {
    method: 'get',
    url: `${basePath}/contentstore/homescreen-config-personal?${parameters}`,
    headers: {
      'Content-Type': 'application/json',
      apiKey,
      Authorization: `Bearer ${jwtToken}`
    },
  };

  return samsungAccountAPIService(config);
};



export const getUserSubscriptionDetails = (apiKey: string, authToken: string, country: string, language: string, model: string, deviceId: string): AxiosPromise => {
  
  const jwtToken = Buffer.from(authToken, 'base64').toString();
  
  const parameters = `country=${country}&langcode=${language}&model=${model}&deviceuniqueid=${deviceId}`;
  const config = {
    method: 'get',
    url: `${basePath}/contentstore/user-subscription?${parameters}`,
    headers: {
      'Content-Type': 'application/json',
      apiKey,
      Authorization: `Bearer ${jwtToken}`
    },
  };

  return samsungAccountAPIService(config);
};

