import React, { useEffect } from 'react';
import moment from "moment";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './login/Login';
import {
  initialize,
  automaticallyShowInAppMessages,
  openSession,
  requestPushPermission,
  isPushSupported,
} from '@braze/web-sdk';
import {
  isSafari
} from 'react-device-detect';
import {
  checkBrowserIsChrome,
  checkBrowserIsOSMobile,
} from '../utils/Common';
import LandingPage from './landingPage/LandingPage';
import Subscribe from './subscribe/Subscribe';
import SubscriptionPlan from './subscribe/SubscriptionPlan';
import SubscriptionSignIn from './subscribe/SubscriptionSignIn';
import ThankYou from './subscribe/ThankYou';
import CollectionPage from './collectionPage/CollectionPage';
import PageNotFound from '../components/pageNotFound/PageNotFound';
import Auth from '../components/auth/Auth';
import CacheBuster from 'react-cache-buster';
import { version } from '../../package.json';
import Loading from '../components/Loading';
import TermsAndConditionsPage from './termsAndConditions/TermsAndConditionsPage';
import { configSettings, loginStatus } from '../redux/reducers/userSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import CollectionDetailPage from './collectionDetailPage/CollectionDetailPage';
import {
  isUserLoggedIn,
  notificationKey,
  notificationEndpoint,
} from '../redux/reducers/userSlice';
import UploadImagePage from './uploadImagePage/UploadImagePage';
import { revertAll } from '../redux/actions';
import { isUserSALoggedIn, setStateTimeStamp, stateTimeStamp } from "../redux/reducers/userSlice";
import Config from '../config/Config';
import { logClientError } from '../redux/reducers/clientSlice';
import { CollectionPageStatusEnum } from '../components/collectionPage/CollectionPageType.enum';

let initialized = false;
const AppRoutes = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(isUserLoggedIn);
  const isSALoggedIn = useAppSelector(isUserSALoggedIn);
  const notifKey = useAppSelector(notificationKey);
  const notifEndpoint = useAppSelector(notificationEndpoint);
  const userStateTimeStamp = useAppSelector(stateTimeStamp);
  const userLoginStatus = useAppSelector(loginStatus);
  const isProduction = process.env.REACT_APP_ENV === 'prod';

  useEffect(() => {
    window.addEventListener("click", updateStateTimeStamp)

    const getConfig = async  () => {
      if (!initialized) {

        initialized = true;

        try {
          shouldResetState() && await dispatch(revertAll());
        } catch (e) {
          await dispatch(revertAll());
          dispatch(logClientError(`ROUTES | REST_STATE: ${e.message}`));
        }
        await dispatch(configSettings());
      }
    }
    getConfig();

    return () => {
      window.addEventListener("click", updateStateTimeStamp)
    }
  },[]);

  useEffect(() => {
    if (isLoggedIn && notifKey && notifEndpoint) {
      // fullscreen mode
      initialize(notifKey, {
        enableLogging: !isProduction,
        baseUrl: notifEndpoint,
        serviceWorkerLocation: '/braze-service-worker.js',
        // Note: Below Id is left intentially for Braze testing.
        //safariWebsitePushId: 'web.com.braze.sample-build',
      });
      automaticallyShowInAppMessages();
      openSession();
      if (isSafari) {
        if (!checkBrowserIsChrome(window.navigator)) {
          if (checkBrowserIsOSMobile(window.navigator)) {
            requestPermission();
          }
        }
      }
    }

    function requestPermission() {
      requestPushPermission(
        () => {
          const pushSupported = isPushSupported();
        },
        (temporary) => {
          const pushSupported = isPushSupported();
          console.log(
            `User ${
              temporary ? 'temporarily dismissed' : 'permanently denied'
            } push prompt ${pushSupported}`
          );
        }
      );
    }
  }, [isLoggedIn]);

  const updateStateTimeStamp = () => {
    dispatch(setStateTimeStamp(moment()));
  }

  const shouldResetState = () => {
    console.log('> Timestamp: ', userStateTimeStamp);
    if (!userStateTimeStamp) {
      dispatch(setStateTimeStamp(moment()));
      return true;
    } else {
      const expiryTime = parseInt(Config().stateExpiryMinutes.toString());
      const timeDifference = moment().diff(userStateTimeStamp, 'minutes');

      console.log('> ', timeDifference, 'minutes has passed out of', expiryTime);
      console.log('> Expire in', (expiryTime - timeDifference),'minutes');

      if (timeDifference >= expiryTime) {
        dispatch(setStateTimeStamp(moment()));
        return true
      } else {
        return false;
      }
    }
  }

  return  (
    <CacheBuster
      currentVersion={version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <>
        {userLoginStatus === 'loading' && <Loading />}
        <Router>
          <Routes>
            {/* Login Routes */}

            {/* <Route path="/login" element={<Login />} /> */}

            <Route element={<Auth />}>
              <Route path="/" element={<LandingPage />} />
              <Route key={1} path="/landingpage" element={<LandingPage />} />
              <Route path="/collections" element={<CollectionPage pageType={CollectionPageStatusEnum.COLLECTIONS} />} />
              <Route path="/collections/artist" element={<CollectionPage pageType={CollectionPageStatusEnum.ARTIST} />} />
              <Route path="/collections/search" element={<CollectionPage pageType={CollectionPageStatusEnum.SEARCH} />} />
              <Route path="/collection/details" element={<CollectionDetailPage />} />
              <Route path="/upload/image" element={isSALoggedIn ? <UploadImagePage /> : <LandingPage />} />
              {/* <Route path="/upload/image1" element={<UploadImagePage />} /> */}
              {/*<Route path="/subscribe" element={<Subscribe />}>*/}
              {/*  <Route index element={<SubscriptionPlan />} />*/}
              {/*  <Route path="login" element={<SubscriptionSignIn />} />*/}
              {/*  <Route path="thank-you" element={<ThankYou />} />*/}
              {/*</Route>*/}

              {/* Page not found Route */}
              <Route path="*" element={<PageNotFound />} />

              <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />

            </Route>
          </Routes>
        </Router>
      </>
    </CacheBuster>
  );
};

export default AppRoutes;
