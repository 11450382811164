import axios from 'axios';
import { AxiosPromise } from 'axios';
import Config from '../../config/Config';
import { PresignedUrlDataInterface } from '../../interfaces/content.interfaces'
const artAPIService = axios.create();
const awsS3APIService = axios.create();
//const basePath = Config().configModule.cloudFront;
const basePath = `${Config().baseUrl}/${Config().appPrefix}/${
  Config().apiVersion
}`;

export const getContentStoreAWSPresignedUrl = (apiKey: string, authToken: string, extension: string): AxiosPromise<PresignedUrlDataInterface> => {
  const jwtToken = Buffer.from(authToken, 'base64').toString();

  const config = {
    method: 'get',
    url: `${basePath}/contentstore/presignedUrl?extension=${extension}`,
    headers: {
      'Content-Type': 'application/json',
      apiKey,
      Authorization: `Bearer ${jwtToken}`
    },
  };

  return artAPIService(config);
};


export const uploadImageToPresignedS3 = (
  presignedUrl: string, keyValue: string, AWSAccessKeyId: string,
  AMZSecurityToken: string, policy: string, signature: string, 
  fileObject: any, contentType: string): AxiosPromise => {
  
  var bodyFormData = new FormData();
  bodyFormData.append('key', keyValue);
  bodyFormData.append('AWSAccessKeyId', AWSAccessKeyId);
  bodyFormData.append('x-amz-security-token', AMZSecurityToken);
  bodyFormData.append('policy', policy);
  bodyFormData.append('signature', signature);
  bodyFormData.append('file', fileObject.file);

  const config = {
    method: 'post', 
    url: presignedUrl,
    data: bodyFormData,
    headers: {
      "Content-Type" : contentType
    }
  }

  return awsS3APIService(config);
};