import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import styles from './HowToSubscribe.module.css';
import { HowToSubscribeInterface } from './interface';
import { Divider } from '@mui/material';

export const HowToSubscribe = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <Typography variant="h5" className={styles.header}>
        {t('miscHowToSubStartMembership')}
      </Typography>
      <Grid container className={styles.gridContainer}>
        {howToSubscribe.map((item, index) => {
          return (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              key={index}
              className={styles.subscriptionBody}
            >
              <Divider className={styles.divider} />
              <Stack
                className={styles.subscriptionItem}
                direction={{ xs: 'row', md: 'row' }}
                spacing={2}
                alignItems="center"
              >
                <Box>
                  <div className={styles.subscriptionIcon}>
                    <img alt={'How to subscribe icon'} src={item.icon} />
                  </div>
                </Box>
                <div className={styles.subscriptionContent}>
                  <Typography variant="body1" className={styles.heading}>
                    {item.heading}
                  </Typography>
                  <Typography variant="body2" className={styles.description}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

const howToSubscribe: HowToSubscribeInterface[] = [
  {
    icon: '/images/subscription_open_icon.svg',
    heading: 'Turn on your Frame TV',
    description: 'Power on your Frame TV to the home screen.',
  },
  {
    icon: '/images/subscription_access_icon.svg',
    heading: 'Open Samsung Art Store',
    description:
      'Navigate to the left to find the Art Store logo and select it.',
  },
  {
    icon: '/images/subscription_membership_icon.svg',
    heading: 'Select "Membership"',
    description: 'Find "Membership" and select it.',
  },
  {
    icon: '/images/subscription_plan_icon.svg',
    heading: 'Pick your plan and complete your purchase',
    description:
      "Opt for a monthly or annual membership, add your payment info and checkout. You're all set. Welcome to the Samsung Art Store.",
  },
];
