import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setRedirectUrl } from '../../redux/reducers/userSlice';
import { useAppDispatch } from '../../redux/hooks';
import styles from './TermsAndConditionsPage.module.css';
import Footer from '../../components/footer/Footer';
import Logo from '../../components/logo/Logo';

export default function TermsAndConditionsPage() {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    dispatch(setRedirectUrl('terms-and-conditions'));
  }, []);

  return (
    <>
      <Logo backArrow={true} borderBottom={false} removeHomeNavigateLink={false} />
      <main className={styles.container}>
        <h1>{t('miscTermsTitle')}</h1>

        <article className="item-container">
          <h2>{t('miscTermsPurpose')}</h2>
          <p>
            {t('miscTermsPurposeDesc')}
          </p>
        </article>

        <article className="item-container">
          <h2>{t('miscTermsDefinitions')}</h2>
          <ul className={styles.alphabetBullets}>
            <li>
              "We", "us", or "Samsung" means Samsung Electronics Co., Ltd, whose
              principal place of business is at 416, Maetan-3 Dong,
              Yeongtong-Gu, Suwon-Si, Gyunggi-do, Korea or third parties
              designated by us; and
            </li>
            <li>
              "Services" means Samsung's content and related services provided
              by us or any third party designated by us through or in connection
              with this device, excluding any services provided under a separate
              written agreement; and
            </li>
            <li>"User" or "you" means a user of Samsung's Services; and</li>
            <li>
              "Content" means copyrighted images and related information that
              can be purchased, downloaded or subscribed to from the Art Store;
              and
            </li>
            <li>
              "Art Store" means the online store operated by Samsung available
              as an application on User Devices; and
            </li>
            <li>
              "User Devices" means devices such as smart TVs, smart phones, and
              tablet PCs capable of connecting to the internet, searching,
              purchasing, using and storing internet content; and
            </li>
            <li>
              "Samsung Account" means the Samsung Account which Users can open
              by registering in order to use various services provided by
              Samsung.; and
            </li>
          </ul>
        </article>

        <article className="item-container">
          <h2>{t('miscTermsScope')}</h2>
          <p>
            Your use of the Services is subject to Samsung's Smart Hub Terms and
            Conditions, Samsung Account Terms and Conditions, Samsung's Privacy
            Policy or any other terms and policies made applicable to the User
            which you already have or will have to agree to (all such terms and
            conditions collectively constituting this "Agreement"). In case of a
            conflict between the terms of this Agreement and any of the above
            mentioned terms and conditions or policy, this Agreement will govern
            with respect to your use of the Services. You can review the Smart
            Hub Terms and Conditions, Privacy Policy and the Samsung Account
            Terms and Conditions under the Settings menu of your TV.
          </p>
        </article>

        <article className="item-container">
          <h2>{t('miscTermsAcceptance')}</h2>
          <ul className={styles.listTypeNone}>
            <li>
              <span className={styles.pdRight1rem}>4.1</span>
              You must accept the terms of this Agreement in order to use the
              Services, and may not use the Services otherwise. By using our
              Services, you are agreeing to the terms of this Agreement and this
              Agreement becomes legally binding between you and Samsung in
              relation to the Services and your use of the Services.
            </li>

            <li>
              <span className={styles.pdRight1rem}>4.2</span>
              The Content are provided only for your personal, noncommercial
              use. Subject to the terms and conditions of this Agreement,
              Samsung hereby grants you, and you accept, a limited, personal,
              nonexclusive, nontransferable and revocable right to use the
              Content only as authorized in this Agreement and in any applicable
              separate terms from Samsung.
            </li>
          </ul>
        </article>

        <article className="item-container">
          <h2>{t('miscTermsPurchase')}</h2>
          <ul className={styles.listTypeNone}>
            <li>
              <span className={styles.pdRight1rem}>5.1</span> You may purchase
              the Content on an a la carte basis or purchase a membership which
              allows you to choose pictures from a pool of Content. Content made
              available for the membership option may be changed at Samsung's
              sole discretion at any time.
            </li>
            <li>
              <span className={styles.pdRight1rem}>5.2</span> You can make a
              purchase with Samsung Checkout on TV. Except as stated in Section
              6 below, all sales are final.
            </li>
            <li>
              <span className={styles.pdRight1rem}>5.3</span> To utilize Samsung
              Checkout on TV, you will need to have a Samsung Account. If you
              don't have one already, you can sign up at account.samsung.com or
              on the applicable User Devices. You are responsible for providing
              accurate payment information and the full payment of your Content
              purchases. By using Samsung Checkout on TV, you authorize Samsung
              to charge your credit card or another selected payment account for
              your purchases.
            </li>
            <li>
              <span className={styles.pdRight1rem}>5.4</span> Please note that
              the prices and currency charged for the Content may vary depending
              on your location. Prices for the Content may change at any time,
              and Samsung Checkout on TV does not provide price protection or
              refunds in the event of a price reduction or promotional offering.
            </li>
          </ul>
        </article>

        <article className="item-container">
          <h2>{t('miscTermsCancellation')}</h2>

          <ul className={styles.listTypeNone}>
            <li>
              <span className={styles.pdRight1rem}>6.1</span>
              You can cancel your Art Store membership at any time, and you will
              continue to have access to the service through the end of your
              billing period. To the extent permitted by the applicable law,
              payments are non-refundable and we do not provide refunds or
              credits for any partial-membership periods. To cancel, go to the
              membership page in the Art Store or "Your Account" page and follow
              the instructions for cancellation. If you cancel your membership,
              your Art Store membership will automatically close at the end of
              your current billing period, and you can receive the cancellation
              notice in the e-mail registered in your Samsung Account. We do not
              accept returns of any transactions for individually purchased
              Content, provided however, you may cancel an order for purchased
              Content within 48 hours of purchase (or, for customers in the
              European Union, within 14 days from the date of purchase) by
              e-mail request to frame.cs@samsung.com. You may not cancel an
              order for purchased Content once you have started using full
              screen setting such Content.
            </li>
            <li>
              <span className={styles.pdRight1rem}>6.2</span>
              If you are unable to download the Content because of a technical
              problem, or if the Content is materially defective, please contact
              us at frame.cs@samsung.com. In such circumstances we may, in our
              sole discretion, replace, make available for re-download, or
              refund the price of the Content.
            </li>
          </ul>
        </article>

        <article className="item-container">
          <h2>{t('miscTermsContent')}</h2>

          <ul className={styles.listTypeNone}>
            <li>
              <span className={styles.pdRight1rem}>7.1</span>
              Samsung is not responsible for any content you may access as part
              of or via your use of the Services (including audio and sound
              files, data files, images, graphics, animation, music,
              photographs, software, videos and written text) that were created
              by or are owned by a third party ("Third Party Content"),
              including advertisements, messages, or other sponsored content.
              Any responsibility or liability for any Third Party Content shall
              remain solely with the creator, owner, or licensee of such Third
              Party Content and not with Samsung whatsoever.
            </li>
            <li>
              <span className={styles.pdRight1rem}>7.2</span>
              You acknowledge and agree that Third Party Content is protected by
              the proprietary and intellectual property rights of the creator,
              owner and licensee of such Third Party Content. You must not
              modify, reverse-engineer, copy, rent, lease, loan, sell, resell,
              re-transmit, distribute, trade, publicly display or create
              derivative works of any Third Party Content, either in whole or in
              part, without obtaining a license from such creator, owner or
              licensee.
            </li>
            <li>
              <span className={styles.pdRight1rem}>7.3</span>
              Samsung, at its sole discretion, may, but is not obligated to,
              pre-screen, review, flag, filter, modify, refuse, suspend, or
              remove any Third Party Content that are provided to you as part of
              or via the Services, without giving prior notice to you.
            </li>
            <li>
              <span className={styles.pdRight1rem}>7.4</span>
              You acknowledge and agree that, by using the Services, you may be
              exposed to Third Party Content that you may find offensive,
              indecent or objectionable and that, in that respect, you agree to
              use the Services at your own risk. You agree to assume such risk
              on behalf of any Minor for whom you are the Minor's parent or
              legal guardian using the Services, and you agree to shield and not
              expose such Services from or to any Minor using the Services who
              is not your child or for whom you are not such Minor's legal
              guardian.
            </li>
          </ul>
        </article>

        <article className="item-container">
          <h2>{t('miscTermsChanges')}</h2>
          <p>
            Samsung may make changes to the terms of this Agreement from time to
            time. When material changes are made, Samsung will provide Users
            with reasonable advance notice before such changes become effective.
          </p>
        </article>

        <article className="item-container">
          <h2>{t('miscTermsWarranties')}</h2>
          <ul className={styles.listTypeNone}>
            <li>
              <span className={styles.pdRight1rem}>9.1</span>
              THE SERVICES ARE PROVIDED “AS IS”, “WHERE IS” AND “AS AVAILABLE”
              AND WITHOUT WARRANTIES OF ANY KIND. SAMSUNG, SAMSUNG AFFILIATES
              AND ITS LICENSORS MAKE NO REPRESENTATIONS OR WARRANTIES OR
              GUARANTEES OF ANY KIND OR NATURE, WHETHER EXPRESS OR IMPLIED,
              REGARDING THE SERVICES, AND SPECIFICALLY DISCLAIM ALL SUCH
              WARRANTIES, INCLUDING, ANY IMPLIED WARRANTY OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.
            </li>
            <li>
              <span className={styles.pdRight1rem}>9.2</span>
              WITHOUT LIMITING THE PREVIOUS DISCLAIMER, SAMSUNG, SAMSUNG
              AFFILIATES AND ITS LICENSORS DO NOT REPRESENT, WARRANT OR
              GUARANTEE THAT THE SERVICES WILL
              <ol style={{ listStyle: 'lower-alpha' }}>
                <li>
                  OPERATE IN AN UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE
                  MANNER,
                </li>
                <li>
                  WILL ALWAYS BE AVAILABLE OR FREE FROM ALL HARMFUL COMPONENTS
                  OR ERRORS
                </li>
                <li>
                  WILL BE SECURE OR IMMUNE (INCLUDING THE CONTENT DELIVERED TO
                  YOU AND THE INFORMATION YOU PROVIDE) FROM UNAUTHORIZED ACCESS,
                </li>
                <li>WILL BE ACCURATE, COMPLETE, OR RELIABLE,</li>
                <li>
                  THAT THE QUALITY OF THE SERVICES WILL BE SATISFACTORY TO YOU,
                  OR
                </li>
                <li>THAT ERRORS OR DEFECTS WILL BE CORRECTED.</li>
              </ol>
            </li>
            <li>
              <span className={styles.pdRight1rem}>9.3</span>
              THE USER ACKNOWLEDGES AND AGREES THAT ANY MATERIAL DOWNLOADED OR
              OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS DONE AT THE
              USER'S OWN DISCRETION AND RISK AND THE USER WILL BE SOLELY
              RESPONSIBLE FOR ANY CONSEQUENCE(S) THAT RESULTS, INCLUDING ANY
              DAMAGE OR HARM CAUSED BY ANY VIRUS, SPYWARE, WORM OR MALWARE.
            </li>
            <li>
              <span className={styles.pdRight1rem}>9.4</span>
              NO ADVICE OR INFORMATION (WHETHER ORAL OR WRITTEN) OBTAINED BY THE
              USER FROM SAMSUNG ON OR VIA THE SERVICES WILL CREATE ANY WARRANTY
              OR CONDITION NOT EXPRESSLY STATED IN THE AGREEMENT.
            </li>
          </ul>
        </article>

        <article className="item-container">
          <h2>{t('miscTermsLimitation')}</h2>
          <p>
            TO THE EXTENT PERMITTED BY APPLICABLE LAW, SAMSUNG SHALL NOT BE
            LIABLE TO THE USER, WHETHER IN CONTRACT, TORT (INCLUDING
            NEGLIGENCE), OR ANY THEORY OF LIABILITY, AND WHETHER OR NOT THAT THE
            POSSIBILITY OF SUCH DAMAGES OR LOSSES HAS BEEN NOTIFIED TO SAMSUNG,
            FOR
          </p>
          <ol style={{ listStyle: 'lower-alpha' }}>
            <li>
              ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL
              DAMAGES;
            </li>

            <li>
              ANY LOSS OF INCOME, BUSINESS, ACTUAL OR ANTICIPATED PROFITS,
              OPPORTUNITY, GOODWILL, OR REPUTATION (WHETHER DIRECT OR INDIRECT);
            </li>
            <li>
              ANY DAMAGE TO OR CORRUPTION OF DATA (WHETHER DIRECT OR INDIRECT);
              OR
            </li>
            <li>
              ANY LOSS OR DAMAGE AS A RESULT OF:
              <ol style={{ listStyle: 'lower-roman' }}>
                <li>USE THE USER MAY MAKE OF THE SERVICES,</li>
                <li>
                  ANY RELIANCE PLACED BY THE USER ON THE COMPLETENESS, ACCURACY
                  OR EXISTENCE OF ANY ADVERTISING,
                </li>
                <li>
                  ANY RELATIONSHIP OR TRANSACTION BETWEEN THE USER AND A THIRD
                  PARTY PROVIDER OF ANY SERVICE, PRODUCT, OR CONTENT, INCLUDING
                  ADVERTISERS AND SPONSORS WHOSE ADVERTISING IS MADE AVAILABLE
                  ON OR VIA THE SERVICES; AND
                </li>
                <li>
                  ANY CHANGES TO THE SERVICES, OR ANY PERMANENT OR TEMPORARY
                  CESSATION IN THE PROVISION OF THE SERVICES OR ANY PORTION
                  THEREOF.
                </li>
              </ol>
            </li>
          </ol>
        </article>

        <article className={styles.itemContainer}>
          <h2>{t('miscTermsGeneral')}</h2>
          <ul className={styles.listTypeNone}>
            <li>
              <span className={styles.pdRight1rem}>11.1</span>
              The User shall rely upon only the written terms set out in the
              terms of this Agreement and not any representations made
              elsewhere, including in advertising or directly to the User by any
              of our employees. This Agreement integrates and supersedes any and
              all prior oral or written agreements relating to the subject
              matter hereof. This Agreement may not be modified or amended, in
              whole or in part, except as set forth in Section 8.
            </li>
            <li>
              <span className={styles.pdRight1rem}>11.2</span>
              Whenever the words “include”, “includes” or “including” are used
              in this Agreement, they shall be deemed to be followed by the
              words “without limitation”, whether or not they are in fact
              followed by those words or words of like import.
            </li>
            <li>
              <span className={styles.pdRight1rem}>11.3</span>
              The User agrees that if Samsung does not exercise or enforce any
              legal right or remedy which is set out in this Agreement, or which
              Samsung has the benefit of under any applicable law, such will not
              be construed as a waiver of Samsung's rights or remedies, and that
              such rights or remedies remain available to Samsung.
            </li>
            <li>
              <span className={styles.pdRight1rem}>11.4</span>
              If any provision of this Agreement is held to be invalid by any
              judicial or administrative authority of competent jurisdiction,
              then that provision will be removed from this Agreement without
              affecting the remaining provisions of this Agreement, which will
              continue to be valid and enforceable.
            </li>
            <li>
              <span className={styles.pdRight1rem}>11.5</span>
              The User acknowledges and agrees that each Samsung Affiliate shall
              be a third party beneficiary to this Agreement and shall be
              entitled to directly enforce, and rely upon, any provision of this
              Agreement which confers a benefit on (or rights in favor of) them.
              Subject to the foregoing, no other person shall be a third party
              beneficiary to this Agreement. For the purposes of this Agreement,
              a Samsung Affiliate is any company, corporation, firm, partnership
              or other entity that controls Samsung, is controlled by Samsung or
              is under common control with Samsung; the term "controls" and
              "controlled" meaning the ability to direct the management of the
              relevant entity.
            </li>
            <li>
              <span className={styles.pdRight1rem}>11.6</span>
              The User may not transfer or assign this Agreement or any of the
              User's rights or obligations arising hereunder. Any attempted
              transfer or assignment of this Agreement or any of the User’s
              rights or obligations arising hereunder in violation of this
              Agreement shall be void and unenforceable.
            </li>
            <li>
              <span className={styles.pdRight1rem}>11.7</span>
              Samsung will not be liable or responsible for any failure to
              perform, or delay in performance of, any of any obligations under
              this Agreement that is caused by acts or events beyond Samsung’s
              reasonable control.
            </li>
            <li>
              <span className={styles.pdRight1rem}>11.8</span>
              The Agreement and the User's relationship with Samsung under this
              Agreement shall be governed by the laws of the State of New York
              without regard to its conflict of law principles. By using the
              Services, the User unconditionally consents and agrees that:
              <ol style={{ listStyle: 'lower-alpha' }}>
                <li>
                  any claim, dispute or controversy (whether in contract, tort,
                  or otherwise) the User may have against any Samsung entity,
                  the officers, directors, agents and employees of any Samsung
                  entity (the “Samsung Entity(ies)”) arising out of, relating
                  to, or connected in any way with the Services or the
                  determination of the scope or applicability of this clause,
                  will be resolved exclusively by final and binding arbitration
                  administered by the International Chamber of Commerce (“ICC”)
                  and conducted before a sole arbitrator in accordance with the
                  rules of the ICC;
                </li>
                <li>
                  this clause is made pursuant to a transaction involving
                  interstate commerce, and shall be governed by the Federal
                  Arbitration Act (“FAA”), 9 U.S.C. §§ 1-16;
                </li>
                <li>the arbitration shall be held in New York, New York;</li>
                <li>
                  the arbitrator’s decision shall be controlled by the terms and
                  conditions of this Agreement and any other agreements
                  referenced herein that the User has agreed to in connection
                  with the Services;
                </li>
                <li>
                  the arbitrator shall apply New York law consistent with the
                  FAA and applicable statutes of limitations, and shall honor
                  claims of privilege recognized at law;
                </li>
                <li>
                  there shall be no authority for any claims to be arbitrated on
                  a class or representative basis; arbitration can decide only
                  the User’s and/or the applicable Samsung Entity’s individual
                  claims; the arbitrator may not consolidate or join the claims
                  of other persons or parties who may be similarly situated; and
                  the User will not file or participate in a class action
                  against Samsung;
                </li>
                <li>
                  the arbitrator shall not have the power to award punitive
                  damages against the User or any Samsung Entity;
                </li>
                <li>
                  in the event that the administrative fees and deposits that
                  must be paid to initiate arbitration against any Samsung
                  Entity exceed One Hundred Twenty-Five US Dollars (US$125), and
                  you are unable (or not required under the rules of ICC) to pay
                  any fees and deposits that exceed this amount, Samsung agrees
                  to pay them and/or forward them on your behalf, subject to
                  ultimate allocation by the arbitrator. In addition, if the
                  User is able to demonstrate that the costs of arbitration will
                  be prohibitive as compared to the costs of litigation, Samsung
                  will pay as much of the User’s filing and hearing fees in
                  connection with the arbitration as the arbitrator deems
                  necessary to prevent the arbitration from being cost
                  prohibitive; and
                </li>
                <li>
                  with the exception of subsection (f) above, if any part of
                  this arbitration provision is deemed invalid, unenforceable or
                  illegal, or otherwise conflicts with the rules of ICC, the
                  balance of this arbitration provision shall remain in effect
                  and shall be construed in accordance with its terms as if the
                  invalid, unenforceable, illegal or conflicting provision were
                  not contained herein. If, however, subsection (f) above is
                  found to be invalid, unenforceable or illegal, the entirety of
                  this arbitration provision shall be null and void, and neither
                  the User nor Samsung shall be entitled to arbitrate the
                  dispute. For more information on the ICC and/or the rules of
                  the ICC, visit their website at www.iccwbo.org. To the extent
                  that applicable local law prevents certain disputes from being
                  resolved in the ICC arbitration in New York, New York, then
                  you agree to resolve the disputes through the ICC arbitration
                  held in your county, state or other place of residence.
                  Likewise, if applicable local law prevents the arbitrator from
                  applying New York law to resolve these disputes, then these
                  disputes will be governed by the applicable local laws of your
                  country, state, or other place of residence.
                </li>
              </ol>
            </li>
          </ul>
        </article>
      </main>
      <Footer />
    </>
  );
}
