import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import constants from '../../../config/Constants';
import { Typography } from '@mui/material';
import styles from './PushNotificationHandRaiser.module.css';

const CustomButton = styled(Button)({
  textTransform: 'none',
  flexBasis: '40%',
  display: 'block',
  borderRadius: '4px',
  padding: '10px 0',
  transition: 'all 0.2s linear',

  '&:hover': {
    borderColor: 'none',
    boxShadow: `0px 0px 5px ${constants.colors.primary}`,
  },
  '&:active': {
    boxShadow: 'none',
  },
  '&:focus': {
    boxShadow: `0px 0px 2px ${constants.colors.primary}`,
  },
});

const OutlinedBtn = styled(CustomButton)({
  border: '2px solid #CBCDDC',
  color: constants.colors.primary,
});

const ContainedButton = styled(CustomButton)({
  backgroundColor: constants.bgColors.primary,
  border: `2px solid ${constants.colors.primary}`,
  boxShadow: 'none',

  '&:hover': {
    background: constants.bgColors.primary,
  },
});

type PushNotificationHandRaiserInterface = {
  showPushNotificationDialog?: boolean;
  setShowPushNotificationDialog?: Function;
};

export default function PushNotificationHandRaiser(
  props: PushNotificationHandRaiserInterface
) {
  const {
    showPushNotificationDialog = false,
    setShowPushNotificationDialog,
  } = props;
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);

  const [notificationSubscription, setNotificationSubscription] = useState(
    false
  );
  const handleClose = () => {
    setOpenDialog(false);
    if (setShowPushNotificationDialog)
      setShowPushNotificationDialog(!showPushNotificationDialog);
  };

  const handleNotificationSubscription = () => {
    // Set notificationSubscription
    setNotificationSubscription(true);
    // Close Dialog
    setOpenDialog(false);
    if (setShowPushNotificationDialog)
      setShowPushNotificationDialog(!showPushNotificationDialog);

    // Perform any other stuff
  };

  return (
    <Box>
      <Dialog
        open={showPushNotificationDialog || openDialog}
        onClose={handleClose}
        aria-labelledby="notification-dialog-title"
        aria-describedby="notification-dialog-description"
        sx={{ textAlign: 'center' }}
      >
        <DialogTitle
          id="notification-dialog-title"
          className={styles.titleContainer}
        >
          <Typography variant={'body1'} className={styles.title}>
            {t('miscPushNotifAllowArt')}
          </Typography>
        </DialogTitle>

        <DialogContent className={styles.contentWrapper}>
          <DialogContentText
            id="notification-dialog-description"
            sx={{ hyphens: 'auto' }}
            className={styles.content}
          >
            {t('miscPushNotifBeFirst')}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.btnWrapper}>
          <OutlinedBtn variant="outlined" onClick={handleClose}>
            {t('miscPushNotifNoThanks')}
          </OutlinedBtn>
          <span className={styles.spaceBetween}></span>
          <ContainedButton
            variant="contained"
            onClick={handleNotificationSubscription}
          >
            {t('miscPushNotifAllow')}
          </ContainedButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
