import { combineReducers } from '@reduxjs/toolkit';
import counterReducer from './counterSlice';
import userReducer from './userSlice';
import categorySlice from './categorySlice';
import contentSlice from './contentSlice';
import contentStoreSlice from './contentStoreSlice';
import emailSubscriptionSlice from './brazeEmailSlice';
import bannerSlice from './bannerSlice';
import featuredCollectionSlice from './featuredCollectionSlice';
import collectionContentSlice from './collectionContentSlice';
import artistSlice from './artistSlice';
import collectionContentDetailsSlice from './collectionContentDetailsSlice';
import searchCollectionContentSlice from './searchCollectionContentSlice';
import clientSlice from './clientSlice';
import artistCollectionContentSlice from './artistCollectionContentSlice';

export const rootReducer = combineReducers({
  counter: counterReducer,
  user: userReducer,
  categories: categorySlice,
  content: contentSlice,
  contentStore: contentStoreSlice,
  emailSubscription: emailSubscriptionSlice,
  banners: bannerSlice,
  featuredCollection: featuredCollectionSlice,
  collectionContent: collectionContentSlice,
  meetTheArtist: artistSlice,
  collectionContentDetails: collectionContentDetailsSlice,
  searchCollectionContent: searchCollectionContentSlice,
  client: clientSlice,
  artistCollectionContent: artistCollectionContentSlice
});

export type RootReducer = ReturnType<typeof rootReducer>;
