import * as React from 'react';
import styles from './CustomAlert.module.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { 
    Box, Stack, Typography, Alert, IconButton, Collapse,Button, AlertTitle
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IosShareIcon from '@mui/icons-material/IosShare';
import Contants from '../../config/Constants'

const customStyles = {
     mainDesktopAlert: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden !important',
        background: 'linear-gradient(147.65deg, #575DE8 0%, #8050DD 100%)', 
        color: 'white', 
        textAlign: 'center',
     },
     alertMobileBox: {
      overflow: 'hidden',
      background: 'linear-gradient(147.65deg, #575DE8 0%, #8050DD 100%)', 
    },
    mainMobileAlert: {
      overflow: 'hidden !important',
      background: 'linear-gradient(147.65deg, #575DE8 0%, #8050DD 100%)',
      color: 'white', 
    },
    alertMobileTitleMain: {
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0px',
      gap: '16px',
    },
    alertMobileTitleImage: {
      width: 40,
      height: 40,
      borderRadius: 8   
    },
    alertMobileTopTitleText: {
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      fontFamily: 'SamsungOne',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 12,
    },
    alertMobileBottomTitleText: {
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      fontFamily: 'SamsungOne',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 12,
      image: {
        verticalAlign: 'bottom',
        height: 20,
        width: 15
      },
    },
    alertDesktopTitleText: {
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      fontFamily: 'SamsungOne',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 12,
      image: {
        verticalAlign: 'bottom',
        height: 20,
        width: 15
      },
    }
  };

const CustomAlert = (props: any): JSX.Element => {
  const { t } = useTranslation();
  const [screenType, setScreenType] = useState<string>(props.screenType);

  return (
    <div>
      <Box sx={customStyles.alertMobileBox}>
          <Alert
            icon={false}
            sx={{
                "& .MuiAlert-message": {
                display: 'flex',
                width:'100%',
                justifyContent: 'center',
                }
              }}
            style={screenType === Contants.screenType.mobile ? customStyles.mainMobileAlert : customStyles.mainDesktopAlert}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                style={screenType === Contants.screenType.mobile ? { paddingTop: 12 } : {paddingBottom: 15}}
                onClick={() => {
                    props.displayAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle>
            <Stack sx={{overflow: 'hidden', width: '100%'}} spacing={2} direction="row" alignItems="center">
              <img style={customStyles.alertMobileTitleImage} src="/images/logo.png" />
              <Stack spacing={0}  direction="column" alignItems="left">
                {screenType === Contants.screenType.mobile ? 
                   <>
                     <span style={customStyles.alertMobileTopTitleText}>{t('miscCustomAlertAddArt')}</span>
                     <div style={customStyles.alertMobileBottomTitleText}>
                       {t('miscCustomAlertInSafari1')}<IosShareIcon fontSize='small' style={customStyles.alertMobileBottomTitleText.image} sx={{ color: 'white' }} />{t('miscCustomAlertInSafari2')}
                     </div>
                   </>
                 : 
                  <div style={customStyles.alertDesktopTitleText}>
                     {t('miscCustomAlertAddArtStore1')}<IosShareIcon fontSize='small' style={customStyles.alertDesktopTitleText.image} sx={{ color: 'white' }} />{t('miscCustomAlertAddArtStore2')}
                  </div>
                }
              </Stack>
            </Stack>
            </AlertTitle>
          </Alert>
        </Box>
    </div>
  );
};

export default CustomAlert;
