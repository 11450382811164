import axios from 'axios';
import { AxiosPromise } from 'axios';
import Config from '../../config/Config';
const cloudFrontAPIService = axios.create();
const basePath = Config().configModule.cloudFront;

export const getAnimation = (fileName: string): AxiosPromise => {
  
  const config = {
    method: 'get',
    url: `${basePath}/${fileName}`,
    headers: {
      'Content-Type': 'application/json',
    }
  };

  return cloudFrontAPIService(config);
};