import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import constants from '../../../config/Constants';
import PushNotificationHandRaiser from '../pushNotificationHandRaiser/PushNotificationHandRaiser';
import IosEmailConfirmationHandRaiser from '../iosEmailConfirmationHandRaiser/IosEmailConfirmationHandRaiser';
import styles from './EmailConfirmationHandRaiser.module.css';
import { Typography } from '@mui/material';
import { isSafari } from 'react-device-detect';
import { checkBrowserIsChrome, checkBrowserIsOSMobile, checkIsStandalone } from '../../../utils/Common';

type propsInterface = {
  showEmailConfirmationHandRaiser: boolean;
  setShowEmailConfirmationHandRaiser: Function;
  data: { status: string; caption: string };
};

enum Status {
  SUCCESS = 'success',
}

export default function EmailConfirmationHandRaiser(props: propsInterface) {
  const { t } = useTranslation();
  const [showPushNotificationDialog, setShowPushNotificationDialog] = useState(
    false
  );

  const [showIosEmailConfirmationDialog, setShowIosEmailConfirmationDialog] = useState(
    false
  );

  const handleBtnClick = () => {
    props.setShowEmailConfirmationHandRaiser(false);

    let navigator: any;
    navigator = window.navigator;
    let displayIOSDialog: boolean = false;
    if (isSafari) {
      if (!checkBrowserIsChrome(window.navigator)) {
        if (checkBrowserIsOSMobile(window.navigator)) {
          if (!checkIsStandalone(window)) {
            if (props.data.status === Status.SUCCESS) {
              setShowIosEmailConfirmationDialog(!showIosEmailConfirmationDialog);
              displayIOSDialog = true;
            }
          }
        }
      } 
    } 
    if (!displayIOSDialog) {
      if (checkIsStandalone(window)) {
        if (props.data.status === Status.SUCCESS) {
          setShowPushNotificationDialog(!showPushNotificationDialog);
        }
      }
    }
  };

  const handleIosEmailConfirmationClose = () => {
    if (checkIsStandalone(window)) {
      setShowPushNotificationDialog(!showPushNotificationDialog);
    }
  }


  return (
    <>
      <Box>
        <Dialog
          open={props.showEmailConfirmationHandRaiser}
          onClose={() => props.setShowEmailConfirmationHandRaiser(false)}
          aria-labelledby="email-confirmation-dialog-title"
          aria-describedby="email-confirmation-dialog-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle
            id="email-confirmation-dialog-title"
            className={styles.titleContainer}
          >
            <Typography variant={'body1'} className={styles.title}>
              {props.data.status === Status.SUCCESS
                ? t('miscEmailThankyou')
                : t('miscEmailFailed')}
            </Typography>
          </DialogTitle>

          <DialogContent className={styles.contentWrapper}>
            <DialogContentText className={styles.content}>
              {props.data.status === Status.SUCCESS ? props.data.caption : null}
            </DialogContentText>
          </DialogContent>

          <DialogActions className={styles.btnWrapper}>
            <Button
              onClick={handleBtnClick}
              sx={{ color: constants.colors.primary }}
              className={styles.btn}
            >
              {t('miscEmailOk')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <IosEmailConfirmationHandRaiser 
          showIosEmailConfirmationDialog={
            props.data.status === Status.SUCCESS && showIosEmailConfirmationDialog
          }
          setShowIosEmailConfirmationDialog={setShowIosEmailConfirmationDialog}
          handleIosEmailConfirmationClose={handleIosEmailConfirmationClose}
      />
      <PushNotificationHandRaiser 
        showPushNotificationDialog={
          props.data.status === Status.SUCCESS && showPushNotificationDialog
        }
        setShowPushNotificationDialog={setShowPushNotificationDialog}
      />
    </>
  );
}
