import React, { useEffect, useState } from 'react';
import styles from './LandingPageMeetTheArtist.module.css';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';
import Skeleton from '@mui/material/Skeleton';
import {
  meetTheArtistData,
  fetchMeetTheArtist
} from '../../redux/reducers/artistSlice';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import './meet-the-artist-swiper.styles.css';
import './meet-the-artist-swiper.styles.css';
import Box from '@mui/material/Box';
import YouTubeEmbed from '../../components/youtubeEmbed/YouTubeEmbed';
import { Link } from 'react-router-dom';
import { logClientError } from '../../redux/reducers/clientSlice';

export default function LandingPageMeetTheArtist() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const meetTheArtist = useAppSelector(meetTheArtistData);
  const [sliderIndex, setSliderIndex] = useState(0);
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    try {
      if (!meetTheArtist || !meetTheArtist.length) {
        dispatch(fetchMeetTheArtist());
      }
    } catch (e) {
      dispatch(fetchMeetTheArtist());
      dispatch(logClientError(`MEET THE ARTIST: ${e.message}`));
    }
  }, []);

  const getIntialSliderIndex = () => {
    let queryMetaId = queryParams.get("mta_id");
    let index = meetTheArtist.findIndex((item) => item.youtubeEmbedDetails.embedId === queryMetaId);
    if (index < 0) {
        index = 0;
    }
    setSliderIndex(index);
    return index;
  }
  
  return (
    <div className={styles.containerWrapper}>
      <section className={styles.container}>
        <Typography variant="body1" className={styles.heading}>
         {t('miscLandingPageMeetTheArtist')}
        </Typography>

        {meetTheArtist && meetTheArtist.length > 0 ? (
          <Swiper
            onSlideChange={(swiper) => {
              setSliderIndex((prev) => swiper.activeIndex);
            }}
            onSwiper={(swiper) => swiper.activeIndex = getIntialSliderIndex()}
            navigation={true}
            pagination={{ clickable: true }}
            modules={[Pagination, Navigation]}
            className="meetTheArtistSwiper"
          >
            {meetTheArtist.map((item, index: number) => {
              return (
                <SwiperSlide key={index} className={styles.carouselContainer}>
                  <Typography variant="h4" className={styles.title}>
                    {item.artistName}
                  </Typography>
                  <Box className={styles.mediaContainer}>
                    <YouTubeEmbed
                      id={item.youtubeEmbedDetails.embedId}
                      thumbnail={item.imageURL}
                      title={item.youtubeEmbedDetails.title}
                      unLoadIframe={sliderIndex !== index}
                      showImage={item.showImage}
                    />
                  </Box>
                  <Typography variant="body1" className={styles.info}>
                    {item.description}
                  </Typography>
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          <>
            <Skeleton
              variant="text"
              sx={{ fontSize: '2.125rem', height: '2rem' }}
            />
            <Skeleton variant="rounded" sx={{ minHeight: '15rem' }} />
            <Skeleton variant="text" />
          </>
        )}
      </section>

      {meetTheArtist && meetTheArtist.length > 0 ? (
        // @ts-ignore
        <Link to={String(meetTheArtist[sliderIndex].cta.url)} className={styles.btn}>
          {
            // @ts-ignore
            <span className={styles.btnText}>{meetTheArtist[sliderIndex].cta.title}</span>
          }
        </Link>
      ) : (
        <Skeleton
          variant="rounded"
          sx={{ height: '40px', maxWidth: '145px', margin: 'auto' }}
        />
      )}
    </div>
  );
}
