import React from 'react';
import styles from './styles.module.css';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import { validateEmoji, validateSpecialChar } from '../../utils/Common';
import { SearchCollectionContentParamsInterface } from '../../interfaces/collections.interface';
import { fetchSearchedCollectionContent } from '../../redux/reducers/searchCollectionContentSlice';
import { useAppDispatch } from '../../redux/hooks';

type PropsInterface = {
  setSearchString: any;
  setSearchErrorState: any;
}
const SearchField = (props: PropsInterface) => {
  const { setSearchString, setSearchErrorState } = props;
  const dispatch = useAppDispatch();

  const searchContents = (keyword: string) => {
    setSearchString(keyword);
    const params: SearchCollectionContentParamsInterface = {
      country: 'US',
      languageCode: 'US',
      year: '2022',
      model: '22_PONTUSM_FTV',
      keyword: keyword.trim().replace(/\s+/g, ' ')
    };

    if (!keyword.trim()) {
      setSearchErrorState({
        error: true,
        message: 'Invalid Search'
      })
    } else {
      dispatch(fetchSearchedCollectionContent(params));
    }
  }
  const validate = (values: any) => {
    const errors: any = {}

    if (!values.keyword) {
      errors.keyword = 'Required'
      setSearchString('');
      setSearchErrorState({
        error: false,
        message: ''
      })
    } else {
      const { isSpecialChar, validatedValue } = validateSpecialChar(values.keyword);
      const { isEmoji, validatedValue : newValidatedValue } = validateEmoji(validatedValue);

      if (isSpecialChar || isEmoji) {
        formik.setFieldValue('keyword', newValidatedValue, true);
      }
    }

    return errors
  }

  const formik = useFormik({
    initialValues: {
      keyword: '',
    },
    validate,
    onSubmit: async (values) => {
      await searchContents(values.keyword);
    },
  })

  return (
    <div className={styles.searchField}>
      <form onSubmit={formik.handleSubmit} className={styles.searchForm}>
        <TextField
          id="filled-search"
          name='keyword'
          placeholder={'Search for an artist or artwork'}
          onChange={formik.handleChange}
          value={formik.values.keyword}
          type="search"
          size={'small'}
          sx={{ width: '100%', marginRight: '10px' }}
        />
        <Button type={'submit'}  className={styles.searchBtn}>Search</Button>
      </form>
    </div>
  );
};

export default SearchField;