import * as React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { Link, useNavigate } from 'react-router-dom';
import styles from './logo.module.css';
import PersonIcon from '@mui/icons-material/Person';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
  previousPath,
  clearPreviousPath,
  setPreviousPath,
  setScrollPosition
} from '../../redux/reducers/userSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import IconButton from '@mui/material/IconButton';

import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import CustomDialog from '../../components/dialogs/CustomDialog';
import { selectCount, profileFamilyName, profileGivenName, profilePicture, profileEmail, isUserSALoggedIn, logoutSA } from "../../redux/reducers/userSlice";
import Config from '../../config/Config';
import ProfileIconSmall from '../icons/profileIconSmall';
import { useState } from 'react';
import ProfileIconSmallHovered from '../icons/profileIconSmallHovered';
import LogoutProfileIconHovered from '../icons/logoutProfileIconHovered';
import LogoutProfileIcon from '../icons/logoutProfileIcon';

type LogoProps = {
  borderBottom: boolean;
  backArrow: boolean;
  removeHomeNavigateLink: boolean;
};

export default function Logo(props: LogoProps) {
  const count = useAppSelector(selectCount);
  const isSALoggedIn = useAppSelector(isUserSALoggedIn)
  const profileSAGivenName = useAppSelector(profileGivenName);
  const profileSAFamilyName = useAppSelector(profileFamilyName);
  const profileSAPicture = useAppSelector(profilePicture);
  const profileSAEmail = useAppSelector(profileEmail);
  const randomState = '12345678901234567890123456789011'; //cryptoRandomString({ length: 32 });
  const clientId = Config().configModule.saLogin.clientId;
  const redirectBaseUrl = Config().configModule.saLogin.redirectBaseUrl;
  const aliasId = Config().configModule.saLogin.aliasId;
  const logOutReturnURL = Config().configModule.saLogin.logOutReturnURL;
  const [openLoginDialog, setOpenLoginDialog] = React.useState(false);
  const [openProfileDialog, setOpenProfileDialog] = React.useState(false);
  const [error, setError] = React.useState('');
  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let previousPagePath = useAppSelector(previousPath);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClose = () => {
    dispatch(logoutSA());
    setAnchorEl(null);
  };

  const navigateBack = () => {
    let  previousPageType = previousPagePath.length > 0 ? previousPagePath[previousPagePath.length - 1].type : '';
    if (previousPageType === 'terms-conditions') {
      if (previousPagePath.length === 1) {
        dispatch(setScrollPosition('bottom'));
      }
    }
    previousPagePath = previousPagePath.length > 0 ? previousPagePath[previousPagePath.length - 1].path : '/landingpage';
    navigate(previousPagePath, { replace: true });
    dispatch(clearPreviousPath(''));
  };

  const handleFavorites = () => {
    navigate('/favorites/collections');
  };

  const handleUploadImage = () => {
    navigate('/upload/image');
  };

  const displayLogin = () => {
    setOpenLoginDialog(true);
    setIsHovered(false);
  }

  const resetLoginDialog = () => {
    setOpenLoginDialog(false);
    setIsHovered(false);
  }

  const displayProfile = () => {
    setOpenProfileDialog(true);
    setIsHovered(false);
  }

  const resetProfileDialog = () => {
    setOpenProfileDialog(false);
    setIsHovered(false);
  }

  const getAvatarInitials = () => {
    let initials =  profileSAGivenName && profileSAGivenName.length > 0 && profileSAGivenName.substring(0,1);
    return initials.toUpperCase();
  }

  const renderAvatar = () => {
    if (profileSAPicture) {
      return <Avatar onClick={displayProfile} src={profileSAPicture} sx={{ bgcolor: '#00D8FF', width: 32, height: 32}} className={styles.ProfileAvatar} />
    } else {
      return (
        <span onClick={displayProfile} className={styles.profileIcon} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
          {isHovered ? <ProfileIconSmallHovered /> : <ProfileIconSmall />}
        </span>
      )
    }
  }

  const getProfileData = () => {
    return {
      givenName: profileSAGivenName,
      familyName: profileSAFamilyName,
      picture: profileSAPicture,
      email: profileSAEmail
    }
  }

  const addBorderBottom = props.borderBottom ? styles.HeaderBorderBottom : '';
  const anchorDisplayStyle = props.backArrow
    ? styles.anchorContainerGrid
    : styles.anchorContainerFlex;

  return (
    <div className={styles.anchorElement}>
      <Box className={`${addBorderBottom} ${Config().samsungAccountFeatureFlag ? styles.anchorContainerFlex : anchorDisplayStyle}`}>
        {props.backArrow ? (
          <div className={styles.BackArrow}>
            <img
              src="/images/back_arrow.svg"
              alt="back arrow"
              onClick={navigateBack}
            />
          </div>
        ) : Config().samsungAccountFeatureFlag ? (<div className={styles.LeftSpace}></div>) : ''}
        <div className={styles.Logo}>
          {props.removeHomeNavigateLink ? (
            <img src="/images/logo_white.svg" alt="logo" />
          ) : (
            <Link to={'/landingpage'}
               onClick={() => dispatch(setPreviousPath({path: '/landingpage', type: 'landingpage'}))}
               >
              <img src="/images/logo_white.svg" alt="logo" />
            </Link>
          )}
        </div>
          {Config().samsungAccountFeatureFlag
            ? isSALoggedIn
                ? renderAvatar()
                : (
                  <div className={styles.ProfileIcon}>
                    <span onClick={displayLogin} className={styles.profileIcon} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                      {isHovered ? <LogoutProfileIconHovered /> : <LogoutProfileIcon />}
                    </span>
                  </div>
                )
            : (<aside style={{ background: 'var(--primaryDarkColor)' }}></aside>)
          }
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {isSALoggedIn ? 
            <div>
              <MenuItem>
                 <span  className={styles.loginLinkItem}>{profileSAGivenName} {profileSAFamilyName}</span>
              </MenuItem> 
              <MenuItem onClick={handleFavorites}>
                 <span className={styles.loginLinkItem}>Favorites</span>
              </MenuItem> 
              <MenuItem onClick={handleUploadImage}>
                 <span className={styles.loginLinkItem}>Upload Images</span>
              </MenuItem> 
              <MenuItem onClick={handleLogoutClose}>
                       <Logout sx={{ marginRight: '0.2rem' }} fontSize="small" />
                       <a
                          className={styles.loginLinkItem}
                          href={`https://account.samsung.com/accounts/v1/${aliasId}/signOutGate?client_id=${clientId}&signOutURL=${logOutReturnURL}/landingpage&state=${randomState}`}
                          rel="noopener noreferrer"
                        >
                          {'Logout'}
                        </a>
              </MenuItem>
             </div>
          :
            <div>
              <MenuItem onClick={handleClose}>
                  <a
                    className={styles.loginLinkItem}
                    href={`https://account.samsung.com/accounts/v1/${aliasId}/signInGate?client_id=${clientId}&response_type=code&redirect_uri=${redirectBaseUrl}/landingpage&state=${randomState}`}
                    rel="noopener noreferrer"
                  >
                    {'Login'}
                  </a>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                  <a
                      className={styles.loginLinkItem}
                      href={`https://account.samsung.com/accounts/v1/${aliasId}/signUpGate?client_id=${clientId}&response_type=code&redirect_uri=${redirectBaseUrl}/landingpage&state=${randomState}`}
                      rel="noopener noreferrer"
                    >
                      {'Create Account'}
                  </a>
              </MenuItem>
            </div>
        }
      </Menu>
      {openLoginDialog && <CustomDialog displayDialog={openLoginDialog} data={{}} resetDisplayDialogStatus={resetLoginDialog} dialogType='login' />}
      {openProfileDialog && <CustomDialog displayDialog={openProfileDialog} data={getProfileData()} resetDisplayDialogStatus={resetProfileDialog} dialogType='profile' />}
    </div>
  );
}
