import React from 'react';
import styles from './login.confirmation.dialog.module.css';
import Typography from '@mui/material/Typography';
import CheckedIcon from './checkedIcon';
import ArtStoreIcon from './artStoreIcon';

type PropsInterface = {
  handleClose: any;
  data: any;
}

export default function LoginConfirmationDialog(props: PropsInterface) {

  return (
    <div className={styles.dialogWrapper}>
      <div className={styles.container}>
        <Typography variant={'body1'} className={styles.title}>{`${props.data.title} has been ${!props.data.exist ? 'removed from' : 'saved to'} The Frame TV`}</Typography>
        <div className={styles.imgWrapper}>
          <span className={styles.artStoreIcon}>
            <ArtStoreIcon/>
          </span>
          <span className={styles.checkIcon}>
            <CheckedIcon/>
          </span>
        </div>
        <Typography variant={'body1'} className={styles.subTitle}>Find your saved artwork in the ‘My Mobile Artworks’ row inside Samsung Art Store</Typography>
      </div>
      <Typography variant={'body1'} className={styles.description}>
        You must be signed into the same Samsung account on SamsungArtStore.com and The Frame TV. It may take up to 20 minutes for artwork to appear on The Frame. Only 2022 The Frame TV models and later are supported.
      </Typography>
      <div className={styles.btnWrapper}>
        <a className={styles.btn} onClick={props.handleClose}>
          <Typography variant={'body1'} className={styles.btnText}>Close</Typography>
        </a>
      </div>
    </div>
  )
}