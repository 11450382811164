import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import SearchField from './searchField';
import { CollectionPageStatusEnum } from './CollectionPageStatus.enum';
import LoadingState from './loadingState';
import TitleSection from './titleSection';
import ImageSection from './imageSection';
import { Box, Typography } from '@mui/material';
import {
  searchedCollectionContentData,
  status,
} from '../../redux/reducers/searchCollectionContentSlice';
import { useAppSelector } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';

type PropsInterface = {
  pageType: string;
  favoriteHandler: any;
  findFavorite: any;
}

const SearchCollectionBody = (props: PropsInterface) => {
  const { pageType, favoriteHandler, findFavorite } = props;
  const searchedContentData = useAppSelector(searchedCollectionContentData);
  const useSearchCollectionStatus = useAppSelector(status);
  const [searchString, setSearchString] = useState('');
  const [searchErrorState, setSearchErrorState] = useState({
    error: false,
    message: ''
  });
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const RenderSearchResults = () => {
    let searchResults = searchedContentData.filter((searchItem: any) => (searchItem.title === 'Artworks'));

    if (searchResults.length) {
      return (
        <>
          <TitleSection
            pageType={pageType}
          />

          {searchResults.map((searchItem: any, index: number) => (
            <ImageSection
              key={index}
              contents={searchItem.contents}
              favoriteHandler={favoriteHandler}
              findFavorite={findFavorite}
              pageType={pageType}
            />
          ))}
        </>
      )
    } else {
      if (searchString) {
        return (
          <RenderErrorState />
        )
      } else {
        return <div style={{ height: '40vh' }}></div>
      }
    }
  }

  const RenderErrorState = () => {
    return (
      <>
        <Typography
          variant="body1"
          sx={{ margin: '200px 0' }}
        >
          {t('miscSearchNoResults')}
        </Typography>
      </>
    )
  }

  return (
    <Box className={styles.container}>
      <SearchField setSearchString={setSearchString} setSearchErrorState={setSearchErrorState} />
      {useSearchCollectionStatus === CollectionPageStatusEnum.LOADING && (
        <LoadingState />
      )}

      {useSearchCollectionStatus === CollectionPageStatusEnum.FULFILLED ?
        (searchedContentData && searchedContentData.length > 0 ? (
          <RenderSearchResults />
        ) : searchString || searchErrorState.error ? (
          <RenderErrorState />
        ) : (
          <div style={{ height: '40vh' }}></div>
        )) : searchErrorState.error ? (
          <RenderErrorState />
        ) : (
          <div style={{ height: '40vh' }}></div>
        )
      }
    </Box>
  );
};

export default SearchCollectionBody;