import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, createSearchParams, useSearchParams } from 'react-router-dom';
import {
  initialize,
  automaticallyShowInAppMessages,
  openSession,
  requestPushPermission,
  isPushSupported,
} from '@braze/web-sdk';
import styles from './LandingPage.module.css';
import {
  BrowserView,
  MobileView,
  TabletView,
  isSafari,
  isChrome,
} from 'react-device-detect';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  isUserLoggedIn,
  isUserSALoggedIn,
  notificationKey,
  notificationEndpoint,
  setRedirectUrl,
  scrollPosition,
  setScrollPosition
} from '../../redux/reducers/userSlice';
import { HowToSubscribe } from '../../components/howToSubscribe/HowToSubscribe';
import EmailConfirmationHandRaiser from '../../components/handRaisers/emailConfirmationHandRaiser/EmailConfirmationHandRaiser';
import PushNotificationHandRaiser from '../../components/handRaisers/pushNotificationHandRaiser/PushNotificationHandRaiser';
import Footer from '../../components/footer/Footer';
import NewsletterSubscriptionSection from '../../components/newsletterSubscriptionSection/NewsletterSubscriptionSection';
import IosEmailConfirmationHandRaiser from '../../components/handRaisers/iosEmailConfirmationHandRaiser/IosEmailConfirmationHandRaiser';
import LandingPageAnimation from './LandingPageAnimation';
import LandingPageFeaturedCollection from './LandingPageFeaturedCollection';
import Loading from '../../components/Loading';
import Logo from '../../components/logo/Logo';
import CustomAlert from '../../components/alert/CustomAlert';
import Contants from '../../config/Constants';
import LandingPageFrameSection from './LandingPageFrameSection';
import { useCookies } from 'react-cookie';
import {
  checkBrowserIsChrome,
  checkBrowserIsOSMobile,
  checkIsStandalone,
} from '../../utils/Common';
import LandingPageMeetTheArtist from './LandingPageMeetTheArtist';
import ScrollAnchor from '../../components/scrollAnchor/ScrollAnchor';
import { clearSearchedCollectionData } from '../../redux/reducers/searchCollectionContentSlice';

export default function LandingPage(props: any) {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  let scrollPositionLocation = useAppSelector(scrollPosition);
  const [
    showEmailConfirmationHandRaiser,
    setShowEmailConfirmationHandRaiser,
  ] = useState(false);
  const [subscriptionCaption, setSubscriptionCaption] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const isLoggedIn = useAppSelector(isUserLoggedIn);
  const isSALoggedIn = useAppSelector(isUserSALoggedIn);

  const [displayIOSAlert, setDisplayIOSAlert] = useState(false);
  const [cookies, setCookie] = useCookies(['alertstatus']);
  const [showPushNotificationDialog, setShowPushNotificationDialog] = useState(
    false
  );
  const [searchParams, setSearchParams] = useSearchParams();

  let fullPathLink = location.pathname;

  if (location.search) {
    fullPathLink = fullPathLink + location.search;
  }

  useEffect(() => {
    if (scrollPositionLocation && scrollPositionLocation === 'bottom') {
      window.scrollTo(0, document.body.scrollHeight); // Put the bottom of the page into view at load time
      dispatch(setScrollPosition('top')); 
    } else {
      window.scrollTo(0, 0); // Put the top of the page into view at load time
    }
    dispatch(clearSearchedCollectionData());

    window.addEventListener('appinstalled', () => {
      setShowPushNotificationDialog(true);
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      let navigator: any;
      navigator = window.navigator;
      dispatch(setRedirectUrl('landingpage'));

      if (isSafari) {
        if (!checkBrowserIsChrome(window.navigator)) {
          if (checkBrowserIsOSMobile(window.navigator)) {
            if (
              navigator &&
              (!navigator.standalone || navigator.standalone === false)
            ) {
              // if alertstatus is true the alert is not displayed
              if (!cookies.alertstatus) {
                setTimeout(() => {
                  setDisplayIOSAlert(true);
                }, 5000);
              }
            }
          }
        }
      }
    }
  }, [isLoggedIn]);

  const displayAlert = (status: boolean) => {
    if (status === false) {
      let expires = new Date();
      expires.setDate(expires.getDate() + 5);
      // if alertstatus is true the alert is not displayed
      setCookie('alertstatus', true, { path: '/', expires });
    }
    setDisplayIOSAlert(status);
  };

  return !isLoggedIn ? (
    <Loading />
  ) : (
    <>
      <EmailConfirmationHandRaiser
        showEmailConfirmationHandRaiser={showEmailConfirmationHandRaiser}
        setShowEmailConfirmationHandRaiser={setShowEmailConfirmationHandRaiser}
        data={{ caption: subscriptionCaption, status: subscriptionStatus }}
      />
      <PushNotificationHandRaiser
        showPushNotificationDialog={showPushNotificationDialog}
        setShowPushNotificationDialog={setShowPushNotificationDialog}
      />
      <main>
        <Logo
          borderBottom={true}
          backArrow={false}
          removeHomeNavigateLink={true}
        />
        {displayIOSAlert && (
          <>
            <BrowserView>
              <CustomAlert
                screenType={Contants.screenType.desktop}
                displayAlert={displayAlert}
              />
            </BrowserView>
            <MobileView>
              <CustomAlert
                screenType={Contants.screenType.mobile}
                displayAlert={displayAlert}
              />
            </MobileView>
            <TabletView>
              <CustomAlert
                screenType={Contants.screenType.tablet}
                displayAlert={displayAlert}
              />
            </TabletView>
          </>
        )}

        <Stack className={styles.banner} textAlign="center">
          <Box sx={{ overflow: 'hidden' }}>
            <img
              src="/images/samsung_art_store_logo.svg"
              alt="samsung_art_store logo"
              className={styles.artStoreBannerLogo}
            />
          </Box>

          <Typography variant="h2" className={styles.bannerHeadline}>
            <span>{t('miscLandingPageMainTitle1')}</span>
            <span>{t('miscLandingPageMainTitle2')}</span>
          </Typography>
          <Typography variant="body1" className={styles.bannerSubHeading}>
           {t('miscLandingPageMainSubTitle')}
          </Typography>
        </Stack>

        <ScrollAnchor id="featured">
          <LandingPageFeaturedCollection  
             featureType={'featured_collection'}
             title={t('miscLandingPageFeatured')}
          />
        </ScrollAnchor>
        <ScrollAnchor id="meet-the-artist">
          <LandingPageMeetTheArtist />
        </ScrollAnchor>
        <ScrollAnchor id="featuredmaster">
          <LandingPageFeaturedCollection 
            featureType={'masters_collection'}
            title={t('miscLandingPageMasters')}
          />
        </ScrollAnchor>
        <div
          style={{
            overflow: 'hidden',
            backgroundColor: '#1d1d2f',
          }}
        >
          <ScrollAnchor id="featuredmuseums">
            <LandingPageFeaturedCollection 
              featureType={'museums_collection'}
              title={t('miscLandingPageMuseums')}
            />
          </ScrollAnchor>
        </div>
        <ScrollAnchor id="featuredcontemporaryartists">
          <LandingPageFeaturedCollection 
            featureType={'contemporary_artists'}
            title={t('miscLandingPageContempArtist')}
          />
        </ScrollAnchor>
        <LandingPageAnimation />
        <ScrollAnchor id="join">
          <HowToSubscribe />
        </ScrollAnchor>

        <div
          style={{
            overflow: 'hidden',
            backgroundImage: 'linear-gradient(140deg, #575de8 10%, #8050dd )',
          }}
        >
          <ScrollAnchor id="buy">
            <LandingPageFrameSection />
          </ScrollAnchor>

          <ScrollAnchor id="newsletter">
            <NewsletterSubscriptionSection
              setShowEmailConfirmationHandRaiser={
                setShowEmailConfirmationHandRaiser
              }
              setSubscriptionStatus={setSubscriptionStatus}
              setSubscriptionCaption={setSubscriptionCaption}
            />
          </ScrollAnchor>
        </div>

        <Footer />
      </main>
    </>
  );
}
