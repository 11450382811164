import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import styles from './PageNotFound.module.css';
import buttonStyles from '../../styles/Button.module.css';
import { useAppSelector } from '../../redux/hooks';
import { isUserLoggedIn } from '../../redux/reducers/userSlice';
import Loading from '../../components/Loading'

export default function PageNotFound() {
  const { t } = useTranslation();
  const isLoggedIn = useAppSelector(isUserLoggedIn);

  return (
    (!isLoggedIn) ?
        <Loading />
     :
    <Stack
      direction={'column'}
      spacing={2}
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      className={styles.container}
    >
      <Box className={styles.logo}>
        <img src="/images/logo_white.svg" alt="artstore logo" />
      </Box>
      <Typography variant="h5" className={styles.header}>
      {t('miscPageNotFound')}
      </Typography>
      <Typography variant="body1" className={styles.subtitle}>
      {t('miscIOSNotifPageNotExist')}
      </Typography>
      <Typography
        variant="body1"
        className={`${styles.mbottom} ${styles.subtitle}`}
      >
        {t('miscIOSNotifPageDeleted')}
      </Typography>
      <Link to="/landingpage" className={buttonStyles.btnPrimary}>
      {t('miscBackToHome')}
      </Link>
      <br />
    </Stack>
  );
}
