import React from 'react';

const LogoutProfileIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="#1D1D2F"/>
      <path d="M19.4606 11.2175C19.4606 13.0432 17.9807 14.5231 16.1549 14.5231C14.3291 14.5231 12.8492 13.0432 12.8492 11.2175C12.8492 9.39178 14.3291 7.91187 16.1549 7.91187C17.9807 7.91187 19.4606 9.39178 19.4606 11.2175Z" stroke="white" strokeWidth="1.36913" strokeMiterlimit="10"/>
      <path d="M22.3449 22.142C22.3449 19.3653 19.5732 16.792 16.153 16.792C12.7329 16.792 9.96117 19.3679 9.96117 22.142V23.9118H22.3449V22.142Z" stroke="white" strokeWidth="1.36913" strokeLinejoin="round"/>
    </svg>
  );
};

export default LogoutProfileIcon;