import React, { useEffect } from 'react';
import { CollectionPageStatusEnum } from './CollectionPageStatus.enum';
import TitleSection from './titleSection';
import ImageSection from './imageSection';
import NoCollectionErrorState from './noCollectionErrorState';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  collectionContentData,
  fetchCollectionContent,
  status as collectionStatus,
} from '../../redux/reducers/collectionContentSlice';
import { CollectionContentParamsInterface } from '../../interfaces/collections.interface';
import { logClientError } from '../../redux/reducers/clientSlice';
import { resetFavoriteListSavedStatus } from '../../redux/reducers/contentStoreSlice';
import { isUserLoggedIn, setRedirectUrl } from '../../redux/reducers/userSlice';
import Common from '../../utils/Common';
import styles from './styles.module.css';
import LoadingState from './loadingState';
import { Box } from '@mui/material';

type PropsInterface = {
  pageType: string;
  collectionShareablePath: string;
  favoriteHandler: any;
  findFavorite: any;
}

const CollectionsBody = (props: PropsInterface) => {
  const { pageType, collectionShareablePath, favoriteHandler, findFavorite } = props;
  const { updateAndGetQueryParams } = Common();
  const collectionsContent = useAppSelector(collectionContentData);
  const useCollectionStatus = useAppSelector(collectionStatus);
  const isLoggedIn = useAppSelector(isUserLoggedIn);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const { categoryId, subCatId } = updateAndGetQueryParams.collectionsPage();

    const params: CollectionContentParamsInterface = {
      country: 'US',
      languageCode: 'US',
      year: '2022',
      model: '22_PONTUSM_FTV',
      categoryId,
      subCatId,
    };

    if (isLoggedIn) {
      try {
        isInvalidData(categoryId, subCatId) && dispatch(fetchCollectionContent(params));
      } catch (e) {
        dispatch(fetchCollectionContent(params));
        dispatch(logClientError(`COLLECTION: ${e.message}`));
      }

      dispatch(resetFavoriteListSavedStatus());
    }
    dispatch(setRedirectUrl('collections'));

    window.scrollTo(0, 0); // Put the top of the page into view at load time
  }, [isLoggedIn]);

  const isInvalidData = (categoryId: string, subCatId: string) => {
    return !collectionsContent || !collectionsContent.length ||
      (
        collectionsContent.length &&
        (
          collectionsContent[0].categoryId !== categoryId ||
          collectionsContent[0].subCatId !== subCatId
        )
      );
  }

  return (
    <Box className={styles.container}>
      {useCollectionStatus === CollectionPageStatusEnum.LOADING && (
        <LoadingState />
      )}
      {useCollectionStatus === CollectionPageStatusEnum.FULFILLED &&
        (collectionsContent && collectionsContent.length > 0 && (collectionsContent[0].contents.sixteenByNine.length || collectionsContent[0].contents.nineBySixteen.length) ? (
          <>
            <TitleSection
              name={collectionsContent[0].name}
              info={collectionsContent[0].info}
              collectionShareablePath={collectionShareablePath}
              pageType={pageType}
            />
            <ImageSection
              contents={collectionsContent[0].contents}
              favoriteHandler={favoriteHandler}
              findFavorite={findFavorite}
              pageType={pageType}
            />
          </>
        ) : (
          <NoCollectionErrorState />
        ))
      }
    </Box>
  );
};

export default CollectionsBody;