import React from 'react';

const ArtStoreIcon = () => {
  return (
    <svg width="41" height="33" viewBox="0 0 41 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5791 23.6935H38.0739V3.36182H3.5791V23.6935Z" fill="url(#paint0_linear_4104_738)"/>
      <mask id='mask0_4104_738'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='41'
          height='33'>
        <path
          d='M0 3.72156C0 1.8272 1.53833 0.285156 3.43008 0.285156H37.5386C39.4299 0.285156 40.9686 1.8272 40.9686 3.72156V22.6633C40.9686 24.5581 39.4299 26.0997 37.5386 26.0997H30.7267L33.0566 28.6657C33.8719 29.5643 33.806 30.955 32.9091 31.7727C32.4885 32.1553 31.9599 32.3445 31.4331 32.3445C30.8365 32.3445 30.2416 32.1021 29.8083 31.6249L24.5387 25.82C23.998 25.224 23.8448 24.4115 24.0647 23.6933H16.1735L11.5599 31.2805C11.1468 31.9605 10.4237 32.3357 9.68307 32.3357C9.29409 32.3357 8.89985 32.2327 8.54337 32.0146C7.50815 31.3826 7.1802 30.0296 7.81064 28.9925L9.5698 26.0997H3.43008C1.53833 26.0997 0 24.5581 0 22.6633V3.72156Z'
          fill='#D9D9D9' />
      </mask>
      <g mask="url(#mask0_4104_738)">
        <g filter="url(#filter0_d_4104_738)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.43008 0.285156C1.53833 0.285156 0 1.8272 0 3.72156V22.6633C0 24.5581 1.53833 26.0997 3.43008 26.0997H9.5698L7.81064 28.9925C7.1802 30.0296 7.50815 31.3826 8.54337 32.0146C8.89985 32.2327 9.29409 32.3357 9.68307 32.3357C10.4237 32.3357 11.1468 31.9605 11.5599 31.2805L20.5563 16.4855C21.1872 15.4488 20.8592 14.095 19.824 13.4634C18.7888 12.8313 17.438 13.1603 16.8071 14.1975L11.977 22.1413H3.9512V4.24364H37.0174V22.1413H26.333C26.3167 22.1413 26.3009 22.1457 26.2847 22.1461C25.7179 22.1144 25.1388 22.3005 24.6862 22.713C23.7893 23.5307 23.7234 24.9214 24.5387 25.82L29.8083 31.6249C30.2416 32.1021 30.8365 32.3445 31.4331 32.3445C31.9599 32.3445 32.4885 32.1553 32.9091 31.7727C33.806 30.955 33.8719 29.5643 33.0566 28.6657L30.7267 26.0997H37.5386C39.4299 26.0997 40.9686 24.5581 40.9686 22.6633V3.72156C40.9686 1.8272 39.4299 0.285156 37.5386 0.285156H3.43008Z" fill="url(#paint1_linear_4104_738)"/>
        </g>
      </g>
      <defs>
        <filter id="filter0_d_4104_738" x="0" y="0.285156" width="42.6877" height="33.778" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dx="0.859466" dy="0.859466"/>
          <feGaussianBlur stdDeviation="0.429733"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.501961 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4104_738"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4104_738" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_4104_738" x1="-11.1087" y1="11.8838" x2="3.68996" y2="37.3896" gradientUnits="userSpaceOnUse">
          <stop stop-color="#585DE9"/>
          <stop offset="1" stop-color="#2E293A"/>
        </linearGradient>
        <linearGradient id="paint1_linear_4104_738" x1="-18.9045" y1="13.0371" x2="4.58174" y2="50.7646" gradientUnits="userSpaceOnUse">
          <stop stop-color="#70D1FE"/>
          <stop offset="1" stop-color="#8051DD"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ArtStoreIcon;