import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState, store } from '../store';
import * as ArtAPI from '../api/artAPI';
import { GetBannerParamsInterface } from '../../interfaces/content.interfaces';
import { revertAll, clearAllState } from '../actions';

export interface StateInterface {
  status: "idle" | "loading" | "failed";
  bannerData: object
}

const initialState: StateInterface = {
  status: "idle",
  bannerData: {}
};

export const fetchBanners = createAsyncThunk(
    "banners/fetchBanners",
    async (data: GetBannerParamsInterface) => {
        try {
          const apiKey: string = store.getState().user.apiToken;
          const response = await ArtAPI.getBanners(apiKey, data);
          return response.data;
        } catch (error) {
          console.log("ERROR: Getting Banners: ", error);
        }
    }
);

export const bannerSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchBanners.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchBanners.fulfilled, (state, action) => {
          state.status = "idle";

          if (action.payload) {
            state.bannerData = action.payload;
          }
        })
        .addCase(fetchBanners.rejected, (state) => {
          state.status = "failed";
        })
        .addCase(revertAll, () => initialState)
        .addCase(clearAllState, () => initialState);
  },
});

export const status = (state: RootState) => state.banners.status;
export const bannerData = (state: RootState) => state.banners.bannerData;
export default bannerSlice.reducer;
