import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState, store } from '../store';
import { featuredCollection } from '../api/collectionsAPI';
import {
  FeaturedCollectionParamsInterface,
  FeaturedCollectionResponseInterface,
} from '../../interfaces/collections.interface';
import { revertAll, clearAllState } from '../actions';

export interface StateInterface {
  
  featuredStatus: 'idle' | 'loading' | 'failed' | 'fulfilled';
  featuredMastersStatus: 'idle' | 'loading' | 'failed' | 'fulfilled';
  featuredMuseumsStatus: 'idle' | 'loading' | 'failed' | 'fulfilled';
  featuredContemporaryArtistsStatus: 'idle' | 'loading' | 'failed' | 'fulfilled';

  featuredCollectionData: FeaturedCollectionResponseInterface[];
  featuredMastersCollectionData: FeaturedCollectionResponseInterface[];
  featuredMuseumsCollectionData: FeaturedCollectionResponseInterface[];
  featuredContemporaryArtistsCollData: FeaturedCollectionResponseInterface[];
  featuredCollectionURL: string;
}

const initialState: StateInterface = {
  featuredStatus: 'idle',
  featuredMastersStatus: 'idle',
  featuredMuseumsStatus: 'idle',
  featuredContemporaryArtistsStatus: 'idle',

  featuredCollectionData: [],
  featuredMastersCollectionData: [],
  featuredMuseumsCollectionData: [],
  featuredContemporaryArtistsCollData: [],
  featuredCollectionURL: ''
};

export const fetchFeaturedCollections = createAsyncThunk(
  'content/featured-collections',
  async (params: FeaturedCollectionParamsInterface) => {
    try {
      const apiKey: string = store.getState().user.apiToken;
      const response = await featuredCollection(apiKey, params);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  }
);

export const featuredCollectionSlice = createSlice({
  name: 'featuredCollection',
  initialState,
  reducers: {
    setFeaturedCollectionURL: (state, action) => {
      if (action.payload) {
        state.featuredCollectionURL = action.payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeaturedCollections.pending, (state, action) => {
        
        if (action && action.meta && action.meta.arg) {
          if (action.meta.arg.collectionType === 'featured_collection') {
            state.featuredStatus = 'loading';
          } else if (action.meta.arg.collectionType === 'masters_collection') {
            state.featuredMastersStatus = 'loading';
          } else if (action.meta.arg.collectionType === 'museums_collection') {
            state.featuredMuseumsStatus = 'loading';
          } else if (action.meta.arg.collectionType === 'contemporary_artists') {
            state.featuredContemporaryArtistsStatus = 'loading';
          }
        }
      })
      .addCase(fetchFeaturedCollections.fulfilled, (state, { payload }) => {

        if (payload && Array.isArray(payload.collectionData)) {
            if (payload.collectionType === 'featured_collection') {
              state.featuredStatus = 'fulfilled';
              state.featuredCollectionData = payload.collectionData;
            } else if (payload.collectionType === 'masters_collection') {
              state.featuredMastersStatus = 'fulfilled';
              state.featuredMastersCollectionData = payload.collectionData;
            } else if (payload.collectionType === 'museums_collection') {
              state.featuredMuseumsStatus = 'fulfilled';
              state.featuredMuseumsCollectionData = payload.collectionData;
            } else if (payload.collectionType === 'contemporary_artists') {
              state.featuredContemporaryArtistsCollData = payload.collectionData;
              state.featuredContemporaryArtistsStatus = 'fulfilled';
            }
        }
      })
      .addCase(fetchFeaturedCollections.rejected, (state, action) => {
        if (action && action.meta && action.meta.arg) {
          if (action.meta.arg.collectionType === 'featured_collection') {
            state.featuredStatus = 'failed';
          } else if (action.meta.arg.collectionType === 'masters_collection') {
            state.featuredMastersStatus = 'failed';
          } else if (action.meta.arg.collectionType === 'museums_collection') {
            state.featuredMuseumsStatus = 'failed';
          } else if (action.meta.arg.collectionType === 'contemporary_artists') {
            state.featuredContemporaryArtistsStatus = 'failed';
          }
        }
      })
      .addCase(revertAll, () => initialState)
      .addCase(clearAllState, () => initialState);
  },
});


export const featuredStatus = (state: RootState) => state.featuredCollection.featuredStatus;
export const featuredMastersStatus = (state: RootState) => state.featuredCollection.featuredMastersStatus;
export const featuredMuseumsStatus = (state: RootState) => state.featuredCollection.featuredMuseumsStatus;
export const featuredContemporaryArtistsStatus = (state: RootState) => state.featuredCollection.featuredContemporaryArtistsStatus;

export const featuredCollectionData = (state: RootState) =>
  state.featuredCollection.featuredCollectionData;
export const featuredMastersCollectionData = (state: RootState) =>
  state.featuredCollection.featuredMastersCollectionData;
export const featuredMuseumsCollectionData = (state: RootState) =>
  state.featuredCollection.featuredMuseumsCollectionData;
export const featuredContemporaryArtistsCollData = (state: RootState) =>
  state.featuredCollection.featuredContemporaryArtistsCollData;

export const { setFeaturedCollectionURL } = featuredCollectionSlice.actions;
export const featuredCollectionURL = (state: RootState) => state.featuredCollection.featuredCollectionURL;
export default featuredCollectionSlice.reducer;
