import axios from 'axios';
import { AxiosPromise } from 'axios';
import Config from '../../config/Config';
import { store } from '../store';
import { samsungAccountAPIService } from './apiUtil';

const artAPIService = axios.create();
const basePath = `${Config().baseUrl}/${Config().appPrefix}/${
  Config().apiVersion
}`;

export const signIn = (password: string): AxiosPromise => {
  const data = JSON.stringify({ password });

  const config = {
    method: 'post',
    url: `${basePath}/auth/login`,
    headers: {
      'Content-Type': 'application/json',
      'clientkey': Config().clientKey,
    },
    data: data,
  };

  return artAPIService(config);
};

export const configDetails = (): AxiosPromise => {

  const config = {
    method: 'get',
    url: `${basePath}/auth/config`,
    headers: {
      'Content-Type': 'application/json',
      'clientkey': Config().clientKey,
    }
  };

  return artAPIService(config);
};

export const getTokens = (dataInput: any): AxiosPromise<any> => {
  const data = JSON.stringify({ 
    saCode: dataInput.code, 
    redirectUrl: dataInput.redirectUrl 
  });

  const config = {
    method: 'post',
    url: `${basePath}/samsungaccount/user-tokens`,
    headers: {
      'Content-Type': 'application/json',
      'clientkey': Config().clientKey,
      'Access-Control-Allow-Credentials' : 'true',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'X-Requested-With, X-Auth-Token, Content-Type, Content-Length, Authorization, Access-Control-Allow-Headers, Accept, Access-Control-Allow-Methods, Access-Control-Allow-Origin, Access-Control-Allow-Credentials',
      'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
    data: data,
  };

  return artAPIService(config);
};

export const verifySamsungAccountToken = (authToken: string): AxiosPromise => {
  const apiKey: string = store.getState().user.apiToken;
  const jwtToken = Buffer.from(authToken, 'base64').toString();

  const config = {
    method: 'get',
    url: `${basePath}/auth/verifySAToken`,
    headers: {
      'Content-Type': 'application/json',
      apiKey,
      Authorization: `Bearer ${jwtToken}`
    },
  };

  return samsungAccountAPIService(config);
};

export const getRefreshToken = (authToken: string): AxiosPromise => {
  const apiKey: string = store.getState().user.apiToken;
  const jwtToken = Buffer.from(authToken, 'base64').toString();

  const config = {
    method: 'get',
    url: `${basePath}/samsungaccount/refresh-token`,
    headers: {
      'Content-Type': 'application/json',
      apiKey,
      Authorization: `Bearer ${jwtToken}`
    },
  };

  return artAPIService(config);
};