import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState, store } from '../store';
import * as BrazeEmailSubscriptionAPI from '../api/brazeEmailSubscriptionAPI';
import { revertAll, clearAllState } from '../actions';

export interface StateInterface {
  status: 'idle' | 'loading' | 'failed';
  apiResponse: object;
}

const initialState: StateInterface = {
  status: 'idle',
  apiResponse: {},
};

export const subscibeEmail = createAsyncThunk(
  '/braze/subscribe',
  async (clientEmail: string) => {
    try {
      const apiKey: string = store.getState().user.apiToken;

      const response = await BrazeEmailSubscriptionAPI.emailSubscriptionAPI(
        apiKey,
        clientEmail
      );

      console.log({ emailResponse: response.data });

      return response.data;
    } catch (err) {
      console.error(err);
    }
  }
);

export const emailSubscriptionSlice = createSlice({
  name: 'emailSubscription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(subscibeEmail.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(subscibeEmail.fulfilled, (state, action) => {
        state.status = 'idle';

        if (action.payload) {
          state.apiResponse = action.payload;
        }
      })
      .addCase(subscibeEmail.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(revertAll, () => initialState)
      .addCase(clearAllState, () => initialState);
  },
});

export const status = (state: RootState) => state.emailSubscription.status;
export const apiResponse = (state: RootState) =>
  state.emailSubscription.apiResponse;
export default emailSubscriptionSlice.reducer;
