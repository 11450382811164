import React from 'react';

const ChevronBlackIcon = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.73438 8.4924L6.97521 5.29698C7.14062 5.13365 7.14062 4.86656 6.97521 4.70365L3.73438 1.50781" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default ChevronBlackIcon;