import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface ScrollAnchor {
  id: string;
  children: any;
}
export default function ScrollAnchor({ id, children }: ScrollAnchor) {
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const getHash = () => {
    return decodeURI(window.location.hash.slice(1));
  };

  const scrollToElement = (hash?: string) => {
    const element = ref.current;
    if (typeof window === 'undefined' || !element || id !== (hash || getHash()))
      return;

    window.requestAnimationFrame(() => {
      let offset = element.offsetTop;
      try {
        let bodyRect = document.body.getBoundingClientRect();
        let bodyStyle = window.getComputedStyle(document.body, null);

        let paddingTop = parseFloat(bodyStyle.getPropertyValue('padding-top'));
        let elementRect = element.getBoundingClientRect();

        const offsetStickyHeader = 53.5;
        offset =
          elementRect.top - paddingTop - bodyRect.top - offsetStickyHeader;
      } catch {}

      window.scrollTo({ top: offset, left: 0, behavior: 'smooth' });
    });
  };

  const clearHash = () => {
    if (getHash()) {
      navigate(location.pathname + location.search, { replace: true });
    }
  }

  useEffect(() => {
    const hash = getHash();
    setTimeout(() => {
      scrollToElement(hash);
    }, 1000);

    window.addEventListener('hashchange', () => scrollToElement());
    window.addEventListener('scroll', clearHash);

    return () => {
      window.removeEventListener('hashchange', () => scrollToElement());
      window.removeEventListener('scroll', clearHash);
    }
  }, []);
  return <div ref={ref}>{children}</div>;
}
