import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { useState, useEffect } from 'react';
import constants from '../../../config/Constants';
import styles from './IosEmailConfirmationHandRaiser.module.css';

const customStyles = {
  dialogTitleSX: {
    fontFamily: 'SamsungOne !important',
    fontWeight: 700,
    fontSize: 14,
    fontStyle: 'normal',
    paddingTop: '32px',
  },
  contentTitleSpan: {
    font: 'SamsungOne',
    fontSize: 22,
    fontWeight: '700',
  },
  contentTextSpan: {
    fontSize: 12,
    font: 'SamsungOne',
    fontWeight: '400',
    color: 'black',
  },
  contentImage: {
    height: 13,
    width: 11,
    verticalAlign: 'middle',
  },
};

type IosEmailConfirmationHandRaiserInterface = {
  showIosEmailConfirmationDialog?: boolean;
  setShowIosEmailConfirmationDialog?: Function;
  handleIosEmailConfirmationClose: () => void;
};

export default function IosEmailConfirmationHandRaiser(
  props: IosEmailConfirmationHandRaiserInterface
) {
  const {
    showIosEmailConfirmationDialog = false,
    setShowIosEmailConfirmationDialog,
  } = props;
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
    if (setShowIosEmailConfirmationDialog) {
      setShowIosEmailConfirmationDialog(!showIosEmailConfirmationDialog);
    }
    props.handleIosEmailConfirmationClose();
  };

  return (
    <Box>
      <Dialog
        classes={{ paper: styles.dialogPaper }}
        open={showIosEmailConfirmationDialog || openDialog}
        onClose={handleClose}
        aria-labelledby="email-confirmation-dialog-title"
        aria-describedby="email-confirmation-dialog-description"
        sx={{ textAlign: 'center' }}
      >
        <DialogTitle
          id="email-confirmation-dialog-title"
          sx={customStyles.dialogTitleSX}
        >
          {t('miscIOSEmailThankYou')}
        </DialogTitle>

        <DialogContent>
          <span style={customStyles.contentTitleSpan}>
           {t('miscIOSEmailAddArtStore')}
          </span>
          <div
            className={styles.contentText}
            style={customStyles.contentTextSpan}
          >
            <span>{t('miscIOSEmailInSafari')}</span>
            <span>
              &nbsp;
              <img
                style={customStyles.contentImage}
                src="/images/safari_icon.svg"
                alt={'safari icon'}
              />
              &nbsp;
            </span>
            <span>{t('miscIOSEmailAddHome')}</span>
          </div>
        </DialogContent>

        <DialogActions className={styles.btnWrapper}>
          <Button
            onClick={handleClose}
            sx={{ color: constants.colors.primary }}
            className={styles.btn}
          >
             {t('miscOk')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
