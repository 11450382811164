import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState, store } from '../store';
import { searchCollectionContent } from '../api/collectionsAPI';
import { SearchCollectionContentParamsInterface } from '../../interfaces/collections.interface';
import { clearAllState, revertAll } from '../actions';

export interface StateInterface {
  status: 'idle' | 'loading' | 'failed' | 'fulfilled';
  searchedCollectionData: any;
}

const initialState: StateInterface = {
  status: 'idle',
  searchedCollectionData: {}
};

export const fetchSearchedCollectionContent = createAsyncThunk(
  'content/collection-content-search',
  async (params: SearchCollectionContentParamsInterface) => {
    try {
      const apiKey: string = store.getState().user.apiToken;
      const response = await searchCollectionContent(apiKey, params);

      return response.data;
    } catch (e) {
      console.error(e);
    }
  }
);

export const searchCollectionContentSlice = createSlice({
  name: 'searchCollectionContent',
  initialState,
  reducers: {
    clearSearchedCollectionData: (state) => {
      state.searchedCollectionData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchedCollectionContent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSearchedCollectionContent.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.searchedCollectionData = action.payload.length ? action.payload : [];
      })
      .addCase(fetchSearchedCollectionContent.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(revertAll, () => initialState)
      .addCase(clearAllState, () => initialState);
  },
});

export const { clearSearchedCollectionData } = searchCollectionContentSlice.actions;
export const status = (state: RootState) => state.searchCollectionContent.status;
export const searchedCollectionContentData = (state: RootState) =>
  state.searchCollectionContent.searchedCollectionData;
export default searchCollectionContentSlice.reducer;
