import axios, { AxiosPromise } from 'axios';
import Config from '../../config/Config';

const axiosObject = axios.create();
const basePath = `${Config().baseUrl}/${Config().appPrefix}/${
  Config().apiVersion
}`;

export const logClientError = (
  apiKey: string,
  errorMessage: string
): AxiosPromise => {
  const data = JSON.stringify({ errorMessage });

  const config = {
    method: 'post',
    url: `${basePath}/client/log-error`,
    headers: { 'Content-Type': 'application/json', apiKey },
    data
  };

  return axiosObject(config);
};