import React, { useEffect, useState } from 'react';
import styles from './CollectionDetailPage.module.css';
import { useTranslation } from 'react-i18next';
import './styles.css';
import Logo from '../../components/logo/Logo';
import { Box, Snackbar, Typography } from '@mui/material';
import {
  CollectionContentDetailsParamsInterface,
} from '../../interfaces/collections.interface';
import {
  collectionContentDetailsData,
  fetchCollectionContentDetails,
  status
} from '../../redux/reducers/collectionContentDetailsSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Link, useLocation } from 'react-router-dom';
import {
  isUserLoggedIn,
  isUserSALoggedIn,
  setRedirectUrl,
  setSelectedContent,
  selectedContent,
  clearSelectedContent,
  setCurrentParams,
  setPreviousPath,
  isSubscribedUser,
} from '../../redux/reducers/userSlice';
import Loading from '../../components/Loading';
import { CollectionDetailPageStatusEnum } from './CollectionDetailPageStatus.enum';
import { RWebShare } from "react-web-share";
import Footer from '../../components/footer/Footer';
import Skeleton from '@mui/material/Skeleton';
import ShareButton from '../../components/shareButton/ShareButton';
import Common, { disableRightClick, loadImage } from '../../utils/Common';
import CustomDialog from '../../components/dialogs/CustomDialog';
import {
  fetchFavorites,
  saveFavorites,
  favoritesData,
  resetFavoriteSavedStatus,
  resetFavoriteListSavedStatus,
  resetFavoritesData,
  favoriteListSavedStatus,
  favoriteSavedStatus,
  status as favoriteApiStatus
} from '../../redux/reducers/contentStoreSlice';
import { getParameterByName } from '../../redux/api/apiUtil';
import { logClientError } from '../../redux/reducers/clientSlice';
import Config from '../../config/Config';
import FavoriteCheckedIcon from '../../components/icons/favoriteCheckedIcon';
import FavoriteUncheckedIcon from '../../components/icons/favoriteUncheckedIcon';
import ChevronBlackIcon from '../../components/icons/chevronBlackIcon';
import SignInTooltipCta from '../../components/signInTooltipCta/signInTooltipCta';

const CollectionDetailPage = () => {
  const { updateAndGetQueryParams } = Common();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  let collectionDetailShareablePath = window.location.origin + location.pathname + location.search;
  const contentDetailsData = useAppSelector(collectionContentDetailsData);
  const useCollectionDetailsStatus = useAppSelector(status);
  const favoriteStatus = useAppSelector(favoriteSavedStatus);
  const favoriteListStatus = useAppSelector(favoriteListSavedStatus);
  const favoriteAPIStatus = useAppSelector(favoriteApiStatus);
  const isLoggedIn = useAppSelector(isUserLoggedIn);
  const isSALoggedIn = useAppSelector(isUserSALoggedIn);
  const selectedFavoriteContent = useAppSelector(selectedContent);
  const [imageDimensions, setImageDimensions]: any = useState({});
  const [openLoginDialog, setOpenLoginDialog] = React.useState(false);
  const favoritesList = useAppSelector(favoritesData);
  const isSubscribed = useAppSelector(isSubscribedUser);
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [collectionId, setCollectionId] = useState('');
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [afterSignIn, setAfterSignIn] = useState(false);
  const [selectedContentData, setSelectedContentData] = useState({
    id: '',
    name: ''
  });
  const isBlockedImage: boolean = contentDetailsData.marketingExcluded && !isSubscribed;

  useEffect(() => {
    if (getParameterByName('code')) {
      setAfterSignIn(true);
    }
  }, []);

  useEffect(() => {
    if (!contentDetailsData.sixteenByNine && contentDetailsData.imageHighResUrl) {
      loadImage(setImageDimensions, contentDetailsData.imageHighResUrl);
    }
  }, [contentDetailsData]);

  useEffect(() => {
    const { contentId } = updateAndGetQueryParams.detailsPage();
    setCollectionId(contentId)

    const params: CollectionContentDetailsParamsInterface = {
      country: 'US',
      languageCode: 'US',
      year: '2022',
      model: '22_PONTUSM_FTV',
      contentId
    };
  
    if (isLoggedIn) {
      try {
        isInvalidData(contentId) && dispatch(fetchCollectionContentDetails(params));
      } catch (e) {
        dispatch(fetchCollectionContentDetails(params));
        dispatch(logClientError(`COLLECTION DETAILS: ${e.message}`));
      }
      dispatch(resetFavoriteListSavedStatus());
    }
    dispatch(setRedirectUrl('collection/details'));

    window.scrollTo(0, 0); // Put the top of the page into view at load time
  }, [isLoggedIn]);

  useEffect(() => {
    if (isSALoggedIn) {
      if (selectedFavoriteContent && selectedFavoriteContent.id) {
        setFavoriteContent(selectedFavoriteContent.id);
        setSelectedContentData(selectedFavoriteContent);
        dispatch(clearSelectedContent());
      }

      try {
        if (
          !favoritesList ||
          !favoritesList.content ||
          !favoritesList.content.length
        ) {
          fetchFavoritesData();
        }
      } catch (e) {
        fetchFavoritesData();
        dispatch(logClientError(`COLLECTION DETAILS | FAVORITES: ${e.message}`));
      }
    } else {
      dispatch(resetFavoritesData());
    }

  }, [isSALoggedIn]);

  useEffect(() => {
    if (favoriteStatus === 'saved' || favoriteStatus.includes('exist')) {
      if (favoriteStatus.includes('exist')) {
        fetchFavoritesData();
        if (favoriteStatus === 'already-exist') {
          setSnackBarMessage('Added to ‘My Mobile Artworks’ row inside Samsung Art Store on The Frame TV');
        } else if (favoriteStatus === 'not-exist') {
          setSnackBarMessage('Removed from ‘My Mobile Artworks’ row inside Samsung Art Store on The Frame TV');
        }
      }
      if (afterSignIn &&
        (
          (selectedFavoriteContent && selectedFavoriteContent.name) ||
          (selectedContentData && selectedContentData.name)
        )
      ) {
        setOpenConfirmDialog(true);
        setAfterSignIn(false);
      } else {
        setShowSnackbar(true);
        dispatch(resetFavoriteSavedStatus());
        setAfterSignIn(false);
      }
    } else if (favoriteStatus === 'invalid-token') {
      setOpenLoginDialog(true); // open login dialog
      dispatch(setRedirectUrl('collection/details'));
      dispatch(resetFavoriteSavedStatus());
    } else if (favoriteStatus === 'failed') {
      setSnackBarMessage('Something went wrong! Please try again later')
      setShowSnackbar(true);
      dispatch(resetFavoriteSavedStatus());
    }
  }, [favoriteStatus]);

  useEffect(() => {
    if (favoriteAPIStatus !== 'loading') {
      setIsLoading(false);
    }
  }, [favoriteAPIStatus]);

  const buttonPendingStatus = (contentId: string) => {
    let index = favoriteListStatus && favoriteListStatus.findIndex((item: any) => item.content_id === contentId && item.requestStatus === 'pending')
    if (index >= 0) {
      return true;
    } else {
      return false;
    }
  }

  const setFavoriteContent = (contentId: string) => {
    setIsLoading(true);
    let exist = findFavorite(contentId);

    if (!exist) {
      setSnackBarMessage('Added to ‘My Mobile Artworks’ row inside Samsung Art Store on The Frame TV');
    } else {
      setSnackBarMessage('Removed from ‘My Mobile Artworks’ row inside Samsung Art Store on The Frame TV');
    }

    let data = {
      country: 'US',
      favorites: {
        content_id: contentId,
        favourite: exist ? 'NO' : 'YES',
        collection_name: 'My Own List',
      }
    }
    dispatch(resetFavoriteSavedStatus());
    Config().favoriteFeatureFlag && dispatch(saveFavorites(data));
  }

  const findFavorite = (contentId: string) => {
    let index = favoritesList && favoritesList.content && favoritesList.content.findIndex((item: any) => item.content_id === contentId)
    if (index >= 0) {
      return true;
    } else {
      return false;
    }
  }

  const favoriteHandler = (artName: any) => {
    const selectedContent = {
      id: collectionId,
      name: artName
    }
    setSelectedContentData(selectedContent);
    if (isSALoggedIn) {
      setFavoriteContent(collectionId);
    } else {
      dispatch(setSelectedContent(selectedContent));
      setOpenLoginDialog(true);
    }
  }

  const isInvalidData = (contentId: string) => {
    return !contentDetailsData || !Object.keys(contentDetailsData).length ||
      (
        Object.keys(contentDetailsData).length &&
        contentDetailsData.id !== contentId
      );
  }

  const fetchFavoritesData = () => {
    const params: any = {
      country: 'US',
    };
    Config().favoriteFeatureFlag && dispatch(fetchFavorites(params.country));
  }

  const resetConfirmDialog = () => {
    dispatch(resetFavoriteSavedStatus());
    dispatch(clearSelectedContent());
    setShowSnackbar(false);
    setOpenConfirmDialog(false);
  }

  const resetDialog = () => {
    setOpenLoginDialog(false);
  }

  const onClickHandler = (contentId: string) => {
    const searchParams = `?id=${contentId}`;
    dispatch(setCurrentParams(searchParams));
    dispatch(setPreviousPath({path: location.pathname + location.search, type: 'details' }));
  }

  return !isLoggedIn ? (
    <Loading />
  ) : (
    <>
      {/*{isLoading && <Loading />}*/}
      <div>
        <Logo borderBottom={false} backArrow={true} removeHomeNavigateLink={false}/>
        <main style={{ overflow: 'hidden' }} className={contentDetailsData.sixteenByNine ? styles.sixteenByNine : styles.nineBySixteen}>
          <div className={useCollectionDetailsStatus === CollectionDetailPageStatusEnum.LOADING ? '' : styles.contentBody}>
            <Box className={styles.container}>
            {useCollectionDetailsStatus === CollectionDetailPageStatusEnum.LOADING && (
              <>
                <Skeleton variant="rectangular" height={400} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="rectangular" height={60} />
              </>
            )}

            {useCollectionDetailsStatus === CollectionDetailPageStatusEnum.FULFILLED &&
              (contentDetailsData && contentDetailsData.artistName ? (
                  <>
                    <div className={contentDetailsData.sixteenByNine ? styles.sixteenByNineContainer : styles.nineBySixteenSmallContainer}>
                      <Box className={contentDetailsData.sixteenByNine ? styles.sixteenByNine : styles.nineBySixteen}>
                        <Box className={styles.imgContainer}>
                          <SignInTooltipCta disableHover={!isBlockedImage}>
                            <img
                              style={{ aspectRatio: contentDetailsData.sixteenByNine ? '16/9' : '' }}
                              src={
                                isBlockedImage
                                  ? contentDetailsData.sixteenByNine
                                    ? '/images/image_excluded_placeholder_horizontal.png'
                                    : '/images/image_excluded_placeholder_vertical.png'
                                  : contentDetailsData.imageHighResUrl ? contentDetailsData.imageHighResUrl : contentDetailsData.imageUrl ||
                                  '/images/image_error_placeholder.svg'
                              }
                              alt={'Collection Image'}
                              onContextMenu={disableRightClick}
                            />
                          </SignInTooltipCta>
                        </Box>
                      </Box>
                      <div className={styles.containerWrapper}>
                        <div>
                          <div className={styles.titleAndIconSectionWrapper}>
                            <div className={styles.titleSectionWrapper}>
                              <div className={styles.titleSection}>
                                <div className={styles.row}>
                                  <div className={styles.titleColumn}>
                                    <div className={styles.title}>
                                      <Typography variant={'body1'} className={styles.heading}>
                                        {contentDetailsData.artWorkName}
                                      </Typography>
                                    </div>
                                  </div>
                                  {/*<div className={styles.iconColumn}>*/}
                                  {/*  <div className={styles.centerDiv}>*/}
                                  {/*    <RWebShare*/}
                                  {/*      data={{*/}
                                  {/*        text: contentDetailsData.artWorkName,*/}
                                  {/*        url: collectionDetailShareablePath,*/}
                                  {/*        title: contentDetailsData.artWorkName,*/}
                                  {/*      }}*/}
                                  {/*      onClick={() => console.log("shared successfully!")}*/}
                                  {/*    >*/}
                                  {/*      <span className={styles.icon}>*/}
                                  {/*        <ShareButton/>*/}
                                  {/*      </span>*/}
                                  {/*    </RWebShare>*/}
                                  {/*  </div>*/}
                                  {/*</div>*/}
                                </div>
                              </div>
                              <div className={styles.subtitleSection}>
                                {contentDetailsData.artistId ? (
                                  <Link
                                    to={`/collections/artist?id=${contentDetailsData.artistId}`}
                                    onClick={() => onClickHandler(contentDetailsData.artistId)}
                                    className={styles.link}
                                  >
                                    <Typography variant={'body2'} className={styles.subHeading}>
                                      {contentDetailsData.artistName}
                                      <span className={styles.chevronIcon}>
                                        <ChevronBlackIcon />
                                      </span>
                                    </Typography>
                                  </Link>
                                ) : (
                                  <Typography variant={'body2'} className={styles.subHeading}>
                                    {contentDetailsData.artistName}
                                  </Typography>
                                )}
                              </div>
                            </div>
                            {Config().favoriteFeatureFlag
                              ? buttonPendingStatus(collectionId)
                                ? (
                                  <span className={styles.favoriteIcon}>
                                    {findFavorite(collectionId) ? <FavoriteCheckedIcon disabled={true} /> : <FavoriteUncheckedIcon disabled={true} />}
                                  </span>
                                )
                                : (
                                  <span className={styles.favoriteIcon} onClick={() => favoriteHandler(contentDetailsData.artWorkName)}>
                                    {findFavorite(collectionId) ? <FavoriteCheckedIcon disabled={false} /> : <FavoriteUncheckedIcon disabled={false} />}
                                  </span>
                                )
                              : ''
                            }
                          </div>
                          <div className={styles.detailsSection}>
                            {contentDetailsData.imageDescription.split('\n').map((item: any, key: any) => {
                              return <Typography style={{ textAlign: 'justify'}} key={key} variant="body2" className={styles.description}>{item}<br/></Typography>
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={contentDetailsData.sixteenByNine ? styles.nineBySixteenContainer : styles.nineBySixteenLargeContainer}>
                      <Box className={contentDetailsData.sixteenByNine ? styles.sixteenByNine : styles.nineBySixteen}>
                        <Box className={styles.imgContainer} style={{ height: `${imageDimensions.height}px`, width: `${imageDimensions.width}px` }}>
                          <SignInTooltipCta disableHover={!isBlockedImage}>
                            <img
                              src={
                                isBlockedImage
                                  ? contentDetailsData.sixteenByNine
                                    ? '/images/image_excluded_placeholder_horizontal.png'
                                    : '/images/image_excluded_placeholder_vertical.png'
                                  : contentDetailsData.imageHighResUrl ? contentDetailsData.imageHighResUrl : contentDetailsData.imageUrl ||
                                  '/images/image_error_placeholder.svg'
                              }
                              alt={'Collection Image'}
                              onContextMenu={disableRightClick}
                            />
                          </SignInTooltipCta>
                        </Box>
                      </Box>
                      <div className={styles.detailsContainerWrapper}>
                        <div>
                          {/*<div className={styles.iconSection}>*/}
                          {/*  <RWebShare*/}
                          {/*    data={{*/}
                          {/*      text: contentDetailsData.artWorkName,*/}
                          {/*      url: collectionDetailShareablePath,*/}
                          {/*      title: contentDetailsData.artWorkName,*/}
                          {/*    }}*/}
                          {/*    onClick={() => console.log("shared successfully!")}*/}
                          {/*  >*/}
                          {/*    <span>*/}
                          {/*      <ShareButton/>*/}
                          {/*    </span>*/}
                          {/*  </RWebShare>*/}
                          {/*</div>*/}
                          <div className={styles.titleSection}>
                            <div className={styles.title}>
                              <Typography variant={'body1'} className={styles.heading}>
                                {contentDetailsData.artWorkName}
                              </Typography>
                            </div>
                          </div>
                          <div className={styles.subtitleSection}>
                            {contentDetailsData.artistId ? (
                              <Link
                                to={`/collections/artist?id=${contentDetailsData.artistId}`}
                                onClick={() => onClickHandler(contentDetailsData.artistId)}
                                className={styles.link}
                              >
                                <Typography variant={'body2'} className={styles.subHeading}>
                                  {contentDetailsData.artistName}
                                  <span className={styles.chevronIcon}>
                                    <ChevronBlackIcon />
                                  </span>
                                </Typography>
                              </Link>
                            ) : (
                              <Typography variant={'body2'} className={styles.subHeading}>
                                {contentDetailsData.artistName}
                              </Typography>
                            )}
                          </div>
                          {Config().favoriteFeatureFlag
                            ? buttonPendingStatus(collectionId)
                              ? (
                                <div className={styles.favoriteIcon}>
                                  {findFavorite(collectionId) ? <FavoriteCheckedIcon disabled={true} /> : <FavoriteUncheckedIcon disabled={true} />}
                                </div>
                              )
                              : (
                                <div className={styles.favoriteIcon} onClick={() => favoriteHandler(contentDetailsData.artWorkName)}>
                                  {findFavorite(collectionId) ? <FavoriteCheckedIcon disabled={false} /> : <FavoriteUncheckedIcon disabled={false} />}
                                </div>
                              )
                            : ''
                         }
                          <div className={styles.detailsSection}>
                            {contentDetailsData.imageDescription.split('\n').map((item: any, key: any) => {
                              return <Typography style={{ textAlign: 'justify'}} key={key} variant="body2" className={styles.description}>{item}<br/></Typography>
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <Typography variant="h4" className={styles.header}>
                      {t('miscNoArtwork')}
                    </Typography>
                    <Box className={styles.noImgContainer}>
                      <img
                        src={'/images/image_error_placeholder.svg'}
                        alt={'image placeholder'}
                        onContextMenu={disableRightClick}
                      />
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{ marginTop: '1rem', marginBottom: '5rem' }}
                    >
                      {t('miscArtworkNotAvailable')}
                    </Typography>
                  </>
                )
              )}
          </Box>
          </div>
        </main>
        <Footer/>
        {openLoginDialog && <CustomDialog displayDialog={openLoginDialog} data={{}} resetDisplayDialogStatus={resetDialog} dialogType='login' />}
        {openConfirmDialog && <CustomDialog displayDialog={openConfirmDialog} data={{title: selectedContentData && selectedContentData.name, exist: findFavorite(selectedContentData.id)}} resetDisplayDialogStatus={resetConfirmDialog} dialogType='confirm' />}
        {showSnackbar && snackBarMessage && (
          <Snackbar
            sx={{
              '.MuiPaper-root': {
                background: '#1D1D2F',
                textAlign: 'center',
              }
            }}
            open={showSnackbar}
            onClose={resetConfirmDialog}
            autoHideDuration={4000}
            message={snackBarMessage}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          />
        )}
      </div>
    </>
  );
};

export default CollectionDetailPage;