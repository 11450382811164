import React from 'react';
import { Typography } from '@mui/material';
import styles from './styles.module.css';
import CopyToClipboardButton from '../copyToClipboardButton/CopyToClipboardButton';
import { useTranslation } from 'react-i18next';
import { CollectionPageStatusEnum } from './CollectionPageType.enum';

type PropsInterface = {
  name?: string;
  info?: string;
  collectionShareablePath?: string;
  pageType: string;
}

const TitleSection = (props: PropsInterface) => {
  const { name, info, collectionShareablePath, pageType } = props;
  const { t } = useTranslation();

  if (pageType === CollectionPageStatusEnum.SEARCH) {
    return (
      <Typography variant="body1" className={styles.searchHeader}>
        {t('miscRelatedArtworks')}
      </Typography>
    )
  } else {
    return (
      <>
        <Typography variant="h4" className={styles.header}>
          {name}
        </Typography>
        <div>
          {info && (
            <div className={styles.subHeadingWrapper}>
              {info.split('\n').map((item: any, key: any) => {
                return (
                  <Typography
                    style={{ textAlign: 'justify' }}
                    key={key} variant='body1'
                    className={styles.subHeading}
                  >
                    {item}<br />
                  </Typography>);
              })}
            </div>
          )}
          <div style={{ textAlign: 'left' }}>
            <CopyToClipboardButton content={collectionShareablePath} />
          </div>
        </div>
      </>
    );
  }
};

export default TitleSection;