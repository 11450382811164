import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import Contants from '../../config/Constants';
import styles from './LandingPageAnimation.module.css';
import { disableLongPress } from '../../utils/Common';
import CustomCarouselSlider from './animation/CustomCarouselSlider';

export default function LandingPageAnimation() {
  return (
    <div onContextMenu={disableLongPress} className={styles.container}>
      <BrowserView>
        <CustomCarouselSlider screenType={Contants.screenType.desktop} />
      </BrowserView>
      <MobileView>
        <CustomCarouselSlider screenType={Contants.screenType.mobile} />
      </MobileView>
    </div>
  );
}
