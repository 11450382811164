export const translations = {
  en: {
    translation: {
      'miscLoading': 'Loading...',

      'miscLandingPageMainTitle1': 'Find the perfect art',
      'miscLandingPageMainTitle2': 'for your Frame',
      'miscLandingPageMainSubTitle': 'Expertly curated, high-definition artwork from the world\'s top collections',

      'miscLandingPageFeatured': 'Featured Collection',
      'miscLandingPageMasters': 'Masters',
      'miscLandingPageMuseums': 'Museums',
      'miscLandingPageContempArtist': 'Contemporary Artists',

      'miscLandingPageFrameTV': 'Transform your home with a Frame TV',
      'miscLandingPageQLED': 'QLED 4K technology with a premium matte display to showcase your art.',
      'miscLandingPageBuynow': 'Buy now',
      'miscLandingPageMeetTheArtist': 'Meet the Artist',

      'miscNewsletterComingToArt': 'Find out what\’s coming to the Art Store',
      'miscNewsletterSubscribe': 'Subscribe to our newsletter',
      'miscSubscribe': 'Subscribe',
      'miscNewsletterPrivacy': 'See Samsung\'s Privacy Policy',

      'miscIsSubscribe': 'Join Membership',
      'miscMemberSince': 'Art Store Member',
      'miscMemberDescription': 'Manage your membership on The Frame TV',
      'miscSignOut': 'Log out',

      'miscToolTipBecomeMember': 'Become a member to see this image',
      'miscToolTipMembers': 'Members ',
      'miscToolTipSignIn': 'sign-in',
      'miscToolTipImage': ' to see this image',

      'miscCustomAlertAddArt': 'Add Art Store to your home screen.',
      'miscCustomAlertInSafari1': 'In Safari, tap ',
      'miscCustomAlertInSafari2': '   and select \'Add to Home Screen\'',
      'miscCustomAlertAddArtStore1': 'Add Art Store to your home screen. In Safari, tap ',
      'miscCustomAlertAddArtStore2': '   and select \'Add to Home Screen\'',

      'miscCopiedtoClipboard': 'Copied to clipboard',

      'miscIOSEmailThankYou': 'Thank you for signing up!',
      'miscIOSEmailAddArtStore': 'Add Art Store to your home screen',
      'miscIOSEmailInSafari': 'In Safari, tap',
      'miscIOSEmailAddHome': 'and select \'Add to Home Screen\'',

      'miscPushNotifAllowArt': 'Allow Art Store to send notifications?',
      'miscPushNotifBeFirst': 'Be the first to hear about new artists joining the art store, exclusive interviews with art insiders, seasonal curations, special offers and more.',
      'miscPushNotifNoThanks': 'No thanks',
      'miscPushNotifAllow': 'Allow',

      'miscHowToSubStartMembership': 'Start Your Membership on Your Frame TV',
      

      'miscIOSNotifPageNotExist': 'The page you are looking for does not exist.',
      'miscIOSNotifPageDeleted': 'It might have been moved or deleted.',
      'miscIOSNotifReqPushPermission': 'Request Push Permission',

      'miscBackToHome': 'Back to Home',
      'miscPageNotFound': 'Page Not Found',

      'miscEmailThankyou': 'Thank you for signing up!',
      'miscEmailFailed': 'Email subscription failed',
      'miscEmailOk': 'OK',
      'miscOk': 'OK',
      'miscYes': 'YES',
      'miscNo': 'NO',
      'miscCancel': 'Cancel',

      'miscTermsTitle': 'Terms and Conditions for the Samsung Art Store',
      'miscTermsPurpose': '1. Purpose',
      'miscTermsPurposeDesc': 'This Agreement sets out the rights and obligations of Samsung and you in regards to the Services provided through Samsung Art Store.',
      'miscTermsDefinitions': '2. Definitions',
      'miscTermsScope': '3. Scope',
      'miscTermsAcceptance': '4. Acceptance of this Agreement and Grant of License',
      'miscTermsPurchase': '5. Purchase of Content and Payment',
      'miscTermsCancellation': '6. Cancellation of Membership or Individual Purchase',
      'miscTermsContent': '7. Content Rights',
      'miscTermsChanges': '8. Changes to this Agreement; Notice of Changes',
      'miscTermsWarranties': '9. Warranties Disclaimer',
      'miscTermsLimitation': '10. Limitation of Liability',
      'miscTermsGeneral': '11. General',

      'miscSearchNoResults': 'No Results Found',
      'miscRelatedArtworks': 'Related Artworks',

      'miscNoArtwork': 'No Artwork',
      'miscArtworkNotAvailable': 'Artwork Not Available',
      'miscNoCollection': 'No Collection',
      'miscCollectionNotAvail': 'Collection Not Available',
      'miscViewCollection': 'View Collection',

      'miscFooterUseful': 'USEFUL LINKS',
      'miscFooterUseArtMode': 'How to use Art Mode',
      'miscFooterFrameTV': 'The Frame TV',
      'miscFooterReadMore': 'Read more on Samsung Newsroom',
      'miscFooterSearch': 'Search Artwork (beta)',
      'miscFooterNote': '2023 Samsung Electronics America, Inc. Samsung is a registered trademark of Samsung Electronics Co., Ltd. All rights reserved. All other brands, products and services, and their respective trademarks, names and logos are the property of their respective owners. This content is provided for entertainment and information purposes only. All information included herein is subject to change without notice. Use only in accordance with law. Screen images simulated; actual appearance may vary. The use of the Art Store is subject to these',
      'miscFooterTerms': 'terms and conditions.',
      'miscFooterPrivacy': 'Privacy',
      'miscFooterLegal': 'Legal',

      'errorTitle': 'Error',
      'error401': 'Oops! Unauthorized access.',
      'error401SubMessage': 'Looks like you are not authorized to access',

      'miscSignInBtnText': 'Sign in',
      'miscSignInTitle': 'Sign in to your Samsung account',
      'miscSignInDescription': 'Sign in through Samsung.com to unlock features on this web app'
    },
  },
  kr: {
    translation: {
      'miscLoading': 'Loading...',
      'miscFeaturedTitle': 'FEATURED COLLECTION',
    },
  },
};
