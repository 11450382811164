import React, { useState, useEffect } from 'react';

import { CircularProgress } from '@mui/material';
import youTubeEmbedStyles from './youtube-embed.module.css';
import { disableRightClick } from '../../utils/Common';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

interface YouTubeEmbed {
  id: string;
  title: string;
  thumbnail?: string;
  unLoadIframe?: Boolean;
  showImage?: Boolean;
}

export default function YouTubeEmbed({
  id,
  title,
  thumbnail,
  unLoadIframe,
  showImage,
}: YouTubeEmbed) {
  const [isIframe, setIsIframe] = useState(false);
  const [preconnect, setPreconnect] = useState(false);
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);

  const videoId = encodeURIComponent(id);
  const posterUrl =
    thumbnail && showImage
      ? thumbnail
      : `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`;

  const ytUrl = 'https://www.youtube.com';
  let iframeSrc = `${ytUrl}/embed/${videoId}?autoplay=1&enablejsapi=1`;

  const startVideo = () => {
    if (!isIframe) {
      setIsIframe(true);
    }
  };

  const startPreconnection = () => {
    if (!preconnect) {
      setPreconnect(true);
    }
  };

  const stopVideo = () => {
    if (isIframe) {
      setIsIframe(false);
      setIsIframeLoaded(false);
    }
  };

  useEffect(() => {
    unLoadIframe && stopVideo();
  }, [unLoadIframe]);

  return (
    <div className={youTubeEmbedStyles.container}>
      <link rel={'preload'} href={posterUrl} as="image" />
      <>{preconnect && <link rel={'preconnect'} href={ytUrl} />}</>

      <article
        onContextMenu={disableRightClick}
        className={`${youTubeEmbedStyles.ytEmbed} ${
          isIframe ? youTubeEmbedStyles.ytLoaded : ''
        }`}
        style={{
          backgroundImage: isIframeLoaded ? 'none' : `url(${posterUrl})`,
          ...({
            '--aspect-ratio': `${(9 / 16) * 100}%`,
          } as React.CSSProperties),
        }}
        onClick={startVideo}
        onPointerOver={startPreconnection}
      >
        {!isIframe ? (
          <div className={youTubeEmbedStyles.ytPlayButtonWrapper}>
            <PlayArrowIcon
              sx={{
                color: '#ffffff',
                width: '100%',
                height: '100%',
              }}
              className={youTubeEmbedStyles.ytPlayButton}
            />
          </div>
        ) : (
          <>
            {!isIframeLoaded && (
              <div className={youTubeEmbedStyles.ytLoader}>
                <CircularProgress
                  thickness={4}
                  size={'100%'}
                  sx={{
                    color: '#585de9',
                  }}
                />
              </div>
            )}

            <iframe
              title={title}
              src={iframeSrc}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              onLoad={() => setIsIframeLoaded(true)}
            ></iframe>
          </>
        )}
      </article>
    </div>
  );
}
