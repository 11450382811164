import React from 'react';

const CheckedIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#585DE9"/>
      <path d="M18.5973 8.23828L10.903 15.9577L5.73047 10.7708" stroke="white" strokeWidth="1.25" stroke-linejoin="round"/>
    </svg>
  );
};

export default CheckedIcon;