import React from 'react';

const FavoriteCheckedIcon = (props: any) => {
  return (
    <svg style={{ cursor: 'pointer' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill={props.disabled ? '#C9C5CA' : '#585DE9'} />
      <path d="M18.6662 8L10.691 16L5.33301 10.6267" stroke="white" strokeWidth="1.25" strokeMiterlimit="10"/>
    </svg>
  );
};

export default FavoriteCheckedIcon;