"use client";
import React from 'react';
import {withErrorBoundary} from 'react-error-boundary'
import AppRoutes from '../pages/Routes';
import FallbackErrorPage from './fallbackErrorPage/FallbackErrorPage';
import { useAppDispatch } from '../redux/hooks';
import { clearAllState } from '../redux/actions';
import { logClientError } from '../redux/reducers/clientSlice';

export default function AppWithErrorBoundary (props: any){
  const dispatch = useAppDispatch();

  const logError = (error: Error, info: { componentStack: string }) => {
    console.log('> ERROR BOUNDARY: ', error.message);
    dispatch(logClientError(`ERROR BOUNDARY: ${error.message}`));
  };

  const onReset = (details: any) => {
    const clearState = async  () => {
      await dispatch(clearAllState());
      window.location.reload();
    }
    clearState();
  }

  const ComponentWithErrorBoundary = withErrorBoundary(AppRoutes, {
    FallbackComponent: FallbackErrorPage,
    onError: logError,
    onReset: onReset
  })

  return <ComponentWithErrorBoundary {...props} />
};